import React, {Component} from 'react';
import './OrientationRemplissageManager.scss'
import api from './../globals/global-vars';
import axios from 'axios';
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/headerOutilsEtudiant'
import FeatherIcon from 'feather-icons-react';
import { toast, ToastContainer } from 'react-toastify';
import {BootstrapTable, TableHeaderColumn, ClearSearchButton, SearchField, ButtonGroup} from 'react-bootstrap-table';
import $ from 'jquery'
import formatDate from '../../utils'
import jsPDF from "jspdf";
import "jspdf-autotable";
import DatePicker from "react-multi-date-picker"
import {ExportToExcel} from './../modules/ExportToExcel/ExportToExcel'
import NavVertical from '../navVertical/NavVertical';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MultiSelect } from "react-multi-select-component";
import ApiBaseUrl from '../../http/ApiBaseUrl';


export default class OrientationRemplissageManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
          token: window.sessionStorage.getItem('token'),
          userAvatar: window.sessionStorage.getItem('user_avatar'),
          requestConfig: {
            headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
          },
          userRole: window.sessionStorage.getItem('user_role'),
          userID: window.sessionStorage.getItem('user_id'),
          api: api.api,
          isPageLoading: true,
          isSessionExpiration: false,
          fields: {},
          errors: {},
          addEventInProgress: false,
          isSuccessAddEvent: false,
          isErrorAddEvent: false,
          is_empty_event_type: false,
          is_empty_event_date: false,
          is_empty_event_start_time: false,
          is_empty_event_end_time: false,
          is_empty_event_participant_count: false,
          is_empty_is_online: false,
          is_empty_ville: false,
          is_empty_is_private: false,
          eventInfos: [],
          updateEventInProgress: false,
          isSuccessUpdateEvent: false,
          isErrorUpdateEvent: false,
          date: [],
          dates: [],
          villes: [],
          exportInfos: [
            {label: "Dates", value: "choosed_date"},
            {label: "Prénom & Nom", value: "event_checkout_fullname"},
            {label: "Email", value: "event_checkout_email"},
            {label: "Téléphone", value: "event_checkout_phone"},
            {label: "Adresse", value: "event_checkout_address"},
            {label: "Région", value: "event_checkout_region"},
            {label: "Compte FR", value: "event_checkout_compte_etudes_fr"},
            {label: "Numéro compte FR", value: "event_checkout_compte_etudes_fr_number"}
          ],
          eventInfosDate: '',
          eventDateInfoID: 0,
          updatedEventInfos: [],
          sortName: undefined,
          sortOrder: undefined,
          showExportFilterModal: false,
          selectedVilles: [],
          formHasError: false,
          showIntervalleDate: false,
          filterInProgress: false,
          is_empty_filter_type: false,
          is_empty_filter_start_date: false,
          is_empty_filter_end_date: false,
          is_empty_selected_villes: false,
          filteredCheckouts: [],
          filteredEventType: '',
          filteredPeriod: '',
          showCheckoutFilterForm: true,
          showCheckoutFilterTable: false
        };

        this.addEventHandleChange = this.addEventHandleChange.bind(this)
        this.onSubmitAddEvent = this.onSubmitAddEvent.bind(this)
        this.updateEventHandleChange = this.updateEventHandleChange.bind(this)
        this.onSortChange = this.onSortChange.bind(this)
        this.handleCloseExportFilterModal = this.handleCloseExportFilterModal.bind(this)
        this.handleShowExportFilterModal = this.handleShowExportFilterModal.bind(this)
        this.handleSelectVille = this.handleSelectVille.bind(this)
        this.FilterAndExportHandleChange = this.FilterAndExportHandleChange.bind(this)
        this.onSubmitFilterAndExportForm = this.onSubmitFilterAndExportForm.bind(this)
    }

    componentDidMount(){
      this.onGetEvents()
      this.onGetVilles()
    }

    handleCloseExportFilterModal = () => {
      this.setState({
        showExportFilterModal: false, 
        fields: {}, 
        errors: {},
        showCheckoutFilterForm: true,
        showCheckoutFilterTable: false,
        filteredCheckouts: [],
        filteredEventType: '',
        filteredPeriod: '',
        selectedVilles: []
      })
    }
    handleShowExportFilterModal = (e) => {
      e.preventDefault()
      this.setState({showExportFilterModal: true});
    }

    onGetEvents = () => {
      var api = this.state.api
      var config = this.state.requestConfig

      axios.get(`${api}orientation_remplissage_seances`, config)
        .then(response => {
          this.setState({
            isPageLoading: false
          })

          if(response.data.success){
            console.log(response.data.data)
            this.setState({
              eventsList: response.data.data
            })
          }else{
            console.log(response)
          }
        })
        .catch(error => {
          console.log(error)
          this.setState({
            isPageLoading: false
          })
          if (error.message === 'Request failed with status code 401') {
            this.setState({
              isSessionExpiration: true
            })
          }
        })
    }

    onGetVilles = () => {
      this.setState({
        isPageLoading: true
      })
  
      var api = this.state.api;
      var config = this.state.requestConfig;
  
      axios.get(api + 'villes', config)
        .then(response => {
          if (response.data.success) {
            this.setState({
              villes: response.data.data,
            })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          console.log(error)
          if (error.message === 'Request failed with status code 401') {
            this.setState({
              isSessionExpiration: true
            })
          }
        })
    }

    onSortChange(sortName, sortOrder) {
      console.info('onSortChange', arguments);
      this.setState({
        sortName,
        sortOrder
      });
    }

    eventTypeFormatter = (cell, row) => {
      return <span className="linkable event_type_item" data-toggle="modal" data-target=".show-event-participant-modal" onClick={() => this.onGetEventInfos(row)}> {cell} </span>
    }

    eventDateFormatter = (cell, row) => {
      if(cell.length !== 0){
        return (
          <div className="eventies-date">
            {cell.map((item) => (
                <div 
                  className="linkable eventies_date" 
                  title="Cliquer pour extraire la liste des inscrits"
                  onClick={() => this.onExportToPDF(row?.checkouts, row?.event_type, formatDate(item.date))}>
                    {formatDate(item.date)}
                </div>
            ))}
          </div>
        );
        
      }else{
        return 'aucune date'
      }
      // return formatDate(cell)
    }

    onActionFormatter = (cell, row) => {
        return(
          <div className="actions-btn-container">
            <button className="btn btn-primary action-btn edit-btn" data-toggle="modal" data-target=".edit-event-modal" onClick={() => this.onGetEventInfos(row)}>
              <FeatherIcon icon="edit" className="action-icon" />
            </button>
          </div>
        )
    }

    onGetEventInfos = (event) => {
      // console.log(event)
      this.setState({
        eventInfos: event,
        eventStatus: event.is_online,
        eventInfosDate: event?.all_dates[0]?.date,
        eventDateInfoID: event?.all_dates[0]?.id,
        updatedEventInfos: {
          id: event?.id,
          event_type: event?.event_type,
          event_start_time: event?.event_start_time,
          event_end_time: event?.event_end_time,
          event_participant_count: event?.event_participant_count,
          is_online: event?.is_online,
          is_private: event?.is_private,
          event_date: event?.all_dates[0]?.date,
          date_id: event?.all_dates[0]?.id,
          user_id: event?.user_id,
          ville_id: event?.ville_id
        }
      })

    }

    handleClearButtonClick = (onClick) => {
      console.log('This is my custom function for ClearSearchButton click event');
      onClick();
    }

    createCustomClearButton = (onClick) => {
        return (
            <ClearSearchButton
                btnText='Effacer'
                btnContextual='btn-warning'
                className='my-custom-class'
                onClick={e => this.handleClearButtonClick(onClick)} />
        );
    }

    createCustomSearchField = (props) => {
      return (
        <SearchField
            className='search-field'
            placeholder='Rechercher'/>
      );
    }

    addEventHandleChange = (e) => {
      let fields = this.state.fields
      fields['user_id'] =this.state.userID
      fields[e.target.name] = e.target.value

      this.setState({
        fields
      })

      console.log(fields)
    }

    handleChangeDate = (date) => {
      let newDate = []
      for (let i = 0; i < date.length; i++) {
        const element = date[i]
        console.log(new Date(element).toISOString())
        newDate.push(new Date(element).toISOString())
      }
      this.setState({ date, dates: newDate })
    };

    onValidateAddEventForm = () => {
      let fields = this.state.fields
      let errors = this.state.errors
      var isValidForm = true

      if(!fields['event_type'] || fields['event_type'] === '--Choisir un type de rencontre--'){
        isValidForm = false
        this.setState({
          is_empty_event_type: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_event_type: false
          })
        }, 5000);
        
        errors['event_type'] = 'Ce champ est obligatoire'
      }

      if(!fields['ville_id'] || fields['ville_id'] === '--Choisir une ville--'){
        isValidForm = false
        this.setState({
          is_empty_ville: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_ville: false
          })
        }, 5000);
        
        errors['ville'] = 'Ce champ est obligatoire'
      }

      // if(!fields['event_start_time']){
      //   isValidForm = false
      //   this.setState({
      //     is_empty_event_start_time: true
      //   })
      //   setTimeout(() => {
      //     this.setState({
      //       is_empty_event_start_time: false
      //     })
      //   }, 5000);
        
      //   errors['event_start_time'] = 'Ce champ est obligatoire'
      // }

      // if(!fields['event_end_time']){
      //   isValidForm = false
      //   this.setState({
      //     is_empty_event_end_time: true
      //   })
      //   setTimeout(() => {
      //     this.setState({
      //       is_empty_event_end_time: false
      //     })
      //   }, 5000);
        
      //   errors['event_end_time'] = 'Ce champ est obligatoire'
      // }

      if(!fields['event_participant_count']){
        isValidForm = false
        this.setState({
          is_empty_event_participant_count: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_event_participant_count: false
          })
        }, 5000);
        
        errors['event_participant_count'] = 'Ce champ est obligatoire'
      }

      if(!fields['is_online'] || fields['is_online'] === '--Choisir un statut--'){
        isValidForm = false
        this.setState({
          is_empty_is_online: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_is_online: false
          })
        }, 5000);
        
        errors['is_online'] = 'Ce champ est obligatoire'
      }
      if(!fields['is_private'] || fields['is_private'] === '--Choisir un statut--'){
        isValidForm = false
        this.setState({
          is_empty_is_private: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_is_private: false
          })
        }, 5000);
        
        errors['is_private'] = 'Ce champ est obligatoire'
      }

      this.setState({
        errors
      })

      return isValidForm
    }

    onSubmitAddEvent = (e) => {
      e.preventDefault()
      var isValidForm = true;
      let errors = this.state.errors
      if(this.state.dates.length === 0){
        isValidForm = false
        this.setState({
          is_empty_event_date: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_event_date: false
          })
        }, 5000);
        
        errors['event_date'] = 'Ce champ est obligatoire'
        this.setState({
          errors
        })
      }

      if(this.onValidateAddEventForm() && isValidForm){
        this.setState({
          addEventInProgress: true
        })
        var api = this.state.api
        var config = this.state.requestConfig
        var data = this.state.fields
        data['event_dates'] = this.state.dates

        axios.post(`${api}orientation_remplissage_seances`, data, config)
          .then(response => {
            this.setState({
              addEventInProgress: false
            })

            if(response.data.success){
              this.onGetEvents()
              toast.success('La rencontre a été bien ajoutée.')
              // window.$('.add-event-modal').modal('hide')
              $('.close').click();
              document.getElementById('add-rdv-form').reset();
              this.setState({
                isSuccessAddEvent: true,
                fields: {},
                dates: [],
                date: []
              })
              setTimeout(() => {
                this.setState({
                  isSuccessAddEvent: false
                })
              }, 5000);
            }else{
              console.log(response)
              toast.error('Oupsss! Une erreur est survenue lors de l\'ajout.')
              this.setState({
                isErrorAddEvent: true
              })
              setTimeout(() => {
                this.setState({
                  isErrorAddEvent: false
                })
              }, 5000);
            }
          })
          .catch(error => {
            console.log(error)
            toast.error('Oupsss! Une erreur est survenue lors de l\'ajout.')
            this.setState({
              addEventInProgress: false,
              isErrorAddEvent: true
            })
            setTimeout(() => {
              this.setState({
                isErrorAddEvent: false
              })
            }, 5000);

            if (error.message === 'Request failed with status code 401') {
              this.setState({
                isSessionExpiration: true
              })
            }

          })
      }
    }

    updateEventHandleChange = (e) => {
      e.persist();

      this.setState(prevState => ({
        updatedEventInfos: { ...prevState.updatedEventInfos,  [e.target.name]: e.target.value }
      }))
    }

    onValidateUpdateEventForm = () => {
      let fields = this.state.updatedEventInfos
      let errors = this.state.errors
      var isValidForm = true

      if(!fields['event_type'] || fields['event_type'] === '--Choisir un type de rencontre--'){
        isValidForm = false
        this.setState({
          is_empty_event_type: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_event_type: false
          })
        }, 5000);
        
        errors['event_type'] = 'Ce champ est obligatoire'
      }

      if(!fields['event_date']){
        isValidForm = false
        this.setState({
          is_empty_event_date: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_event_date: false
          })
        }, 5000);
        
        errors['event_date'] = 'Ce champ est obligatoire'
      }

      if(!fields['event_start_time']){
        isValidForm = false
        this.setState({
          is_empty_event_start_time: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_event_start_time: false
          })
        }, 5000);
        
        errors['event_start_time'] = 'Ce champ est obligatoire'
      }

      if(!fields['event_end_time']){
        isValidForm = false
        this.setState({
          is_empty_event_end_time: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_event_end_time: false
          })
        }, 5000);
        
        errors['event_end_time'] = 'Ce champ est obligatoire'
      }

      if(!fields['event_participant_count']){
        isValidForm = false
        this.setState({
          is_empty_event_participant_count: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_event_participant_count: false
          })
        }, 5000);
        
        errors['event_participant_count'] = 'Ce champ est obligatoire'
      }

      if(!fields['is_online'] || fields['is_online'] === '--Choisir un statut--'){
        isValidForm = false
        this.setState({
          is_empty_is_online: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_is_online: false
          })
        }, 5000);
        
        errors['is_online'] = 'Ce champ est obligatoire'
      }

      this.setState({
        errors
      })

      return isValidForm
    }

    onSubmitUpdateEvent = (e) => {
      e.preventDefault()

      if(this.onValidateUpdateEventForm()){
        var eventOldStatus = parseInt(this.state.eventStatus)
        var eventUpdateStatus = parseInt(this.state.eventInfos.is_online)

        if(eventOldStatus !== eventUpdateStatus){
          if(window.confirm('Confirmez-vous le changement de statut de la séance ?')){
            this.validFormUpdateData()
          }
        }else{
          this.validFormUpdateData()
        }
      }
    }

    validFormUpdateData = () => {
      this.setState({
        updateEventInProgress: true
      })
      var config = this.state.requestConfig
      var data = this.state.updatedEventInfos

      axios.put(`${ApiBaseUrl}orientation_remplissage_seances/${data.id}`, data, config)
        .then(response => {
          this.setState({
            updateEventInProgress: false
          })

          if(response.data.success){
            this.onGetEvents()
            toast.success('La rencontre a été modifiée avec succès.')
            // window.$('.add-event-modal').modal('hide')
            $('.close').click()
            this.setState({
              isSuccessUpdateEvent: true
            })
            setTimeout(() => {
              this.setState({
                isSuccessUpdateEvent: false
              })
            }, 5000);
          }else{
            console.log(response)
            toast.error('Oupsss! Une erreur est survenue lors de la modification.')
            this.setState({
              isErrorUpdateEvent: true
            })
            setTimeout(() => {
              this.setState({
                isErrorUpdateEvent: false
              })
            }, 5000);
          }
        })
        .catch(error => {
          console.log(error)
          toast.error('Oupsss! Une erreur est survenue lors de la modification.')
          this.setState({
            updateEventInProgress: false,
            isErrorUpdateEvent: true
          })
          setTimeout(() => {
            this.setState({
              isErrorUpdateEvent: false
            })
          }, 5000);

          if (error.message === 'Request failed with status code 401') {
            this.setState({
              isSessionExpiration: true
            })
          }

        })
    }

    compeEtudesFrFormatter = (cell, row) => {
      if(cell === true){
        return(
          <span className="badge badge-success orm-badge-success"> OUI </span>
        )
      }else{
        return(
          <span className="badge badge-warning orm-badge-warning"> NON </span>
        )
      }
    }

    compeEtudesFrNumberFormatter = (cell, row) => {
      if(cell !== null){
        return(
          <span className="cfr_number"> { cell } </span>
        )
      }else{
        return(
          <span className="badge badge-warning orm-badge-warning"> inexistant </span>
        )
      }
    }

    createCustomButtonGroup = props => {
      return (
          <ButtonGroup className='export-btn' sizeClass='btn-group-md'>
              {props.exportCSVBtn}
          </ButtonGroup>
      );
    }

    onlineStatusFormatter = (cell, row) => {
      if(cell === 1){
        return(
          <span className="badge badge-success orm-badge-success"> en ligne </span>
        )
      }
      if(cell === 0){
        return(
          <span className="badge badge-danger orm-badge-danger"> annulé </span>
        )
      }
    }

    isPrivateFormatter = (cell, row) => {
      if(cell === 0 || cell === false){
        return(
          <span className="badge badge-success orm-badge-success"> public </span>
        )
      }
      if(cell === 1 || cell === true){
        return(
          <span className="badge badge-danger orm-badge-danger"> privé </span>
        )
      }
    }

    onExportToPDF = (infos, type, date) => {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape
      var infs = null;
      var data = null;
  
      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);
  
      doc.setFontSize(15);
  
      // const title = "Liste des inscrits - " + type + " du " + formatDate(date);
      // return console.log(typeof(date));
      var title = ''
      // if(typeof(date) === 'object'){
      //   title = "Liste des inscrits - " + type
      // }
      if(typeof(date) === 'string'){
        title = "Liste des inscrits - " + type + ' du ' + formatDate(date);
        infs = infos.filter(function(checked) {
          return checked.choosed_date === date;
        })
      }else{
        title = "Liste des inscrits - " + type
      }
      
      const headers = [["Dates","Prénom & Nom", "Email", "Téléphone", "Adresse", "Région", "Compte FR", "Numéro compte FR"]];

      // if(typeof(date) === 'object'){
      //   data = infos.map(elt=> [formatDate(elt.choosed_date), elt.event_checkout_fullname, elt.event_checkout_email, elt.event_checkout_phone, elt.event_checkout_address, elt.event_checkout_region, elt.event_checkout_compte_etudes_fr, elt.event_checkout_compte_etudes_fr_number]);
      // }
      if(typeof(date) === 'string'){
        data = infs.map(elt=> [formatDate(elt.choosed_date), elt.event_checkout_fullname, elt.event_checkout_email, elt.event_checkout_phone, elt.event_checkout_address, elt.event_checkout_region, elt.event_checkout_compte_etudes_fr, elt.event_checkout_compte_etudes_fr_number]);
      }else{
        data = infos.map(elt=> [formatDate(elt.choosed_date), elt.event_checkout_fullname, elt.event_checkout_email, elt.event_checkout_phone, elt.event_checkout_address, elt.event_checkout_region, elt.event_checkout_compte_etudes_fr, elt.event_checkout_compte_etudes_fr_number]);
      }
  
      
  
      let content = {
        startY: 50,
        head: headers,
        body: data
      };

      // const pdfName = "Export_Liste_Inscrits_" + type + "_" + date
      const pdfName = "Export_Liste_Inscrits_" + type
  
      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save(`${pdfName}.pdf`)
    }
    

    inscritFormatter = (cell, row) => {
      if(cell.length !== 0){
        return (
          <div className="eventies-date">
            {cell.map((item) => (
              <>
                <div className="date-pcounting-container linkable" onClick={() => this.onExportToPDF(
                    row.checkouts,
                    row?.event_type, 
                    item.date)}
                >
                  <span className="date-pcounting-label">{formatDate(item.date)}</span>&nbsp;
                  <span className="date-pcounting-content">{item.participants}</span>
                </div> 

                {/* <div className="export-to-xlsx-container linkable">
                  <ExportToExcel exportData={row.checkouts} exportLabels={this.state.exportInfos} />
                </div>  */}
              </>
            ))}
          </div>
        );
        
      }else{
        return 'aucune information'
      }
    }

    exportExcelFormatter = (cell, row) => {
      if(cell.length !== 0){
        return (
          <div className="eventies-date">
            {cell.map((item) => (
              <>
                <div className="export-to-xlsx-container linkable">
                  <ExportToExcel isRDV={true} exportData={row.checkouts} exportLabels={this.state.exportInfos} date={formatDate(item.date)} />
                </div> 
              </>
            ))}
          </div>
        );
        
      }else{
        return 'aucune information'
      }
    }

    eventVilleFormatter = (cell, row) => {
      if(cell){
        return <span className="ville_name"> { row?.ville?.name } </span>
      }
      return <span className="badge badge-danger">ville non associée</span>
    }

    handleSelectVille = (e) => {
      this.setState({
        selectedVilles: e
      })
    }

    FilterAndExportHandleChange = (e) => {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      fields['selected_villes'] = this.state.selectedVilles;

      if(e.target.name === 'filter_type'){
        const value = e.target.value;
        if(value === "one_date"){
          this.setState({
            showIntervalleDate: false
          })
        } 
        if(value === "intervalle_date"){
          this.setState({
            showIntervalleDate: true
          })
        }
      }

      this.setState({
        fields
      })
    }

    ValidateFilterAndExportForm = () => {
      let fields = this.state.fields
      fields['selected_villes'] = this.state.selectedVilles
      let errors = this.state.errors
      var isValidForm = true;

      if(!fields['event_type']){
        isValidForm = false;
        errors['event_type'] = "Ce champ est requis."
        this.setState({
          is_empty_event_type: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_event_type: false
          })
        }, 5000);
      }
      if(!fields['filter_type']){
        isValidForm = false;
        errors['filter_type'] = "Ce champ est requis."
        this.setState({
          is_empty_filter_type: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_filter_type: false
          })
        }, 5000);
      }
      if(!fields['filter_start_date']){
        isValidForm = false;
        errors['filter_start_date'] = "Ce champ est requis."
        this.setState({
          is_empty_filter_start_date: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_filter_start_date: false
          })
        }, 5000);
      }
      if(!fields['filter_end_date'] && this.state.showIntervalleDate){
        isValidForm = false;
        errors['filter_end_date'] = "Ce champ est requis."
        this.setState({
          is_empty_filter_end_date: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_filter_end_date: false
          })
        }, 5000);
      }
      if(!fields['selected_villes'] || this.state.selectedVilles?.length === 0){
        isValidForm = false;
        errors['selected_villes'] = "Ce champ est requis."
        this.setState({
          is_empty_selected_villes: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_selected_villes: false
          })
        }, 5000);
      }

      this.setState({
        errors
      })

      return isValidForm;
    }

    onSubmitFilterAndExportForm = () => {
      // console.log('error', this.state.errors)
      // console.log('fields', this.state.fields)
      if(this.ValidateFilterAndExportForm()){
        this.setState({
          filterInProgress: true
        })
        const data = this.state.fields;
        axios.post(`${ApiBaseUrl}filter-event-to-export`, data)
          .then(response => {
            const rep = response.data;
            console.log('rep', rep)
            this.setState({
              filterInProgress: false
            })
            if(rep.success){
              this.setState({
                filteredCheckouts: rep.data,
                filteredEventType: rep.event_type,
                filteredPeriod: rep.period,
                showCheckoutFilterForm: false,
                showCheckoutFilterTable: true
              })
            }
          }).catch(error => {
            console.log('error', error)
            this.setState({
              filterInProgress: false
            })
          })
        
      }
    }

    OnResetCheckoutFilter = () => {
      this.setState({
        showCheckoutFilterForm: true,
        showCheckoutFilterTable: false,
        filteredCheckouts: [],
        filteredEventType: '',
        filteredPeriod: '',
        selectedVilles: []
      })
    }

    ExportFilteredCheckoutsToExcel = () => {
      const exportLabels = [
        {label: "Prénom et Nom", value: "fullname"},
        {label: "Téléphone", value: "telephone"},
        {label: "Date de RDV", value: "date"},
        {label: "Horaire", value: "horaire"},
        {label: "Antenne Campus France", value: "antenne"},
      ]
      return (
          <div className="filtered-checkout-export-btn-container row">
              <div className="col-lg-4 linkable export-filtered-checkout-to-excel" disabled={this.state.filteredCheckouts?.length === 0 && true}>
                  <ExportToExcel filteredPeriod={this.state.filteredPeriod} filteredEventType={this.state.filteredEventType} isFilteredCheckout={true} exportData={this.state.filteredCheckouts} exportLabels={exportLabels}  />
              </div>
              <div className="col-lg-4 offset-lg-4 linkable reset-filter-btn-col">
                <button onClick={this.OnResetCheckoutFilter} className="reset-filter-btn">Retour au formulaire</button>
              </div>
          </div>
      );
    }

    render() {
        const options = {
          clearSearch: true,
          clearSearchBtn: this.createCustomClearButton,
          noDataText: 'Aucune donnée disponible',
          searchField: this.createCustomSearchField,
          btnGroup: this.createCustomButtonGroup,
          sortName: this.state.sortName,
          sortOrder: this.state.sortOrder,
          onSortChange: this.onSortChange
        };

        return(
          <div className="component-orientation-remplissage-manager">
            {this.state.isPageLoading &&
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Chargement en cours</div>
              </div>
            }

            <HeaderOutilsEtudiant isAdminDashboard={true} />

            <section id="cd_container" className="s-h-primo-1">
              <div className="bordure-bas">
                <div className="row p-block-20 headering-container">
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="logo-soushaulle">
                      <p className="capitalise mb-0 bold color-white mb-3"> Gestion des RDV (Séances et Sessions) </p>
                    </div>
                  </div>
                </div>

                <div className="ligne mt-3 mb-5"></div>

              </div>

              <div className="row orm-row">
                <div className="nav-container nav-dashboard-color">
                    <NavVertical />
                </div>
                <div className="offset-xl-2 offset-lg-2 col-xl-10 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 page-content-spacing">
                    <div className="row page-content-row">
                      <div className="col-md-4 offset-md-4 orm-add-btn-col event-manager-global-btn-actions-container">
                        <button className="orm-add-btn btn btn-primary" data-toggle="modal" data-target=".add-event-modal">
                          <FeatherIcon icon="plus" className="add-plus-icon" />
                        </button>
                        <button onClick={this.handleShowExportFilterModal } className="orm-global-filter-btn btn btn-warning">
                          <span className="two-icons-in-button-container">
                            <FeatherIcon icon="filter" className="filter-icon f-icon" />
                            <span className="plus-fe-icon">+</span>
                            <FeatherIcon icon="download-cloud" className="filter-icon e-icon" />
                          </span>
                        </button>
                      </div>
                      <div className="col-md-12 orm-table-col">
                        <div className="orm-table-container">
                          <BootstrapTable data={this.state.eventsList}
                            striped={true} hover={true} condensed={true}
                            multiColumnSort={0} options={options}
                            multiColumnSearch={ true }
                            pagination
                            sort
                            search={true} version='4'>
                              <TableHeaderColumn dataField='id' isKey={true} hidden={true}>Numero</TableHeaderColumn>
                              <TableHeaderColumn width="270" dataField='event_type' dataFormat={this.eventTypeFormatter.bind(this)} dataSort>Type de Séance</TableHeaderColumn>
                              <TableHeaderColumn width="150" dataField='ville_id' dataFormat={this.eventVilleFormatter.bind(this)} dataSort>Antenne CF</TableHeaderColumn>
                              <TableHeaderColumn width="90" dataField='is_private' dataFormat={this.isPrivateFormatter.bind(this)}>Accès</TableHeaderColumn> 
                              <TableHeaderColumn width="90" dataField='is_online' dataFormat={this.onlineStatusFormatter.bind(this)}>Statuts</TableHeaderColumn>       
                              <TableHeaderColumn width="250" dataField='all_dates' dataFormat={this.inscritFormatter.bind(this)}>Inscrits & Export inscrits</TableHeaderColumn>
                              <TableHeaderColumn width="400" dataField='all_dates' dataFormat={this.exportExcelFormatter.bind(this)}>Inscrits Export Excel</TableHeaderColumn>
                              <TableHeaderColumn width="120" dataField='event_start_time'>Heure de début</TableHeaderColumn>
                              <TableHeaderColumn width="120" dataField='event_end_time'>Heure de fin</TableHeaderColumn>
                              <TableHeaderColumn width="150" dataField='event_participant_count'>Nombre attendu</TableHeaderColumn>
                              <TableHeaderColumn width="100" dataField='id' dataFormat={this.onActionFormatter.bind(this)}>Action</TableHeaderColumn>
                          </BootstrapTable>
                        </div>
                      </div>
                    </div>
                </div>
              </div>

              {/* <AdminRightSidebar isAdmin={true} /> */}

            </section>

            {/* Add event Modal */}
            <div className="modal fade add-event-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addEventModalTitle">Ajouter un RDV</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form id="add-rdv-form">
                      <div className="row add-event-row">
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="event_type">Type de rencontre</label>
                          <select onChange={this.addEventHandleChange} className="form-control add-event-form-control" name="event_type" id="event_type">
                            <option>--Choisir un type de rencontre--</option>
                            <option value="Orientation Collective"> Session d'orientation collective </option>
                            <option value="Aide au remplissage"> Atelier d'aide au remplissage </option>
                            <option value="Rendez-vous individuel d'informations"> Rendez-vous individuel d'informations </option>
                            <option value="Rendez-vous de complément de dossier"> Rendez-vous de complément de dossier </option>
                            <option value="Séance de préparation au séjour en France"> Séance de préparation au séjour en France </option>
                            <option value="Aide à la construction du projet">Aide à la construction du projet</option>
                            <option value="Entretien Individuel d'orientation">Entretien Individuel d'orientation</option>
                          </select>

                          {this.state.is_empty_event_type &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.event_type } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="villes">Antenne Campus France</label>
                          <select onChange={this.addEventHandleChange} className="form-control add-event-form-control" name="ville_id" id="villes">
                            <option>--Choisir une ville--</option>
                            {
                              this.state.villes?.map((ville, index) => 
                                <option key={`select-ville-${index}`} value={ville?.id}> { ville?.name } </option>
                              )
                            }
                          </select>

                          {this.state.is_empty_ville &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.ville } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col datepicker-col">
                          <label htmlFor="event_date">Date de la rencontre</label>
                          <DatePicker 
                            inputClass="form-control add-event-form-control cpus-datepicker-input"
                            multiple
                            placeholder="Sélectionner une ou plusieurs dates"
                            value={this.state.date}
                            onChange={this.handleChangeDate}
                          />
                          {this.state.is_empty_event_date &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.event_date } </div>
                          }
                        </div>

                        <div className="col-md-6 add-event-col">
                          <label htmlFor="morning_start_time">Heure de début de la matinée</label>
                          <input onChange={this.addEventHandleChange} type="time" placeholder="Heure de début de la matinée" className="form-control add-event-form-control" name="morning_start_time" id="" />
                        </div>

                        <div className="col-md-6 add-event-col">
                          <label htmlFor="morning_end_time">Heure de fin de la matinée</label>
                          <input onChange={this.addEventHandleChange} type="time" placeholder="Heure de fin de la matinée" className="form-control add-event-form-control" name="morning_end_time" id="" />
                        </div>

                        <div className="col-md-6 add-event-col">
                          <label htmlFor="afternoon_start_time">Heure de début de l'aprés-midi</label>
                          <input onChange={this.addEventHandleChange} type="time" placeholder="Heure de début de la matinée" className="form-control add-event-form-control" name="afternoon_start_time" id="" />
                        </div>
                        
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="afternoon_end_time">Heure de fin de l'aprés-midi</label>
                          <input onChange={this.addEventHandleChange} type="time" placeholder="Heure de fin de la matinée" className="form-control add-event-form-control" name="afternoon_end_time" id="" />
                        </div>

                        <div className="col-md-6 add-event-col">
                          <label htmlFor="interval_between_seance">Durée en minutes de chaque séance</label>
                          <input onChange={this.addEventHandleChange} type="number" placeholder="Nombre de participants attendus" className="form-control add-event-form-control" name="interval_between_seance" id="interval_between_seance" />
                        </div>

                        {/* <div className="col-md-6 add-event-col">
                          <label htmlFor="event_end_time">Heure de fin de la rencontre</label>
                          <input onChange={this.addEventHandleChange} type="time" placeholder="Heure de fin de la rencontre" className="form-control add-event-form-control" name="event_end_time" id="event_end_time" />
                        
                          {this.state.is_empty_event_end_time &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.event_end_time } </div>
                          }
                        </div> */}
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="event_participant_count">Nombre de participants attendus</label>
                          <input onChange={this.addEventHandleChange} type="number" placeholder="Nombre de participants attendus" className="form-control add-event-form-control" name="event_participant_count" id="event_participant_count" />
                        
                          {this.state.is_empty_event_participant_count &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.event_participant_count } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="is_online">Statut</label>
                          <select onChange={this.addEventHandleChange} className="form-control add-event-form-control" name="is_online" id="is_online">
                            <option>--Choisir un statut--</option>
                            <option value={1}> Publier en ligne </option>
                            <option value={0}> Ne pas publier en ligne </option>
                          </select>

                          {this.state.is_empty_is_online &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.is_online } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="is_private">Statut d'accès</label>
                          <select onChange={this.addEventHandleChange} className="form-control add-event-form-control" name="is_private" id="is_private">
                            <option>--Choisir un statut--</option>
                            <option value={1}> Privé</option>
                            <option value={0}> Public </option>
                          </select>

                          {this.state.is_empty_is_private &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.is_private } </div>
                          }
                        </div>

                        <div className="col-md-4 offset-md-4 add-event-submit-col">
                          {!this.state.addEventInProgress ?
                            <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => this.onSubmitAddEvent(e) }>
                              <FeatherIcon icon="plus" className="submit-add-icon" />&nbsp;
                              <span className="submit-add-label">Ajouter</span>
                            </button> :
                            <button className="btn btn-primary add-event-submit-btn">
                              <i className="fas fa-spin fa-spinner"></i>&nbsp;
                              <span className="submit-add-label">En cours</span>
                            </button>
                          }
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

            {/* Edit event Modal */}
            <div className="modal fade edit-event-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addEventModalTitle">Modifier</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form>
                      <div className="row add-event-row">
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="update_event_type">Type de rencontre</label>
                          <select value={this.state.updatedEventInfos.event_type} onChange={this.updateEventHandleChange} className="form-control add-event-form-control" name="event_type" id="update_event_type">
                            <option>--Choisir un type de rencontre--</option>
                            <option value="Orientation Collective"> Session d'orientation collective </option>
                            <option value="Aide au remplissage"> Atelier d'aide au remplissage </option>
                            <option value="Rendez-vous individuel d'informations"> Rendez-vous individuel d'informations </option>
                            <option value="Rendez-vous de complément de dossier"> Rendez-vous de complément de dossier </option>
                            <option value="Séance de prépration au séjour en France"> Séance de prépration au séjour en France </option>
                          </select>

                          {this.state.is_empty_event_type &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.event_type } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="villes">Antenne Campus France</label>
                          <select value={this.state.updatedEventInfos?.ville_id} onChange={this.updateEventHandleChange} className="form-control add-event-form-control" name="ville_id" id="update-villes">
                            <option>--Choisir une ville--</option>
                            {
                              this.state.villes?.map((ville, index) => 
                                <option key={`select-ville-${index}`} value={ville?.id}> { ville?.name } </option>
                              )
                            }
                          </select>

                          {this.state.is_empty_ville &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.ville } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="update_event_date">Date de la rencontre</label>
                          <input value={moment(this.state.updatedEventInfos?.event_date).format("YYYY-MM-DD")} onChange={this.updateEventHandleChange} type="date" placeholder="Date de la rencontre" className="form-control add-event-form-control" name="event_date" id="update_event_date" />
                        
                          {this.state.is_empty_event_date &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.event_date } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="update_event_start_time">Heure de début de la rencontre</label>
                          <input value={this.state.updatedEventInfos.event_start_time} onChange={this.updateEventHandleChange} type="time" placeholder="Heure de début de la rencontre" className="form-control add-event-form-control" name="event_start_time" id="update_event_start_time" />
                        
                          {this.state.is_empty_event_start_time &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.event_start_time } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="update_event_end_time">Heure de fin de la rencontre</label>
                          <input value={this.state.updatedEventInfos.event_end_time} onChange={this.updateEventHandleChange} type="time" placeholder="Heure de fin de la rencontre" className="form-control add-event-form-control" name="event_end_time" id="update_event_end_time" />
                        
                          {this.state.is_empty_event_end_time &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.event_end_time } </div>
                          }
                        </div>
                        <div className="col-md-6 add-event-col">
                          <label htmlFor="update_event_participant_count">Nombre de participants attendus</label>
                          <input value={this.state.updatedEventInfos.event_participant_count} onChange={this.updateEventHandleChange} type="number" placeholder="Nombre de participants attendus" className="form-control add-event-form-control" name="event_participant_count" id="update_event_participant_count" />
                        
                          {this.state.is_empty_event_participant_count &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.event_participant_count } </div>
                          }
                        </div>

                        <div className="col-md-6 add-event-col">
                          <label htmlFor="update_is_online">Statut</label>
                          <select value={this.state.updatedEventInfos.is_online} onChange={this.updateEventHandleChange} className="form-control add-event-form-control" name="is_online" id="update_is_online">
                            <option>--Choisir un statut--</option>
                            <option value={1}> Publier en ligne </option>
                            <option value={0}> Ne pas publier en ligne </option>
                          </select>

                          {this.state.is_empty_is_online &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.is_online } </div>
                          }
                        </div>

                        <div className="col-md-6 add-event-col">
                          <label htmlFor="is_private">Statut d'accès</label>
                          <select value={this.state.updatedEventInfos.is_private} onChange={this.addEventHandleChange} className="form-control add-event-form-control" name="is_private" id="is_private">
                            <option>--Choisir un statut--</option>
                            <option value={1}> Privé</option>
                            <option value={0}> Public </option>
                          </select>

                          {this.state.is_empty_is_private &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.is_private } </div>
                          }
                        </div>

                        <div className="col-md-4 offset-md-4 add-event-submit-col">
                          {!this.state.updateEventInProgress ?
                            <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => this.onSubmitUpdateEvent(e) }>
                              <FeatherIcon icon="plus" className="submit-add-icon" />&nbsp;
                              <span className="submit-add-label">Modifier</span>
                            </button> :
                            <button className="btn btn-primary add-event-submit-btn">
                              <i className="fas fa-spin fa-spinner"></i>&nbsp;
                              <span className="submit-add-label">En cours</span>
                            </button>
                          }
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

            {/* List Participant Modal */}
            <div className="modal fade show-event-participant-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="listParticipantEvent" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title" id="listParticipantEvent"> {`${this.state.eventInfos.event_type}`} </h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                          </button>
                      </div>

                      <div className="modal-body">
                          <div className="row orm-row">
                              <div className="col-md-12 orm-table-col tabler-position">
                                  <button className="btn btn-primary export-pdf-btn" onClick={() => this.onExportToPDF(this.state.eventInfos.checkouts, this.state.eventInfos.event_type, this.state.eventInfos.all_dates)}>
                                    <FeatherIcon icon="download" className="pdf-download-icon" />&nbsp;
                                    Exporter en PDF
                                  </button>
                                  <div className="orm-table-container">
                                      <BootstrapTable data={this.state.eventInfos.checkouts}
                                          striped={true} hover={true} condensed={true}
                                          multiColumnSort={0} options={options}
                                          multiColumnSearch={ true }
                                          pagination
                                          // exportCSV
                                          search={true} version='4'>
                                          <TableHeaderColumn dataField='id' isKey={true} hidden={true}>Numero</TableHeaderColumn>
                                          <TableHeaderColumn width="200" dataField='event_checkout_fullname' >Prénom & Nom</TableHeaderColumn>
                                          <TableHeaderColumn width="200" dataField='event_checkout_email'>Email</TableHeaderColumn>
                                          <TableHeaderColumn width="200" dataField='event_checkout_phone'>Téléphone</TableHeaderColumn>
                                          <TableHeaderColumn width="200" dataField='event_checkout_address'>Adresse</TableHeaderColumn>
                                          <TableHeaderColumn width="200" dataField='event_checkout_region'>Région</TableHeaderColumn>
                                          <TableHeaderColumn width="150" dataField='event_checkout_compte_etudes_fr' dataFormat={this.compeEtudesFrFormatter.bind(this)} csvFormat={this.compeEtudesFrFormatter.bind(this)}>Compte Etudes FR</TableHeaderColumn>
                                          <TableHeaderColumn width="150" dataField='event_checkout_compte_etudes_fr_number' dataFormat={this.compeEtudesFrNumberFormatter.bind(this)} csvFormat={this.compeEtudesFrNumberFormatter.bind(this)}>Num. Compte Etudes FR</TableHeaderColumn>
                                          {/* <TableHeaderColumn width="100" dataField='id' dataFormat={this.onActionFormatter.bind(this)}>Action</TableHeaderColumn> */}
                                      </BootstrapTable>
                                  </div>
                              </div>
                          </div>
                      </div>

                  </div>
              </div>
            </div>

            {/* Filter to Export Excel */}
            <Modal size={this.state.showCheckoutFilterForm ? 'md' : this.state.showCheckoutFilterTable && 'lg'} show={this.state.showExportFilterModal} onHide={this.handleCloseExportFilterModal} className="v2-admin-modal" >
                <Modal.Header className="v2-admin-modal-header" closeButton>
                    <Modal.Title className="v2-admin-modal-title"> Filter et Exporter </Modal.Title>
                </Modal.Header>
                {(this.state.showCheckoutFilterForm && !this.state.showCheckoutFilterTable) && (
                  <form id="multiple-generation-form" >
                      <Modal.Body className="v2-admin-modal-body">
                        <div className="v2-admin-modal-body-content">
                            <div className="row v2-admin-modal-row">
                                <div className="col-md-12 o-form-col">
                                    <div className="form-group o-form-group">
                                        <label htmlFor="session_type">Choisir un type de RDV <span className="required-icon">*</span></label>
                                        <select onChange={this.FilterAndExportHandleChange} name="event_type" className="form-control oe-form-control v2-select-form-control" id="session_type">
                                          <option>--Choisir un type de rencontre--</option>
                                          <option value="Orientation Collective"> Session d'orientation collective </option>
                                          <option value="Aide au remplissage"> Atelier d'aide au remplissage </option>
                                          <option value="Rendez-vous individuel d'informations"> Rendez-vous individuel d'informations </option>
                                          <option value="Rendez-vous de complément de dossier"> Rendez-vous de complément de dossier </option>
                                          <option value="Séance de préparation au séjour en France"> Séance de préparation au séjour en France </option>
                                          <option value="Aide à la construction du projet">Aide à la construction du projet</option>
                                          <option value="Entretien Individuel d'orientation">Entretien Individuel d'orientation</option>
                                        </select>
                                    </div>
                                    {(this.state.is_empty_event_type) && (
                                      <div className="alert alert-danger oe-alert-danger"> 
                                        { this.state.errors.event_type } 
                                      </div>
                                    )}
                                </div>
                                <div className="col-md-12 o-form-col">
                                  <div className="form-group o-form-group">
                                      <label>Choisir un type d'exportation <span className="required-icon">*</span></label>
                                      <div className="admin-radio-input-btn-container">
                                        <label htmlFor="one_date" className="radio-input-item one-date-item">
                                          <span className="radio-input-container">
                                            <input value="one_date" onChange={this.FilterAndExportHandleChange} type="radio" className="admin-radio-btn-in-form" name="filter_type" id="one_date" />
                                            <span className="radio-input-label">Exporter une date</span>
                                          </span>
                                        </label>
                                        <label htmlFor="intervalle_date" className="radio-input-item intervalle-date-item">
                                          <span className="radio-input-container">
                                            <input value="intervalle_date" onChange={this.FilterAndExportHandleChange} type="radio" className="admin-radio-btn-in-form" name="filter_type" id="intervalle_date" />
                                            <span className="radio-input-label">Exporter une intervalle de date</span>
                                          </span>
                                        </label>
                                      </div>
                                  </div>
                                  {(this.state.is_empty_filter_type) && (
                                    <div className="alert alert-danger oe-alert-danger"> 
                                      { this.state.errors.filter_type } 
                                    </div>
                                  )}
                                </div>

                                {!this.state.showIntervalleDate && (
                                  <div className="col-md-12 o-form-col">
                                    <div className="form-group o-form-group">
                                      <label htmlFor="date_debut_filtre">Date souhaitée</label>
                                      <input  onChange={this.FilterAndExportHandleChange} type="date" placeholder="Date de début" className="form-control oe-form-control" name="filter_start_date" id="date_debut_filtre" />
                                    </div>
                                    {(this.state.is_empty_filter_start_date) && (
                                      <div className="alert alert-danger oe-alert-danger"> 
                                        { this.state.errors.filter_start_date } 
                                      </div>
                                    )}
                                  </div>
                                )}

                                {this.state.showIntervalleDate && (
                                  <>
                                    <div className="col-md-12 o-form-col">
                                      <div className="form-group o-form-group">
                                        <label htmlFor="date_debut_filtre">Date de début</label>
                                        <input  onChange={this.FilterAndExportHandleChange} type="date" placeholder="Date de début" className="form-control oe-form-control" name="filter_start_date" id="date_debut_filtre" />
                                      </div>
                                      {(this.state.is_empty_filter_start_date) && (
                                        <div className="alert alert-danger oe-alert-danger"> 
                                          { this.state.errors.filter_start_date } 
                                        </div>
                                      )}
                                    </div>
                                    
                                    <div className="col-md-12 o-form-col">
                                      <div className="form-group o-form-group">
                                        <label htmlFor="date_fin_filtre">Date de fin</label>
                                        <input  onChange={this.FilterAndExportHandleChange} type="date" placeholder="Date de fin" className="form-control oe-form-control" name="filter_end_date" id="date_fin_filtre" />
                                      </div>
                                      {(this.state.is_empty_filter_end_date) && (
                                        <div className="alert alert-danger oe-alert-danger"> 
                                          { this.state.errors.filter_end_date } 
                                        </div>
                                      )}
                                    </div>
                                  </>
                                )}
                                

                                <div className="col-md-12 o-form-col">
                                  <div className="form-group o-form-group">
                                    <label htmlFor="date_debut_filtre">Antenne Campus France</label>
                                    <MultiSelect
                                        options={
                                            this.state.villes?.map((ville) => {
                                                return {
                                                    label: `${ville?.name}`,
                                                    value: `${ville?.id}`
                                                }
                                            })
                                        } 
                                        className="form-control oe-form-control v2-select-form-control" 
                                        id="candidats-select-dropdown" 
                                        value={this.state.selectedVilles}
                                        onChange={ (e) => this.setState({selectedVilles: e}) }
                                        labelledBy="Choisir une ou des antennes"
                                        overrideStrings={{
                                            "allItemsAreSelected": "Tous les candidats choisis",
                                            "clearSearch": "Effacer la recherche",
                                            "clearSelected": "Effacer la sélection",
                                            "noOptions": "Aucun candidat",
                                            "search": "Chercher",
                                            "selectAll": "Tout sélectionner",
                                            "selectAllFiltered": "Tout sélectionner (Filtré)",
                                            "selectSomeItems": "Choisir...",
                                            "create": "Créer",
                                        }}
                                    />
                                  </div>
                                  {(this.state.is_empty_selected_villes) && (
                                        <div className="alert alert-danger oe-alert-danger"> 
                                          { this.state.errors.selected_villes } 
                                        </div>
                                      )}
                                </div>
                            </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer className="v2-admin-modal-footer">
                          <Button className="v2-admin-modal-footer-close-btn" variant="secondary" onClick={this.handleCloseExportFilterModal}>
                              Fermer
                          </Button>
                          <Button className="v2-admin-modal-footer-submit-btn" variant="primary" onClick={(e) => {
                              e.preventDefault();
                              this.onSubmitFilterAndExportForm();
                          }}>
                              { this.state.filterInProgress ? 'En cours...' : 'Envoyer' }
                          </Button>
                      </Modal.Footer>
                  </form>
                )}
                {(!this.state.showCheckoutFilterForm && this.state.showCheckoutFilterTable) && (
                  <div className="checkout-filter-results-data-container">
                    <div className="row checkout-filter-results-row">
                      <div className="col-lg-12 checkout-filter-results-textuals-col">
                        <div className="checkout-filter-results-textuals-body">
                          <div className="period-title"> Recherche effectuée sur la période du { this.state.filteredPeriod } </div>
                          <div className="event-type-type"> Type de séance : <span> { this.state.filteredEventType } </span> </div>
                        </div>
                      </div>
                      <div className="col-md-12 orm-table-col checkout-filter-results-table-col">
                        {this.ExportFilteredCheckoutsToExcel()}
                        <div className="orm-table-container checkout-filter-results-table">
                          <BootstrapTable data={this.state.filteredCheckouts}
                            striped={true} hover={true} condensed={true}
                            multiColumnSort={0} options={options}
                            multiColumnSearch={ true }
                            pagination
                            sort
                            search={true} version='4'>
                              <TableHeaderColumn dataField='id' isKey={true} hidden={true}>ID</TableHeaderColumn>
                              <TableHeaderColumn width="270" dataField='event_type' dataSort>Type de Séance</TableHeaderColumn>
                              <TableHeaderColumn width="250" dataField='fullname' dataSort>Prénom et Nom</TableHeaderColumn>
                              <TableHeaderColumn width="150" dataField='telephone'>Téléphone</TableHeaderColumn>
                              <TableHeaderColumn width="150" dataField='date' >Date de RDV</TableHeaderColumn> 
                              <TableHeaderColumn width="200" dataField='horaire'>Horaire</TableHeaderColumn>
                              <TableHeaderColumn width="200" dataField='antenne'>Antenne Campus France</TableHeaderColumn>
                          </BootstrapTable>
                        </div>
                      </div>
                    </div>

                    <Modal.Footer className="v2-admin-modal-footer">
                          <Button className="v2-admin-modal-footer-close-btn" variant="secondary" onClick={this.handleCloseExportFilterModal}>
                              Fermer
                          </Button>
                      </Modal.Footer>
                  </div>
                )}
            </Modal>

            {this.state.isSuccessAddEvent || this.state.isErrorAddEvent || this.state.isSuccessUpdateEvent || this.state.isErrorUpdateEvent ?
              <ToastContainer /> : ''
            }
          </div>
        );
      }
    }

