import React, {useEffect, useState} from 'react';
import OrientationIcon from '../../img/v2/icons/OrientationIcon.svg';
import InformationIcon from '../../img/v2/icons/InformationIcon.svg';
import VisaIcon from '../../img/v2/icons/VisaIcon.svg';
import UniversityIcon from '../../img/v2/icons/UniversityIcon.svg';
import JeMorienteIndexModal from './LandingPageModals/JeMorienteModal/JeMorienteIndexModal';
import SearchUniversityIndexModal from './LandingPageModals/SearchUniversityModals/SearchUniversityIndexModal';
import JeMinformeIndexModal from './LandingPageModals/JeMinformeModal/JeMinformeIndexModal';
import VisaIndexModal from './LandingPageModals/VisaModal/VisaIndexModal';
import { useHistory } from 'react-router-dom';

function LandingPageServicesV2({onShowChat, onShowOrientation, params}) {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [subModalIsOpen, setSubModalIsOpen] = useState(false)

    const [searchUniversityModalIsOpen, setSearchUniversityModalIsOpen] = useState(false);
    const [publicSchoolOpenModal, setPublicSchoolOpenModal] = useState(false);

    const [jeMinformeModalIsOpen, setJeMinformeModalIsOpen] = useState(false);

    const [visaModalIsOpen, setVisaModalIsOpen] = useState(false);
    const [subVisaModalIsOpen, setSubVisaModalIsOpen] = useState(false);

    const [isOpenOrientationCollectiveModal, setIsOpenOrientationCollectiveModal] = useState(false);
    const [isOpenSubscribeEventModal, setIsOpenSubscribeEventModal] = useState(false);

    const [isOpenRemplissageModal, setIsOpenRemplissageModal] = useState(false);
    const [isOpenInformationModal, setIsOpenInformationModal] = useState(false);
    const [isOpenComplementDossierModal, setIsOpenComplementDossierModal] = useState(false);
    const [isOpenPreparationSejourModal, setIsOpenPreparationSejourModal] = useState(false);

    const [isOpenPlayingTutorialVideoModal, setIsOpenPlayingTutorialVideoModal] = useState(false);

    const navigate = useHistory();

    useEffect(() => {
        if(onShowChat){
            setIsOpen(true);
            openSubModal();
        }
        if(onShowOrientation){
            setIsOpenOrientationCollectiveModal(true);
        }
        // eslint-disable-next-line
    }, [onShowChat])

    useEffect(() => {
        if(params){
            const eventType = params?.event_type;
            if(eventType === 'inscription-session-collective-dorientation'){
                onOpenOrientationCollectiveModal();
            }
            if(eventType === 'je-moriente'){
                openModal();
            }
            if(eventType === 'echanger-avec-un-conseiller'){
                openSubModal();
            }
            if(eventType === 'echanger-avec-un-conseiller'){
                openSubModal();
            }
            if(eventType === 'je-cherche-une-universite-ou-une-ecole'){
                onOpenSearchUniversityModal();
            }
            if(eventType === 'etablissements-publics-ou-prives'){
                onOpenSubSearchUniversityModal();
            }
            if(eventType === 'je-minforme'){
                onOpenJeMinformeModal();
            }
            if(eventType === 'je-prepare-mon-visa-et-mon-sejour'){
                onOpenVisaModal();
            }
            if(eventType === 'je-prepare-mon-visa'){
                onOpenSubModalVisa();
            }
            if(eventType === 'aide-au-remplissage-du-dossier-electronique'){
                onOpenRemplissageModal();
            }
            if(eventType === 'rendez-vous-individuel-d-informations'){
                onOpenInformationModal();
            }
            if(eventType === 'rendez-vous-de-complement-de-dossier'){
                onOpenComplementDossierModal();
            }
            if(eventType === 'seance-de-preparation-au-sejour-en-france'){
                onOpenPreparationSejourModal();
            }
        }
    }, [params])


    const openModal = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setIsOpen(true);
    }
    const closeModal = () => {
        setIsOpen(false);
        if(params?.hasOwnProperty('event_type')){
            navigate.push('/')
        }
    }

    const openSubModal = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setSubModalIsOpen(true);
        setIsOpen(false);
    }
    const closeSubModal = () => {
        setSubModalIsOpen(false);
        if(params?.hasOwnProperty('event_type')){
            navigate.push('/')
        }else{
            setIsOpen(true);
        }
    }

    const onOpenSearchUniversityModal = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setSearchUniversityModalIsOpen(true);
    }
    const onCloseSearchUniversityModal = () => {
        setSearchUniversityModalIsOpen(false);
        if(params?.hasOwnProperty('event_type')){
            navigate.push('/')
        }
    }

    const onOpenSubSearchUniversityModal = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setSearchUniversityModalIsOpen(false);
        setPublicSchoolOpenModal(true);
    }
    const onCloseSubSearchUniversityModal = () => {
        setPublicSchoolOpenModal(false);
        if(params?.hasOwnProperty('event_type')){
            navigate.push('/')
        }else{
            setSearchUniversityModalIsOpen(true);
        }
    }

    const onOpenJeMinformeModal = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setJeMinformeModalIsOpen(true)
    }
    const onCloseJeMinformeModal = () => {
        setJeMinformeModalIsOpen(false)
        if(params?.hasOwnProperty('event_type')){
            navigate.push('/')
        }
    }

    const onOpenVisaModal = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setVisaModalIsOpen(true)
    }
    const onCloseVisaModal = () => {
        setVisaModalIsOpen(false)
        if(params?.hasOwnProperty('event_type')){
            navigate.push('/')
        }
    }

    const onOpenSubModalVisa = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setVisaModalIsOpen(false);
        setSubVisaModalIsOpen(true);
    }

    const onCloseSubVisaModal = () => {
        setSubVisaModalIsOpen(false);
        if(params?.hasOwnProperty('event_type')){
            navigate.push('/')
        }else{
            setVisaModalIsOpen(true);
        }
    }

    const onOpenOrientationCollectiveModal = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setIsOpen(false);
        setIsOpenOrientationCollectiveModal(true);
        window.sessionStorage.setItem('eventType', 'orientation')
    }
    const onCloseOrientationCollectiveModal = () => {
        setIsOpenOrientationCollectiveModal(false);
        window.sessionStorage.removeItem('eventType')
        if(params?.hasOwnProperty('event_type')){
            navigate.push('/')
        }else{
            setIsOpen(true);
        }
    }

    const onOpenSubscribeEventModal = (eventType) => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        if(eventType === 'orientation'){
            setIsOpenOrientationCollectiveModal(false);
        }
        if(eventType === 'remplissage'){
            setIsOpenRemplissageModal(false);
        }
        if(eventType === 'information'){
            setIsOpenInformationModal(false);
        }
        if(eventType === 'complement'){
            setIsOpenComplementDossierModal(false);
        }
        if(eventType === 'preparation'){
            setIsOpenPreparationSejourModal(false);
        }
        setIsOpenSubscribeEventModal(true)
    }
    const onCloseSubscribeEventModal = (eventType) => {
        setIsOpenSubscribeEventModal(false);
        if(eventType === 'orientation'){
            setIsOpenOrientationCollectiveModal(true);
        }
        if(params?.hasOwnProperty('event_type')){
            navigate.push('/')
        }else{
            setIsOpen(true);
        }
    }

    const onOpenRemplissageModal = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setIsOpen(false);
        setIsOpenRemplissageModal(true);
        window.sessionStorage.setItem('eventType', 'remplissage')
    }
    const onCloseRemplissageModal = () => {
        setIsOpenRemplissageModal(false);
        window.sessionStorage.removeItem('eventType')
        if(params?.hasOwnProperty('event_type')){
            navigate.push('/')
        }else{
            setIsOpen(true);
        }
    }

    const onOpenInformationModal = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setIsOpen(false);
        setIsOpenInformationModal(true);
        window.sessionStorage.setItem('eventType', 'information')
    }
    const onCloseInformationModal = () => {
        setIsOpenInformationModal(false);
        window.sessionStorage.removeItem('eventType')
        if(params?.hasOwnProperty('event_type')){
            navigate.push('/')
        }else{
            setIsOpen(true);
        }
    }

    const onOpenComplementDossierModal = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setIsOpen(false);
        setIsOpenComplementDossierModal(true);
        window.sessionStorage.setItem('eventType', 'complement')
    }
    const onCloseComplementDossierModal = () => {
        setIsOpenComplementDossierModal(false);
        window.sessionStorage.removeItem('eventType')
        if(params?.hasOwnProperty('event_type')){
            navigate.push('/')
        }else{
            setIsOpen(true);
        }
    }

    const onOpenPreparationSejourModal = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setIsOpen(false);
        setIsOpenPreparationSejourModal(true);
        window.sessionStorage.setItem('eventType', 'preparation')
    }
    const onClosePreparationSejourModal = () => {
        setIsOpenPreparationSejourModal(false);
        window.sessionStorage.removeItem('eventType')
        if(params?.hasOwnProperty('event_type')){
            navigate.push('/')
        }else{
            setIsOpen(true);
        }
    }

    const onOpenPlayingTutorialVideoModal = (eventType) => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        if(eventType === 'orientation'){
            setIsOpenOrientationCollectiveModal(false);
        }
        if(eventType === 'remplissage'){
            setIsOpenRemplissageModal(false);
        }
        if(eventType === 'information'){
            setIsOpenInformationModal(false);
        }
        if(eventType === 'complement'){
            setIsOpenComplementDossierModal(false);
        }
        if(eventType === 'preparation'){
            setIsOpenPreparationSejourModal(false);
        }
        setIsOpenPlayingTutorialVideoModal(true);
    }
    const onClosePlayingTutorialVideoModal = (eventType) => {
        setIsOpenPlayingTutorialVideoModal(false);
        if(eventType === 'orientation'){
            setIsOpenOrientationCollectiveModal(true);
        }
        if(eventType === 'remplissage'){
            setIsOpenRemplissageModal(true);
        }
        if(eventType === 'information'){
            setIsOpenInformationModal(true);
        }
        if(eventType === 'complement'){
            setIsOpenComplementDossierModal(true);
        }
        if(eventType === 'preparation'){
            setIsOpenPreparationSejourModal(true);
        }
        if(params?.hasOwnProperty('event_type')){
            navigate.push('/')
        }else{
            setIsOpen(true);
        }
    }

    return (
        <section id="v2-services-section">
            <div className="container-fluid v2-services-body-container">
                <div className="v2-services-body-content">
                    <div className="v2-services-body-listing-items">
                        <div className="v2-service-item-container v2-orientation-services-container linkable" onClick={openModal}>
                            <div className="v2-service-item-card">
                                <div className="v2-service-card-header">
                                    <div className="v2-service-icon-container">
                                        <img src={ OrientationIcon } className="v2-service-icon" alt="Je m'oriente" />
                                    </div>
                                    <div className="v2-service-name">
                                        Je m'oriente
                                    </div>
                                </div>
                                <div className="v2-service-explorer-btn-container">
                                    <button onClick={openModal} className="btn btn-primary v2-explorer-btn">Parcourir</button>
                                </div>
                            </div>
                        </div>
                        <div onClick={onOpenSearchUniversityModal} className="v2-service-item-container v2-school-or-university-services-container linkable">
                            <div className="v2-service-item-card">
                                <div className="v2-service-card-header">
                                    <div className="v2-service-icon-container">
                                        <img src={ UniversityIcon } className="v2-service-icon" alt="Je recherche une école ou une université" />
                                    </div>
                                    <div className="v2-service-name">
                                        Je recherche une école ou une université
                                    </div>
                                </div>
                                <div className="v2-service-explorer-btn-container">
                                    <button onClick={onOpenSearchUniversityModal} className="btn btn-primary v2-explorer-btn">Parcourir</button>
                                </div>
                            </div>
                        </div>
                        <div onClick={onOpenJeMinformeModal} className="v2-service-item-container v2-informations-services-container linkable">
                            <div className="v2-service-item-card">
                                <div className="v2-service-card-header">
                                    <div className="v2-service-icon-container">
                                        <img src={ InformationIcon } className="v2-service-icon" alt="Je m’informe" />
                                    </div>
                                    <div className="v2-service-name">
                                        Je m’informe
                                    </div>
                                </div>
                                <div className="v2-service-explorer-btn-container">
                                    <button onClick={onOpenJeMinformeModal} className="btn btn-primary v2-explorer-btn">Parcourir</button>
                                </div>
                            </div>
                        </div>
                        <div onClick={onOpenVisaModal} className="v2-service-item-container v2-visa-services-container linkable">
                            <div className="v2-service-item-card">
                                <div className="v2-service-card-header">
                                    <div className="v2-service-icon-container">
                                        <img src={ VisaIcon } className="v2-service-icon" alt="Je prépare mon visa et mon séjour en France" />
                                    </div>
                                    <div className="v2-service-name">
                                        Je prépare mon visa et mon séjour en France
                                    </div>
                                </div>
                                <div className="v2-service-explorer-btn-container">
                                    <button onClick={onOpenVisaModal} className="btn btn-primary v2-explorer-btn">Parcourir</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <JeMorienteIndexModal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                subModalIsOpen={subModalIsOpen}
                setSubModalIsOpen={setSubModalIsOpen}
                closeModal={() => closeModal()}
                openSubModal={() => openSubModal()}
                closeSubModal={closeSubModal}

                isOpenOrientationCollectiveModal={isOpenOrientationCollectiveModal}
                setIsOpenOrientationCollectiveModal={setIsOpenOrientationCollectiveModal}
                onOpenOrientationCollectiveModal={onOpenOrientationCollectiveModal}
                onCloseOrientationCollectiveModal={onCloseOrientationCollectiveModal}

                isOpenSubscribeEventModal={isOpenSubscribeEventModal}
                setIsOpenSubscribeEventModal={setIsOpenSubscribeEventModal}
                onOpenSubscribeEventModal={(e) => onOpenSubscribeEventModal(e)}
                onCloseSubscribeEventModal={(e) => onCloseSubscribeEventModal(e)}

                isOpenRemplissageModal={isOpenRemplissageModal}
                setIsOpenRemplissageModal={setIsOpenRemplissageModal}
                onOpenRemplissageModal={(e) => onOpenRemplissageModal(e)}
                onCloseRemplissageModal={(e) => onCloseRemplissageModal(e)}

                isOpenInformationModal={isOpenInformationModal}
                setIsOpenInformationModal={setIsOpenInformationModal}
                onOpenInformationModal={(e) => onOpenInformationModal(e)}
                onCloseInformationModal={(e) => onCloseInformationModal(e)}

                isOpenComplementDossierModal={isOpenComplementDossierModal}
                setIsOpenComplementDossierModal={setIsOpenComplementDossierModal}
                onOpenComplementDossierModal={(e) => onOpenComplementDossierModal(e)}
                onCloseComplementDossierModal={(e) => onCloseComplementDossierModal(e)}

                isOpenPreparationSejourModal={isOpenPreparationSejourModal}
                setIsOpenPreparationSejourModal={setIsOpenPreparationSejourModal}
                onOpenPreparationSejourModal={(e) => onOpenPreparationSejourModal(e)}
                onClosePreparationSejourModal={(e) => onClosePreparationSejourModal(e)}

                isOpenPlayingTutorialVideoModal={isOpenPlayingTutorialVideoModal}
                setIsOpenPlayingTutorialVideoModal={setIsOpenPlayingTutorialVideoModal}
                onOpenPlayingTutorialVideoModal={(e) => onOpenPlayingTutorialVideoModal(e)}
                onClosePlayingTutorialVideoModal={(e) => onClosePlayingTutorialVideoModal(e)}
            />

            <SearchUniversityIndexModal 
                searchUniversityModalIsOpen={searchUniversityModalIsOpen} 
                setSearchUniversityModalIsOpen={setSearchUniversityModalIsOpen}
                onOpenSearchUniversityModal={onOpenSearchUniversityModal}
                onCloseSearchUniversityModal={onCloseSearchUniversityModal}
                publicSchoolOpenModal={publicSchoolOpenModal}
                setPublicSchoolOpenModal={setPublicSchoolOpenModal}
                onOpenSubSearchUniversityModal={onOpenSubSearchUniversityModal}
                onCloseSubSearchUniversityModal={onCloseSubSearchUniversityModal}
            />

            <JeMinformeIndexModal 
                jeMinformeModalIsOpen={jeMinformeModalIsOpen} 
                setJeMinformeModalIsOpen={setJeMinformeModalIsOpen}
                onOpenJeMinformeModal={onOpenJeMinformeModal}
                onCloseJeMinformeModal={onCloseJeMinformeModal}
            />

            <VisaIndexModal 
                visaModalIsOpen={visaModalIsOpen}
                setVisaModalIsOpen={setVisaModalIsOpen}
                onOpenVisaModal={onOpenVisaModal}
                onCloseVisaModal={onCloseVisaModal}
                onOpenSubModalVisa={onOpenSubModalVisa}
                onCloseSubVisaModal={onCloseSubVisaModal}
                subVisaModalIsOpen={subVisaModalIsOpen}
                setSubVisaModalIsOpen={setSubVisaModalIsOpen}
            />
        </section>
    )
}

export default LandingPageServicesV2