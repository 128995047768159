import React, {Component} from 'react';
import './EventManager.scss'
import api from '../globals/global-vars';
import baseApiUrl from '../globals/global-vars';
import axios from 'axios';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import MonEspaceEmptyUserTokenView from '../monEspaceEmptyUserTokenView/MonEspaceEmptyUserTokenView';
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/headerOutilsEtudiant'
import FeatherIcon from 'feather-icons-react';
import $ from 'jquery'
import toast, { Toaster } from 'react-hot-toast';
import NavVertical from '../navVertical/NavVertical';
import ApiBaseUrl from '../../http/ApiBaseUrl';
import BackendBaseurl from '../../http/BackendBaseUrl';



export default class EventManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
          token: window.sessionStorage.getItem('token'),
          requestConfig: {
            headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
          },
          userID: window.sessionStorage.getItem('user_id'),
          isPageLoading: false,
          api: api.api,
          baseApiUrl: baseApiUrl.baseApiUrl,
          events: [],
          eventInfos: [],
          isAddInProgress: false,
          isSuccessAdding: false,
          isErrorAdding: false,
          is_empty_name: false,
          updateEventInProgress: false,
          isUpdateEventRequestError: false,
          fields: {},
          errors: {},
          editEventInProgress: false,
          is_empty_is_active:  false,
          is_empty_image: false
        };

        this.addEventHandleChange = this.addEventHandleChange.bind(this);
        this.onSubmitAddEvent = this.onSubmitAddEvent.bind(this);
        this.editEventHandleChange = this.editEventHandleChange.bind(this);
    }

    componentDidMount = () => {
      this.onGetEvents()
    
    }

    onGetEvents = () => {
      this.setState({
        isPageLoading: true
      })
      var config = this.state.requestConfig;
  
      axios.get(ApiBaseUrl + 'evenements', config)
        .then(response => {
          this.setState({
            isPageLoading: false
          })
  
          if (response.data.success) {
            this.setState({
              events: response.data.data,
            })
            console.log(response.data.data)
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          this.setState({
            isPageLoading: false,
          })
          console.log(error)
        })
    }

    goBack = (e) => {
      e.preventDefault()
      this.props.history.goBack();
    }

    onShowEvents = (row, e) => {
      this.setState({
        eventInfos: row
      })
    }

    onDeleteEvent = (e,itemID) => {
      e.preventDefault()
      if(window.confirm('Confirmez-vous la suppression de cet événement ?')){
        var config = this.state.requestConfig
        axios.delete(`${ApiBaseUrl}evenements/${itemID}`, config)
          .then(response => {
            if(response.data.success){
              this.onGetEvents()
              toast.success('Événement supprimé avec succès.',{
                duration: 5000,
                position: 'top-center',
              })
            }else{
              console.log(response)
              toast.error('Une erreur est survenue lors de la suppression.',{
                duration: 5000,
                position: 'top-center',
              })
            }
          })
          .catch(error => {
            console.log(error)
            toast.error('Une erreur est survenue lors de la suppression.',{
              duration: 5000,
              position: 'top-center',
            })
            if (error.message === 'Request failed with status code 401') {
              this.setState({
                isSessionExpiration: true
              })
            }
          })
      }
      
    }

    actionsFormatter = (cell, row) => {
      return(
        <div className="actions-btn-container">
          <button data-toggle="modal" data-target=".edit-event-modal" className="action-btn oe-edit-btn color-white" onClick={(e) => this.onGetEventInfos(e, row)}>
            <FeatherIcon icon="edit" className="edit-icon" />
          </button>

          <button className="action-btn oe-delete-btn color-white" onClick={(e) => this.onDeleteEvent(e, row.id)}>
            <FeatherIcon icon="trash" className="trash-icon" />
          </button>
          
        </div>
      )
    }

    onGetEventInfos = (e, item) => {
      e.preventDefault()
      this.setState({
        eventInfos: item
      })
    }


    addEventHandleChange = (e) => {
      let fields = this.state.fields;

      if(e.target.name !== 'image'){
        fields[e.target.name] = e.target.value
      }else{
        fields[e.target.name] = e.target.files[0]
      }
      
      this.setState({
        fields
      })

      console.log(fields)
    }

    onValidateAddEventForm = () => {
      let fields = this.state.fields
      let errors = this.state.errors
      var isValidForm = true

      if(!fields['event_name']){
        isValidForm = false
        this.setState({
          is_empty_name: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_name: false
          })
        }, 5000);
        
        errors['name'] = 'Ce champ est obligatoire'
      }
      if(!fields['image']){
        isValidForm = false
        this.setState({
          is_empty_image: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_image: false
          })
        }, 5000);
        
        errors['image'] = 'Ce champ est obligatoire'
      }
      if(!fields['is_active'] || fields['is_active'] === 'placeholder'){
        isValidForm = false
        this.setState({
          is_empty_is_active: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_is_active: false
          })
        }, 5000);
        
        errors['is_active'] = 'Ce champ est obligatoire'
      }

      this.setState({
        errors
      })

      return isValidForm
    }

    getFormData = object => Object.keys(object).reduce((formData, key) => {
      formData.append(key, object[key]);
      return formData;
    }, new FormData());
    
    onSubmitAddEvent = (e) => {
      e.preventDefault()
      if(this.onValidateAddEventForm()){
        console.log('ok')
        this.setState({
          isAddInProgress: true
        })

        let data = this.state.fields;
        var config = this.state.requestConfig;
        
        let fd = new FormData();
        fd.append('event_name', data.event_name);
        fd.append('image', data.image);
        fd.append('is_active', data.is_active);
        fd.append('event_subscribe_link', data.event_subscribe_link);
        fd.append('user_id', this.state.userID);

        axios.post(`${ApiBaseUrl}evenements`, fd, config)
          .then(response => {
            this.setState({
              isAddInProgress: false
            })
            if(response.data.success){
              this.onGetEvents()
              document.getElementById('add-event-form').reset()
              $('.close').click()
              toast.success('Événement ajouté avec succès', {
                duration: 5000,
                position: 'top-center',
                style: {},
                className: '',
                icon: '👏',
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: '#000',
                  secondary: '#fff',
                },
                // Aria
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
              });
            }else{
              toast.error('Oupss! Une erreur est survenue lors de l\'ajout.', {
                duration: 5000,
                position: 'top-center',
                // Styling
                style: {},
                className: '',
                // Custom Icon
                // icon: '👏',
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: '#000',
                  secondary: '#fff',
                },
                // Aria
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
              });
            }
          })
          .catch(error => {
            this.setState({
              isAddInProgress: false
            })
            console.log(error)
            toast.error('Oupss! Une erreur est survenue lors de l\'ajout.', {
              duration: 5000,
              position: 'top-center',
              style: {},
              className: '',
              iconTheme: {
                primary: '#000',
                secondary: '#fff',
              },
              // Aria
              ariaProps: {
                role: 'status',
                'aria-live': 'polite',
              },
            });
          })
      }
    }

    editEventHandleChange = (e) => {
      e.persist()
      var name = e.target.name
      var value = e.target.name !== 'image' ? e.target.value : e.target.files[0];

      if(e.target.name === 'image'){
        // eslint-disable-next-line
        this.state.eventInfos.image = File;
      }
      // console.log(value)
      this.setState((prevState) => ({
        eventInfos: { ...prevState.eventInfos, [name]: value }
      }))
      console.log(this.state.eventInfos)
    }

    onValidateEditEventForm = () => {
      let fields = this.state.eventInfos
      let errors = this.state.errors
      var isValidForm = true

      if(!fields['event_name']){
        isValidForm = false
        this.setState({
          is_empty_name: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_name: false
          })
        }, 5000);
        
        errors['name'] = 'Ce champ est obligatoire'
      }
      if(!fields['is_active'] || fields['is_active'] === 'placeholder'){
        isValidForm = false
        this.setState({
          is_empty_is_active: true
        })
        setTimeout(() => {
          this.setState({
            is_empty_is_active: false
          })
        }, 5000);
        
        errors['is_active'] = 'Ce champ est obligatoire'
      }

      this.setState({
        errors
      })

      return isValidForm
    }

    onSubmitEditEvent = (e) => {
      e.preventDefault()

      console.log(this.state.eventInfos)

      if(this.onValidateEditEventForm()){
        this.setState({
          editEventInProgress: true
        })

        let data = this.state.eventInfos;
        let fd = new FormData();
        fd.append('event_name', data.event_name);
        fd.append('image', data.image);
        fd.append('is_active', data.is_active);
        fd.append('event_subscribe_link', data.event_subscribe_link);
        fd.append('user_id', this.state.userID);

        axios.put(`${this.state.api}evenements/${this.state.eventInfos.id}`, fd)
          .then(response => {
            this.setState({
              editEventInProgress: false
            })
            if(response.data.success){
              this.onGetEvents()
              $('.close').click()
              $('#edit_event_name').val('')
              this.setState({
                eventInfos: []
              })
              toast.success('Événement modifié avec succès', {
                duration: 5000,
                position: 'top-center',
                // Styling
                style: {},
                className: '',
                // Custom Icon
                icon: '👏',
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: '#000',
                  secondary: '#fff',
                },
                // Aria
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
              });
            }else{
              toast.error('Oupss! Une erreur est survenue lors de la modification.', {
                duration: 5000,
                position: 'top-center',
                // Styling
                style: {},
                className: '',
                // Custom Icon
                // icon: '👏',
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: '#000',
                  secondary: '#fff',
                },
                // Aria
                ariaProps: {
                  role: 'status',
                  'aria-live': 'polite',
                },
              });
            }
          })
          .catch(error => {
            this.setState({
              editVilleInProgress: false
            })
            console.log(error)
            toast.error('Oupss! Une erreur est survenue lors de l\'ajout.', {
              duration: 5000,
              position: 'top-center',
              // Styling
              style: {},
              className: '',
              // Custom Icon
              // icon: '👏',
              // Change colors of success/error/loading icon
              iconTheme: {
                primary: '#000',
                secondary: '#fff',
              },
              // Aria
              ariaProps: {
                role: 'status',
                'aria-live': 'polite',
              },
            });
          })
      }

    }

    isActiveFormatter = (cell, row) => {
      if(cell === true || row.is_active === 1 || row.is_active === '1'){
        return <span className="badge badge-success">actif</span>
      }
      return <span className="badge badge-danger">inactif</span>
    }

    LinkFormatter = (cell, row) => {
      return <a rel="noopener noreferrer" href={cell} target="_blank" className="event_link_deco">Ouvrir le lien</a>
    }

    ImageFormatter = (cell, row) => {
      return <img alt="Event Deco" width={'100%'} height={'100%'} className="event_img_deco" src={ BackendBaseurl + '/' + cell } />
    }


    render() {
      const options = {
        noDataText: 'Aucun article trouvé.'
        // withoutNoDataText: true, // this will make the noDataText hidden, means only showing the table header
      };

      if (this.state.token === null || this.state.token === undefined || this.state.isSessionExpiration === true) {
        if(this.state.token === null || this.state.token === undefined){
          return <MonEspaceEmptyUserTokenView type="null" />
        }else{
          return <MonEspaceEmptyUserTokenView type="session" />
        }
      }else {
        return(
          <div className="component-liste-conseillers-oe">
            {this.state.isPageLoading &&
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Chargement en cours</div>
              </div>
            }

            <HeaderOutilsEtudiant isAdminDashboard={true} />

            <div className="overlay"></div>

            <section id="salon_container" className="s-h-primo-1">
              <div className="bordure-bas">
                <div className="row p-block-20 headering-container">
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="logo-soushaulle">
                      <p className="capitalise mb-0 bold color-white mb-3">Liste des événements</p>
                    </div>
                  </div>
                </div>

                <div className="ligne mt-3 mb-5"></div>

                <div className="row lc-container">
                  <div className="nav-container nav-dashboard-color">
                      <NavVertical />
                  </div>
                  <div className="offset-xl-2 offset-lg-2 col-xl-10 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 page-content-spacing">
                    <div className="row page-content-row">
                      <div className="col-md-10 offset-md-1 orm-add-btn-col">
                        <button className="orm-add-btn btn btn-primary scrapping-siblings" data-toggle="modal" data-target=".add-event-modal">
                          <FeatherIcon icon="plus" className="add-plus-icon" />
                        </button>
                      </div>

                      <div className="col-md-12 cm-col oe-liste-conseiller-table-container">
                        <BootstrapTable data={this.state.events} 
                          options={options}
                          striped 
                          hover 
                          version='4'
                          pagination>
                            <TableHeaderColumn isKey dataField='id' hidden={true}>News ID</TableHeaderColumn>
                            <TableHeaderColumn 
                              dataField='event_name'
                              filter={{ type: 'TextFilter', delay: 1000 }}>
                              Nom de l'événement</TableHeaderColumn>
                            <TableHeaderColumn
                              dataFormat={this.LinkFormatter.bind(this)}
                              dataField='event_subscribe_link'>
                              Lien de souscription</TableHeaderColumn>
                            <TableHeaderColumn
                              dataFormat={this.isActiveFormatter.bind(this)}
                              dataField='is_active'>
                              Statut</TableHeaderColumn>
                            <TableHeaderColumn
                              dataFormat={this.ImageFormatter.bind(this)}
                              dataField='full_image_path'>
                              Image</TableHeaderColumn>
                            <TableHeaderColumn dataFormat={this.actionsFormatter.bind(this)} dataField='id'>Actions</TableHeaderColumn>
                        </BootstrapTable>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* Add Event modal */}
            <div className="modal fade add-event-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addEventModalTitle">Ajouter un événement</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form id="add-event-form">
                      <div className="row add-event-row">
                        <div className="col-md-6 offset-md-3 add-event-col">
                          <label htmlFor="event_name">Nom de l'événement</label>
                          <input onChange={this.addEventHandleChange} type="text" placeholder="Nom de l'événement" className="form-control add-event-form-control" name="event_name" id="event_name" />
                        
                          {this.state.is_empty_name &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.name } </div>
                          }
                        </div>

                        <div className="col-md-6 offset-md-3 add-event-col">
                          <label htmlFor="event_subscribe_link">Lien d'inscription de l'événement</label>
                          <input onChange={this.addEventHandleChange} type="text" placeholder="Lien d'inscription de l'événement" className="form-control add-event-form-control" name="event_subscribe_link" id="event_subscribe_link" />
                        </div>

                        <div className="col-md-6 offset-md-3 add-event-col">
                          <label htmlFor="is_active">Statut</label>
                          <select onChange={this.addEventHandleChange} type="text" placeholder="Statut de publication de l'événement" className="form-control add-event-form-control" name="is_active" id="is_active">
                            <option value="placeholder">--Choisir--</option>
                            <option value={1} >Publier en ligne</option>
                            <option value={0}>Ne pas publier</option>
                          </select>
                        
                          {this.state.is_empty_is_active &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.is_active } </div>
                          }
                        </div>

                        <div className="col-md-6 offset-md-3 add-event-col">
                          <label htmlFor="image">Image de l'événement</label>
                          <input onChange={this.addEventHandleChange} type="file" className="form-control add-event-form-control" name="image" id="image" />
                        
                          {this.state.is_empty_image &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.image } </div>
                          }
                        </div>

                        <div className="col-md-4 offset-md-4 add-event-submit-col">
                          {!this.state.isAddInProgress ?
                            <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => this.onSubmitAddEvent(e) }>
                              <FeatherIcon icon="plus" className="submit-add-icon" />&nbsp;
                              <span className="submit-add-label">Ajouter</span>
                            </button> :
                            <button className="btn btn-primary add-event-submit-btn">
                              <i className="fas fa-spin fa-spinner"></i>&nbsp;
                              <span className="submit-add-label">En cours</span>
                            </button>
                          }
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

            <div className="modal fade edit-event-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addEventModalTitle">Modifier un article</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form>
                      <div className="row add-event-row">
                        
                      <div className="col-md-6 offset-md-3 add-event-col">
                          <label htmlFor="edit_event_name">Nom de l'événement</label>
                          <input value={this.state.eventInfos?.event_name} onChange={this.editEventHandleChange} type="text" placeholder="Nom de l'événement" className="form-control add-event-form-control" name="event_name" id="edit_event_name" />
                        
                          {this.state.is_empty_name &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.name } </div>
                          }
                        </div>

                        <div className="col-md-6 offset-md-3 add-event-col">
                          <label htmlFor="edit_event_subscribe_link">Lien d'inscription de l'événement</label>
                          <input value={this.state.eventInfos?.event_subscribe_link} onChange={this.editEventHandleChange} type="text" placeholder="Lien d'inscription de l'événement" className="form-control add-event-form-control" name="event_subscribe_link" id="edit_event_subscribe_link" />
                        </div>

                        <div className="col-md-6 offset-md-3 add-event-col">
                          <label htmlFor="edit_is_active">Statut</label>
                          <select onChange={this.editEventHandleChange} type="text" placeholder="Statut de publication de l'événement" className="form-control add-event-form-control" name="is_active" id="edit_is_active">
                            <option value="placeholder">--Choisir--</option>
                            <option selected={(this.state.eventInfos?.is_active === '1' || this.state.eventInfos?.is_active === 1 || this.state.eventInfos?.is_active === true) && true} value={1} >Publier en ligne</option>
                            <option selected={(this.state.eventInfos?.is_active === '0' || this.state.eventInfos?.is_active === 0 || this.state.eventInfos?.is_active === false) && true} value={0}>Ne pas publier</option>
                          </select>
                        
                          {this.state.is_empty_is_active &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.is_active } </div>
                          }
                        </div>

                        <div className="col-md-6 offset-md-3 add-event-col">
                          <label htmlFor="edit_image">Image de l'événement</label>
                          <input onChange={this.editEventHandleChange} type="file" className="form-control add-event-form-control" name="image" id="edit_image" />
                        
                          {this.state.is_empty_image &&
                            <div className="alert alert-danger oe-alert-danger"> { this.state.errors.image } </div>
                          }
                        </div>

                        <div className="col-md-4 offset-md-4 add-event-submit-col">
                          {!this.state.editEventInProgress ?
                            <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => this.onSubmitEditEvent(e) }>
                              <FeatherIcon icon="edit" className="submit-add-icon" />&nbsp;
                              <span className="submit-add-label">Modifier</span>
                            </button> :
                            <button className="btn btn-primary add-event-submit-btn">
                              <i className="fas fa-spin fa-spinner"></i>&nbsp;
                              <span className="submit-add-label">En cours</span>
                            </button>
                          }
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

            <Toaster />

            {/* {this.state.isNewsDeletedSuccess || this.state.isSuccessScrapping || this.state.isErrorScrapping ?
              <ToastContainer /> : ''
            } */}
          </div>
        )
      }
    }
  }