import React from 'react'
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ExportToExcel = (props) => {
    return (
        props?.isRDV ?
        <ExcelFile filename={`Export en Excel`} element={`${props?.date ? props?.date : ''} Exporter en Excel`}>
            <ExcelSheet data={props?.exportData} name={`Export Excel ${props?.date ? props?.date : ' des inscriptions aux JPO'}`} >
                {props?.exportLabels?.map((item) => 
                    <ExcelColumn label={item.label} value={item.value} widthPx="500"/>
                )}
            </ExcelSheet>
        </ExcelFile> :
        props?.isProfile ? 
        <ExcelFile filename={`Liste_Candidats_Auto_Evalués`} element={`Exporter Tout`}>
            <ExcelSheet data={props?.exportData} name={'Liste des candidats auto-évalués'} >
                {props?.exportLabels?.map((item) => 
                    <ExcelColumn label={item.label} value={item.value} widthPx="900"/>
                )}
            </ExcelSheet>
        </ExcelFile> : 
        props?.isProfileFilter ? 
        <ExcelFile filename={`Liste_Candidats_Auto_Evalués_Filtrée`} element={`Exporter votre filtre en Excel`}>
            <ExcelSheet data={props?.exportData} name={'Liste des candidats auto-évalués filtrée'} >
                {props?.exportLabels?.map((item) => 
                    <ExcelColumn label={item.label} value={item.value} widthPx="900"/>
                )}
            </ExcelSheet>
        </ExcelFile> :
        props?.isFilteredCheckout ?
        <ExcelFile filename={`${props?.filteredEventType}_${props?.filteredPeriod}`} element={`Exporter en Excel`}>
            <ExcelSheet data={props?.exportData} name={`${props?.filteredEventType}_${props?.filteredPeriod}`} >
                {props?.exportLabels?.map((item) => 
                    <ExcelColumn label={item.label} value={item.value} widthPx="900"/>
                )}
            </ExcelSheet>
        </ExcelFile> :
        <ExcelFile filename={`Export Excel Inscrits Newsletter`} element={`Exporter en Excel`}>
            <ExcelSheet data={props?.exportData} name={'Export Excel Inscrits Newsletter'} >
                {props?.exportLabels?.map((item) => 
                    <ExcelColumn label={item.label} value={item.value} widthPx="900"/>
                )}
            </ExcelSheet>
        </ExcelFile>
    )
}

// filteredPeriod={this.state.filteredPeriod} filteredEventType={this.state.filteredEventType} isFilteredCheckout={true}