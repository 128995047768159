import React, {Component} from 'react';
import './ListGeneratedLinks.scss'

import api from './../globals/global-vars';
import axios from 'axios';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import MonEspaceEmptyUserTokenView from '../monEspaceEmptyUserTokenView/MonEspaceEmptyUserTokenView';
import HeaderOutilsEtudiant from '../headerOutilsEtudiant/headerOutilsEtudiant'
import FeatherIcon from 'feather-icons-react';
import copy from "copy-to-clipboard";  
import CopyNotification from '../copyNotification/CopyNotification'
import NavVertical from '../navVertical/NavVertical';

export default class ListGeneratedLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {
          token: window.sessionStorage.getItem('token'),
          requestConfig: {
            headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
          },
          userID: window.sessionStorage.getItem('user_id'),
          isPageLoading: false,
          api: api.api,
          listLinks: [],
          linkInfos: [],
          isSuccessCopied: false,

        };
    }

    componentDidMount(){
      this.onGetGeneratedLinks()
    }

    onGetGeneratedLinks = () => {
      this.setState({
        isPageLoading: true
      })
  
      var api = this.state.api;
      var config = this.state.requestConfig;
  
      axios.get(api + 'event_subscribe_link_generates', config)
        .then(response => {
          this.setState({
            isPageLoading: false
          })
  
          if (response.data.success) {
            this.setState({
              listLinks: response.data.data,
            })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          this.setState({
            isPageLoading: false,
          })
          console.log(error)
          if (error.message === 'Request failed with status code 401') {
            this.setState({
              isSessionExpiration: true
            })
          }
        })
    }

    actionsFormatter = (cell, row) => {
      return(
        <div className="actions-btn-container">
          <button className="btn btn-primary action-btn show-btn" data-toggle="modal" data-target=".show-link-infos-modal" onClick={() => this.onGetLinkInfos(row)}>
            <FeatherIcon icon="eye" className="action-icon" />
          </button>
        </div>
      )
    }

    onGetLinkInfos = (link) => {
      this.setState({
        linkInfos: link
      })
    }

    onClickToCopy = (copied) => {
      if(copy(copied)){
        this.setState({
          isSuccessCopied: true
        })
      }
     
      setTimeout(() => {
        this.setState({
          isSuccessCopied: false
        })
      }, 5000);
    }

    openInfosModalAndFormatter = (cell, row) => {
      return(
        <span className="linkable linkable-item" data-toggle="modal" data-target=".show-link-infos-modal" onClick={() => this.onGetLinkInfos(row)}>
          { cell }
        </span>
      )
    }


    render() {
      if (this.state.token === null || this.state.token === undefined || this.state.isSessionExpiration === true) {
        if(this.state.token === null || this.state.token === undefined){
          return <MonEspaceEmptyUserTokenView type="null" />
        }else{
          return <MonEspaceEmptyUserTokenView type="session" />
        }
      }else {
        const options = {
          noDataText: 'Aucun lien trouvé.'
        };
        return(
          <div className="component-list-generated-links">
            {this.state.isSuccessCopied &&
              <CopyNotification />
            }

            {this.state.isPageLoading &&
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Chargement en cours</div>
              </div>
            }

            <HeaderOutilsEtudiant isAdminDashboard={true} />
            <div className="overlay"></div>

            <section id="salon_container" className="s-h-primo-1">
              <div className="bordure-bas">
                <div className="row p-block-20 headering-container">
                  <div className="col-md-12 d-flex justify-content-center">
                    <div className="logo-soushaulle">
                      <p className="capitalise mb-0 bold color-white mb-3">Liste des liens générés</p>
                    </div>
                  </div>
                </div>

                <div className="ligne mt-3 mb-5"></div>

                <div className="row lc-container">
                  <div className="nav-container nav-dashboard-color">
                      <NavVertical />
                  </div>
                  <div className="offset-xl-2 offset-lg-2 col-xl-10 col-lg-10 col-md-12 content-position-after-header px-md-3 px-0">
                      <div className="row page-content-row">
                        <div className="col-md-12 cm-col oe-liste-liens-table-container">
                        <BootstrapTable data={this.state.listLinks} 
                          options={options}
                          striped 
                          hover 
                          version='4'
                          pagination>
                            <TableHeaderColumn isKey dataField='id' hidden={true}>ID</TableHeaderColumn>
                            <TableHeaderColumn
                              dataFormat={this.openInfosModalAndFormatter.bind(this)}
                              width="400"
                              dataField='visitor_email'
                              filter={{ type: 'TextFilter', delay: 1000 }}>
                              Email du visiteur</TableHeaderColumn>
                            <TableHeaderColumn 
                              dataFormat={this.openInfosModalAndFormatter.bind(this)}
                              width="250"
                              filter={{ type: 'TextFilter', delay: 1000 }}
                              dataField='event_type'>
                              Type de seances</TableHeaderColumn>
                            <TableHeaderColumn 
                              dataFormat={this.openInfosModalAndFormatter.bind(this)}
                              width="900" 
                              dataField='generated_url'>
                              Url</TableHeaderColumn>
                            <TableHeaderColumn width="100" dataFormat={this.actionsFormatter.bind(this)} dataField='id'>Actions</TableHeaderColumn>
                        </BootstrapTable>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </section>
            
            

            <div className="modal fade show-link-infos-modal oe-modal" tabIndex="-1" role="dialog" aria-labelledby="showLinkInfosLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title" id="showLinkInfosLabel"> {`${this.state.linkInfos.event_type}`} </h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                          </button>
                      </div>

                      <div className="modal-body">
                          <div className="row orm-row">
                              <div className="col-md-12 link-item-container">
                                <h3 className="link-item-title">Type de séance</h3>
                                <div className="link-item-content">
                                  { this.state.linkInfos.visitor_email }
                                </div>
                              </div>
                              <div className="separator-line"></div>
                              <div className="col-md-12 link-item-container">
                                <h3 className="link-item-title">Email du visiteur</h3>
                                <div className="link-item-content">
                                  { this.state.linkInfos.visitor_email }
                                </div>
                              </div>
                              <div className="separator-line"></div>
                              <div className="col-md-12 link-item-container">
                                <h3 className="link-item-title">URL générée</h3>
                                <div className="link-item-content">
                                  { this.state.linkInfos.generated_url }&nbsp;
                                  <span className="click-to-copy linkable" onClick={() => this.onClickToCopy(this.state.linkInfos.generated_url)}>
                                    Copier
                                  </span>
                                </div>
                              </div>
                              <div className="separator-line"></div>
                          </div>
                      </div>

                  </div>
              </div>
            </div>
          </div>
        );
      }
    }
}
