/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ReturnBack from '../../../../img/v2/icons/ReturnBack.svg';
import OrientationIcon from '../../../../img/v2/icons/OrientationIcon.svg';
import WarningIcon from '../../../../img/v2/icons/WarningIcon.svg';
import 'react-tabs/style/react-tabs.css';
import ApiBaseUrl from '../../../../http/ApiBaseUrl';
import axios from 'axios';
import Talk from "talkjs";
import $ from 'jquery';
import talkjsID from '../../../../http/TalkJSInfos';
import toast from 'react-hot-toast';
import FeatherIcon from 'feather-icons-react';
import ReactPlayer from 'react-player';
import moment from 'moment';
import formatDate from '../../../../utils';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import FrontendBaseUrl from '../../../../http/FrontendBaseUrl';
import { differenceInCalendarDays } from 'date-fns';



const JeMorienteIndexModal = (props) => {
    const [isActiveChat, setIsActiveChat] = useState();
    const [helpFields, setHelpFields] = useState({}); 
    const [helpFieldsErrors, setHelpFieldsErrors] = useState({});
    const [isOthersChoices, setIsOthersChoices] = useState(false);
    const [isBacChoice, setIsBacChoice] = useState(false);
    const [showBacSubmitBtn, setShowBacSubmitBtn] = useState(false);
    const [isShowBachelierBesoin, setIsShowBachelierBesoin] = useState(false);
    const [isShowBachelierCompteEtude, setIsShowBachelierCompteEtude] = useState(false);
    // eslint-disable-next-line
    const [isShowBachelierCompteEtudeNumber, setIsShowBachelierCompteEtudeNumber] = useState(false);
    const [isShowCompteNumeroInput, setIsShowCompteNumeroInput] = useState(false);
    const [isShowBesoinOther, setIsShowBesoinOther] = useState(false);
    const [isShowBesoinOtherPostBac, setIsShowBesoinOtherPostBac] = useState(false);
    const [isShowBesoinInput, setIsShowBesoinInput] = useState(false);
    const [showOtherSubmitBtn, setShowOtherSubmitBtn] = useState(false);
    const [is_empty_visitor_fullname, set_is_empty_visitor_fullname] = useState(false);
    const [is_empty_visitor_phone, set_is_empty_visitor_phone] = useState(false);
    const [is_empty_visitor_email, set_is_empty_visitor_email] = useState(false);
    const [visitor_email_syntax_error, set_visitor_email_syntax_error] = useState(false);
    const [is_empty_numero_compte_etude, set_is_empty_numero_compte_etude] = useState(false);
    const [openChatInProgress, setOpenChatInProgress] = useState(false);
    const [cannotChat, setCannotChat] = useState(false);
    const [isDisabledChat, setisDisabledChat] = useState(false);
    const [isErrorTalkTo, setIsErrorTalkTo] = useState(false);

    const [comingOrientations, setComingOrientations] = useState([]);
    const [countComingOrientations, setCountComingOrientations] = useState();
    const [isEmptyComingOrientation, setIsEmptyComingOrientation] = useState(false);
    const [orientationVideosList, setOrientationVideosList] = useState([]);
    const [orientationCountVideos, setOrientationCountVideos] = useState();
    const [videoInfos, setVideoInfos] = useState();
    const [eventInfos, setEventInfos] = useState();

    const [checkoutFields, setCheckoutFields] = useState({});
    const [checkoutHasCompteEtudesFR, setCheckoutHasCompteEtudesFR] = useState(false);
    const [checkoutErrors, setCheckoutErrors] = useState({});
    const [is_empty_checkout_date_id, set_is_empty_checkout_date_id] = useState(false);
    const [is_empty_event_checkout_fullname, set_is_empty_event_checkout_fullname] = useState(false);
    const [is_empty_event_checkout_email, set_is_empty_event_checkout_email] = useState(false);
    const [is_invalid_event_checkout_email, set_is_invalid_event_checkout_email] = useState(false);
    const [is_empty_event_checkout_phone, set_is_empty_event_checkout_phone] = useState(false);
    const [is_empty_event_checkout_address, set_is_empty_event_checkout_address] = useState(false);
    const [is_empty_event_checkout_region, set_is_empty_event_checkout_region] = useState(false);
    const [is_different_event_checkout_region, set_is_different_event_checkout_region] = useState(false);
    const [is_empty_event_checkout_compte_etudes_fr, set_is_empty_event_checkout_compte_etudes_fr] = useState(false);
    const [is_empty_event_checkout_compte_etudes_fr_number, set_is_empty_event_checkout_compte_etudes_fr_number] = useState(false);
    const [eventCheckoutInProgress, setEventCheckoutInProgress] = useState(false);
    const [villes, setVilles] = useState([]);
    const [comingSeanceDeRemplissages, setComingSeanceDeRemplissages] = useState([]);
    const [countComingSeanceDeRemplissages, setCountComingSeanceDeRemplissages] = useState();
    const [isEmptyComingSeanceDeRemplissages, setIsEmptyComingSeanceDeRemplissages] = useState(false);
    const [remplissageVideosList, setRemplissageVideosList] = useState([]);
    const [remplissageCountVideos, setRemplissageCountVideos] = useState();
    const [comingInformations, setComingInformations] = useState([]);
    const [countComingInformations, setCountComingInformations] = useState();
    const [isEmptyComingInformations, setIsEmptyComingInformations] = useState(true);
    const [informationVideosList, setInformationVideosList] = useState([]);
    const [informationCountVideos, setInformationCountVideos] = useState();
    const [comingSoonComplementDossier, setComingSoonComplementDossier] = useState([]);
    const [countComingSoonComplementDossier, setCountComingSoonComplementDossier] = useState();
    const [isEmptyComingSoonComplementDossier, setIsEmptyComingSoonComplementDossier] = useState(false);
    const [complementDossierVideo, setComplementDossierVideo] = useState([]);
    const [countComplementDossierVideo, setCountComplementDossierVideo] = useState();
    const [comingPreparationDepart, setComingPreparationDepart] = useState([]);
    const [countComingPreparationDepart, setCountComingPreparationDepart] = useState();
    const [isEmptyComingPreparationDepart, setIsEmptyComingPreparationDepart] = useState(true);
    const [preparationDepartVideo, setPreparationDepartVideo] = useState([]);
    const [countPreparationDepartVideo, setCountPreparationDepartVideo] = useState();
    const [tutorielEventType, setTutorielEventType] = useState(null);
    const [unreadCount, setUnreadCount] = useState();
    const [showNumeroDossierInput, setShowNumeroDossierInput] = useState(false);
    const [is_empty_numero_dossier_auto_evaluation, set_is_empty_numero_dossier_auto_evaluation] = useState(false);
    const [is_empty_has_doing_auto_evaluation, set_is_empty_has_doing_auto_evaluation] = useState(false);

    const [hasNoRequest, setHasNoRequest] = useState(true);
    const [checkEventInProgress, setCheckEventInProgress] = useState(false);
    const [choosedDateEvent, setChoosedDateEvent] = useState();
    const [value, setValue] = useState();
    const [availableDates, setAvailableDates] = useState([]);
    const [remplissageAvailableDates, setRemplissageAvailableDates] = useState([]);
    const [informationAvailableDates, setInformationAvailableDates] = useState([]);
    const [complementDossierAvailableDates, setComplementDossierAvailableDates] = useState([]);
    const [preparationDepartAvailableDates, setPreparationDepartAvailableDates] = useState([]);

    const onChangeDate = (value, event) => {
        console.log('event', event);
        setValue(value)
    }

    // token: window.sessionStorage.getItem('token'),
    // userAvatar: window.sessionStorage.getItem('user_avatar'),
    const requestConfig = {
        headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
    };


    const onGetChatStatus = () => {
        axios.get(`${ApiBaseUrl}chat_mon_espace_configs/${1}`)
            .then(response => {
              // console.log(response)
              if(response.data.success && response.data.is_active_chat){
                setIsActiveChat(true)
              }else if(response.data.success && !response.data.is_active_chat){
                setIsActiveChat(false)
              }
            })
            .catch(error => {
                console.log(error)
            })
    }

    const onGetVilles = () => {
        axios.get(ApiBaseUrl + 'villes', requestConfig)
          .then(response => {
            if (response.data.success) {
              setVilles(response.data.data)
            } else {
              console.log(response)
            }
          })
          .catch(error => {
            console.log(error)
          })
    }

    const handleHelpChoice = (e) => {
        let _helpFields = helpFields
        _helpFields[e.target.name] = e.target.value

        if(e.target.name === 'visitor_email'){
            _helpFields[e.target.name] = e.target.value.toLowerCase()
        }

        if(_helpFields['has_doing_auto_evaluation'] === '1' || _helpFields['has_doing_auto_evaluation'] === 1){
            setShowNumeroDossierInput(true);
        }
        if(_helpFields['has_doing_auto_evaluation'] === '0' || _helpFields['has_doing_auto_evaluation'] === 0){
            setShowNumeroDossierInput(false);
        }
        if(_helpFields['last_degree'] === 'BAC' || _helpFields['last_degree'] === 'TERMINALE'){
            setIsBacChoice(true);
            setIsOthersChoices(false);
            setShowBacSubmitBtn(false);
            setIsShowCompteNumeroInput(false);

            _helpFields['isBacChoice'] = true
        }
        if(_helpFields['last_degree'] === 'BAC+1' || 
            _helpFields['last_degree'] === 'BAC+2' ||
            _helpFields['last_degree'] === 'BAC+3' ||
            _helpFields['last_degree'] === 'BAC+4' ||
            _helpFields['last_degree'] === 'BAC+5' ||
            _helpFields['last_degree'] === 'BAC+6' ||
            _helpFields['last_degree'] === 'BAC+7' ||
            _helpFields['last_degree'] === 'BAC+8'){
            setIsBacChoice(false);
            setIsOthersChoices(true);
            setShowBacSubmitBtn(false);
            setIsShowBachelierCompteEtude(false);
            setIsShowBachelierBesoin(false);
            setIsShowBachelierCompteEtudeNumber(false);

            _helpFields['bac_serie'] = null
            _helpFields['isBacCompteCampusYesChoice'] = false
            _helpFields['isBacChoice'] = false
        }
        if(_helpFields['bac_serie']){
            setIsShowBachelierBesoin(true);
        }
        if(_helpFields['bac_serie'] && _helpFields['besoins']){
            setIsShowBachelierCompteEtude(true);
        }
        if(_helpFields['bac_serie'] && _helpFields['besoins'] && _helpFields['compte_etude_fr'] === 'OUI'){
            setIsShowBachelierCompteEtudeNumber(true);
            _helpFields['isBacCompteCampusYesChoice'] = true
        }
        if(_helpFields['bac_serie'] && _helpFields['besoins'] && _helpFields['compte_etude_fr'] && _helpFields['numero_compte_etude_fr']){
            setShowBacSubmitBtn(true);
        }
        if(_helpFields['compte_etude_fr'] === 'NON'){
            setShowBacSubmitBtn(true);
        }

        if(_helpFields['besoins'] === 'Autre'){
            setIsShowBesoinOther(true);
        }else{
            setIsShowBesoinOther(false);
        }
        if(_helpFields['besoins_post_bac'] === 'Autre'){
            setIsShowBesoinOtherPostBac(true);
        }else{
            setIsShowBesoinOtherPostBac(false);
        }
        if(_helpFields['domaine']){
            setIsShowBesoinInput(true);
        }
        if(_helpFields['bac_serie'] === null && _helpFields['compte_etude_fr_post_bac'] === 'OUI'){
            setIsShowCompteNumeroInput(true);
            setShowOtherSubmitBtn(true);
        }
        if(_helpFields['bac_serie'] === null && _helpFields['compte_etude_fr_post_bac'] === 'NON'){
            setIsShowCompteNumeroInput(false);
            setShowOtherSubmitBtn(true);
        }

        setHelpFields(_helpFields)

        console.log(_helpFields)
    }

    const onValidateOpenChatForm = () => {
        let errors = helpFieldsErrors
        var isValidForm = true

        if(!helpFields['has_doing_auto_evaluation']){
            isValidForm = false
            set_is_empty_has_doing_auto_evaluation(true)
            setTimeout(() => {
                set_is_empty_has_doing_auto_evaluation(false);
            }, 5000);
            errors['has_doing_auto_evaluation'] = 'Ce champ est obligatoire'
        }
        if(showNumeroDossierInput){
            if(!helpFields['numero_dossier_auto_evaluation']){
                isValidForm = false
                set_is_empty_numero_dossier_auto_evaluation(true)
                setTimeout(() => {
                    set_is_empty_numero_dossier_auto_evaluation(false);
                }, 5000);
                errors['numero_dossier_auto_evaluation'] = 'Ce champ est obligatoire'
            }
        }
        if(!helpFields['visitor_fullname']){
            isValidForm = false
            set_is_empty_visitor_fullname(true)
            setTimeout(() => {
                set_is_empty_visitor_fullname(false);
            }, 5000);
            errors['visitor_fullname'] = 'Ce champ est obligatoire'
        }
        if(!helpFields['visitor_phone']){
            isValidForm = false
            set_is_empty_visitor_phone(true)
            setTimeout(() => {
                set_is_empty_visitor_phone(false);
            }, 5000);
            errors['visitor_phone'] = 'Ce champ est obligatoire'
        }
        if(!helpFields['visitor_email']){
            isValidForm = false
            set_is_empty_visitor_email(true);
            setTimeout(() => {
                set_is_empty_visitor_email(false);
            }, 5000);
            errors['visitor_email'] = 'Ce champ est obligatoire'
        }
        if(typeof helpFields["visitor_email"] !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(helpFields["visitor_email"])) {
                isValidForm = false;
                set_visitor_email_syntax_error(true);
                setTimeout(() => {
                    set_visitor_email_syntax_error(false);
                }, 5000);
                errors["visitor_email_syntax"] = "Votre adresse email est invalide.";
            }
        }

        if(helpFields['isBacChoice'] === false && helpFields['compte_etude_fr'] === 'OUI'){
            if(!helpFields['numero_compte_etude_fr']){
                isValidForm = false
                set_is_empty_numero_compte_etude(true);
                setTimeout(() => {
                    set_is_empty_numero_compte_etude(false);
                }, 5000);
                errors['numero_compte_etude'] = 'Vous devez renseigner votre numéro de compte étude'
            }
        }

        setHelpFieldsErrors(errors);
        return isValidForm;
    }

    const onRenderHelpSectionForm = () => {
        return(
            <form onSubmit={(e) => onSubmitOpenChatModal(e)}>
                <div className="row o-row talk-to-form-container-row">
                    
                    <div className="col-md-6 o-form-col">
                        <div className="form-group o-form-group">
                            <label htmlFor="visitor_fullname">Votre Prénom et Nom de famille <span className="required-icon">*</span> </label>
                            <input placeholder="Votre Prénom et Nom de famille" onChange={handleHelpChoice} name="visitor_fullname" className="form-control oe-form-control" id="visitor_fullname" />
                        </div>
                        {is_empty_visitor_fullname &&
                            <div className="alert alert-danger oe-alert-danger">
                                {helpFieldsErrors.visitor_fullname}
                            </div>
                        }
                    </div>

                    <div className="col-md-6 o-form-col">
                        <div className="form-group o-form-group">
                            <label htmlFor="visitor_email">Votre Email <span className="required-icon">*</span></label>
                            <input placeholder="Votre Email" onChange={handleHelpChoice} name="visitor_email" className="form-control oe-form-control" id="visitor_email" />
                        </div>
                        {is_empty_visitor_email &&
                            <div className="alert alert-danger oe-alert-danger">
                                {helpFieldsErrors.visitor_email}
                            </div>
                        }
                        {visitor_email_syntax_error &&
                            <div className="alert alert-danger oe-alert-danger">
                                {helpFieldsErrors.visitor_email_syntax}
                            </div>
                        }
                    </div>

                    <div className="col-md-6 o-form-col">
                        <div className="form-group o-form-group">
                            <label htmlFor="visitor_phone">Votre Numéro de téléphone <span className="required-icon">*</span></label>
                            <input placeholder="Votre Numéro de téléphone" onChange={handleHelpChoice} name="visitor_phone" className="form-control oe-form-control" id="visitor_phone" />
                        </div>
                        {is_empty_visitor_phone &&
                            <div className="alert alert-danger oe-alert-danger">
                                {helpFieldsErrors.visitor_phone}
                            </div>
                        }
                    </div>

                    <div className="col-md-6 o-form-col">
                        <div className="form-group o-form-group">
                            <label htmlFor="has_doing_auto_evaluation">Avez-vous été auto-évalué ? <span className="required-icon">*</span></label>
                            <select onChange={handleHelpChoice} name="has_doing_auto_evaluation" className="form-control oe-form-control v2-select-form-control" id="has_doing_auto_evaluation">
                                <option selected disabled>--Choisir--</option>
                                <option value={1}>Oui</option>
                                <option value={0}>Non</option>
                            </select>
                        </div>
                        {is_empty_has_doing_auto_evaluation &&
                            <div className="alert alert-danger oe-alert-danger">
                                {helpFieldsErrors.has_doing_auto_evaluation}
                            </div>
                        }
                    </div>
                    
                    {showNumeroDossierInput &&
                        <div className="col-md-6 o-form-col">
                            <div className="form-group o-form-group">
                                <label htmlFor="numero_dossier_auto_evaluation">Votre Numéro de dossier <span className="required-icon">*</span></label>
                                <input placeholder="Votre Numéro de dossier" onChange={handleHelpChoice} name="numero_dossier_auto_evaluation" className="form-control oe-form-control" id="numero_dossier_auto_evaluation" />
                            </div>
                            {is_empty_numero_dossier_auto_evaluation &&
                                <div className="alert alert-danger oe-alert-danger">
                                    {helpFieldsErrors.numero_dossier_auto_evaluation}
                                </div>
                            }
                        </div>
                    }

                    <div className="col-md-6 o-form-col">
                        <div className="form-group o-form-group">
                            <label htmlFor="last_degree">Votre dernier diplôme obtenu</label>
                            <select onChange={handleHelpChoice} name="last_degree" className="form-control oe-form-control v2-select-form-control" id="last_degree">
                                <option selected disabled>--Choisir un diplôme--</option>
                                <option value="TERMINALE">Terminale</option>
                                <option value="BAC">BAC</option>
                                <option value="BAC+1">BAC+1</option>
                                <option value="BAC+2">BAC+2</option>
                                <option value="BAC+3">BAC+3</option>
                                <option value="BAC+4">BAC+4</option>
                                <option value="BAC+5">BAC+5</option>
                                <option value="BAC+6">BAC+6</option>
                                <option value="BAC+7">BAC+7</option>
                                <option value="BAC+8">BAC+8</option>
                            </select>
                        </div>
                    </div>
                    
                    {isBacChoice &&
                        <>
                            <div className="col-md-6 o-form-col">
                                <div className="form-group o-form-group">
                                    <label htmlFor="bac_serie">Votre Série</label>
                                    <select onChange={handleHelpChoice} name="bac_serie" className="form-control oe-form-control v2-select-form-control" id="bac_serie">
                                        <option>--Choisir une série--</option>
                                        <option value="steg">STEG</option>
                                        <option value="litterature">L</option>
                                        <option value="science">S</option>
                                        <option value="technique">T</option>
                                    </select>
                                </div>
                            </div>

                            {isShowBachelierBesoin &&
                                <div className="col-md-6 o-form-col">
                                    <div className="form-group o-form-group">
                                        <label htmlFor="besoins">Quels sont vos besoins ?</label>
                                        <select onChange={handleHelpChoice} name="besoins" className="form-control oe-form-control v2-select-form-control" id="besoins">
                                            <option>--Faites votre choix--</option>
                                            <option value="Trouver les formations qui vont avec mon potentiel, mes qualités et mes centres d'intérêt">Trouver les formations qui vont avec mon potentiel, mes qualités et mes centres d'intérêt</option>
                                            <option value="Avoir des informations sur la vie universitaire">Avoir des informations sur la vie universitaire</option>
                                            <option value="Avoir un carnet d'adresse d'établissement">Avoir un carnet d'adresse d'établissement</option>
                                            <option value="Avoir des informations sur les métiers">Avoir des informations sur les métiers</option>
                                            <option value="Définir mon parcours d'étude">Définir mon parcours d'étude</option>
                                            <option value="Autre">Autre</option>
                                        </select>
                                    </div>
                                </div>
                            }

                            {isShowBesoinOther &&
                                <div className="col-md-6 o-form-col">
                                    <div className="form-group o-form-group">
                                        <label htmlFor="besoins-autres">Précisez vos besoins <span className="required-icon">*</span> </label>
                                        <input placeholder="Précisez vos besoins" onChange={handleHelpChoice} name="autre-besoins" className="form-control oe-form-control" id="besoins-autres" />
                                    </div>
                                    {/* {is_empty_visitor_fullname &&
                                        <div className="alert alert-danger oe-alert-danger">
                                            {helpFieldsErrors.visitor_fullname}
                                        </div>
                                    } */}
                                </div>
                            }

                            {isShowBachelierCompteEtude &&
                                <div className="col-md-6 o-form-col">
                                    <div className="form-group o-form-group">
                                        <label htmlFor="compte_etude_fr">Avez-vous un compte étude en France ?</label>
                                        <select onChange={handleHelpChoice} name="compte_etude_fr" className="form-control oe-form-control v2-select-form-control" id="compte_etude_fr">
                                            <option>--Faites votre choix--</option>
                                            <option value="OUI">OUI</option>
                                            <option value="NON">NON</option>
                                        </select>
                                    </div>
                                </div>
                            }

                            {helpFields['isBacCompteCampusYesChoice'] &&
                                <div className="col-md-6 o-form-col">
                                    <div className="form-group o-form-group">
                                        <label htmlFor="numero_compte_etude_fr">Précisez votre numéro SN <span className="required-icon">*</span></label>
                                        <input placeholder="Renseignez votre numéro de compte SN" onChange={handleHelpChoice} name="numero_compte_etude_fr" className="form-control oe-form-control" id="numero_compte_etude_fr" />
                                    </div>
                                    {is_empty_numero_compte_etude &&
                                        <div className="alert alert-danger oe-alert-danger">
                                            {helpFieldsErrors.numero_compte_etude}
                                        </div>
                                    }
                                </div>
                            }

                            {((helpFields['bac_serie'] || helpFields['bac_serie'] !== null) && showBacSubmitBtn) &&
                                <div className="row open-chat-btn-row">
                                    <div className="col-md-6 offset-md-3 open-chat-btn-col">
                                        {!openChatInProgress ?
                                            <button className="btn btn-primary oe-primary-btn open-chat-btn">
                                                Chattez avec un conseiller
                                            </button> :
                                            <button className="btn btn-primary oe-primary-btn open-chat-btn">
                                                Demande en cours&nbsp;
                                                <i className="fas fa-spin fa-spinner"></i>
                                            </button>
                                        }
                                    </div>
                                </div>
                            }

                                
                        </>
                    }

                    {(isOthersChoices && (!isBacChoice)) &&
                        <div className="col-md-6 o-form-col">
                            <div className="form-group o-form-group">
                                <label htmlFor="domaine">Votre domaine d'étude</label>
                                <select onChange={handleHelpChoice} name="domaine" className="form-control oe-form-control v2-select-form-control" id="domaine">
                                    <option>--Choisir un domaine--</option>
                                    <option value="Architecture, Urbanisme et Aménagement du territoire">Architecture, Urbanisme et Aménagement du territoire</option>
                                    <option value="Management, Gestion Finances et Commerce">Management, Gestion Finances et Commerce</option>
                                    <option value="Communication, Multimédia et Journalisme">Communication, Multimédia et Journalisme</option>
                                    <option value="Environnement et Science de la Terre">Environnement et Science de la Terre</option>

                                    <option value="Tourisme, hôtellerie et restauration">Tourisme, hôtellerie et restauration</option>
                                    <option value="Sciences économiques et de gestion">Sciences économiques et de gestion</option>
                                    <option value="Sciences humaines et sociales">Sciences humaines et sociales</option>
                                    <option value="Santé et professions sociales">Santé et professions sociales</option>
                                    <option value="Arts, culture, design et mode">Arts, culture, design et mode</option>
                                    <option value="Agriculture-agroalimentaire">Agriculture-agroalimentaire</option>
                                    <option value="Sciences de l'éducation">Sciences de l'éducation</option>
                                    <option value="Sciences de l'ingénieur">Sciences de l'ingénieur</option>
                                    <option value="Transport et logistique">Transport et logistique</option>
                                    <option value="Langues et lettres">Langues et lettres</option>
                                    <option value="Mathématiques">Mathématiques</option>
                                    <option value="Informatique">Informatique</option>
                                    <option value="Biologie">Biologie</option>
                                    <option value="Chimie">Chimie</option>
                                    <option value="Sports">Sports</option>
                                    <option value="Droit et sciences politiques">Droit et sciences politiques</option>
                                    <option value="Physique">Physique</option>
                                </select>
                            </div>
                        </div>

                        
                    }

                    {(isShowBesoinInput && !isBacChoice && helpFields[''] !== null) &&
                        <>
                            <div className="col-md-6 o-form-col">
                                <div className="form-group o-form-group">
                                    <label htmlFor="besoins">Quels sont vos besoins ?</label>
                                    <select onChange={handleHelpChoice} name="besoins_post_bac" className="form-control oe-form-control v2-select-form-control" id="besoins">
                                        <option>--Faites votre choix--</option>
                                        <option value="Trouver les formations qui vont avec mon potentiel, mes qualités et mes centres d'intérêt">Trouver les formations qui vont avec mon potentiel, mes qualités et mes centres d'intérêt</option>
                                        <option value="Avoir des informations sur la vie universitaire">Avoir des informations sur la vie universitaire</option>
                                        <option value="Avoir un carnet d'adresse d'établissement">Avoir un carnet d'adresse d'établissement</option>
                                        <option value="Avoir des informations sur les métiers">Avoir des informations sur les métiers</option>
                                        <option value="Définir mon parcours d'étude">Définir mon parcours d'étude</option>
                                        <option value="Autre">Autre</option>
                                    </select>
                                </div>
                            </div>

                            {isShowBesoinOtherPostBac &&
                                <div className="col-md-6 o-form-col">
                                    <div className="form-group o-form-group">
                                        <label htmlFor="besoins-autres">Précisez vos besoins <span className="required-icon">*</span> </label>
                                        <input placeholder="Précisez vos besoins" onChange={handleHelpChoice} name="autre_besoins_post_bac" className="form-control oe-form-control" id="besoins-autres" />
                                    </div>
                                    {/* {is_empty_visitor_fullname &&
                                        <div className="alert alert-danger oe-alert-danger">
                                            {helpFieldsErrors.visitor_fullname}
                                        </div>
                                    } */}
                                </div>
                            }

                            <div className="col-md-6 o-form-col">
                                <div className="form-group o-form-group">
                                    <label htmlFor="compte_etude_fr_post_bac">Avez-vous un compte étude en France ?</label>
                                    <select onChange={handleHelpChoice} name="compte_etude_fr_post_bac" className="form-control oe-form-control v2-select-form-control" id="compte_etude_fr_post_bac">
                                        <option>--Faites votre choix--</option>
                                        <option value="OUI">OUI</option>
                                        <option value="NON">NON</option>
                                    </select>
                                </div>
                            </div>

                            {(helpFields['compte_etude_fr_post_bac'] === 'NON') && showOtherSubmitBtn &&
                                <div className="row open-chat-btn-row">
                                    <div className="col-md-6 offset-md-3 open-chat-btn-col">
                                        {!openChatInProgress ?
                                            <button className="btn btn-primary oe-primary-btn open-chat-btn">
                                                Chattez avec un conseiller
                                            </button> :
                                            <button className="btn btn-primary oe-primary-btn open-chat-btn">
                                                Demande en cours&nbsp;
                                                <i className="fas fa-spin fa-spinner"></i>
                                            </button>
                                        }
                                    </div>
                                </div>
                            }
                        </>
                    }

                    {(isShowCompteNumeroInput && !isBacChoice && helpFields['domaine'] !== null && helpFields['isBacCompteCampusYesChoice'] === false ) &&
                        <>
                            <div className="col-md-6 o-form-col">
                                <div className="form-group o-form-group">
                                    <label htmlFor="numero_compte_etude_fr">Précisez votre numéro SN <span className="required-icon">*</span></label>
                                    <input placeholder="Renseignez votre numéro de compte SN" onChange={handleHelpChoice} name="numero_compte_etude_fr" className="form-control oe-form-control" id="numero_compte_etude_fr" />
                                </div>
                                {is_empty_numero_compte_etude &&
                                    <div className="alert alert-danger oe-alert-danger">
                                        {helpFieldsErrors.numero_compte_etude}
                                    </div>
                                }
                            </div>

                            {(helpFields['compte_etude_fr_post_bac'] === 'OUI') && showOtherSubmitBtn &&
                                <div className="row open-chat-btn-row">
                                    <div className="col-md-6 offset-md-3 open-chat-btn-col">
                                        {!openChatInProgress ?
                                            <button className="btn btn-primary oe-primary-btn open-chat-btn">
                                                Chattez avec un conseiller
                                            </button> :
                                            <button className="btn btn-primary oe-primary-btn open-chat-btn">
                                                Demande en cours&nbsp;
                                                <i className="fas fa-spin fa-spinner"></i>
                                            </button>
                                        }
                                    </div>
                                </div>
                            }

                            {cannotChat &&
                                <div className="row cannot-chat-row">
                                    <div className="col-md-12 cannot-chat-col">
                                        <div className="alert alert-danger oe-alert-danger">
                                            Oupss! Une erreur est survenue lors de votre demande pour discuter avec un conseiller. Veuillez réessayer plus tard.
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </form>
        )
    }

    const onReturnVisitorInfos = (infos) => {
        return(
            infos['isBacChoice'] === true ?
                `Prénom et Nom : ${infos['visitor_fullname'] ? infos['visitor_fullname'] + ', ' : ''} Téléphone : ${infos['visitor_phone'] ? infos['visitor_phone'] + ',' : ''} Email : ${infos['visitor_email'] ? infos['visitor_email'] + ',' : ''} Série BAC : ${infos['bac_serie'] ? infos['bac_serie'] + ',' : ''} Besoins : ${infos['besoins'] ? infos['besoins'] + ',' : ''} Compte Étude : ${infos['compte_etude_fr'] ? infos['compte_etude_fr'] + ',' : ''} Numéro Compte Étude : ${infos['numero_compte_etude_fr'] ? infos['numero_compte_etude_fr'] + ',' : ''} Lien Rapport auto-évaluation : ${(infos['has_doing_auto_evaluation'] === '1' || infos['has_doing_auto_evaluation'] === 1 || infos['has_doing_auto_evaluation'] === true) && (FrontendBaseUrl + 'rapport-auto-evaluation-vu-par-campus-france-sn/' + infos['numero_dossier_auto_evaluation'])}`
                : 
                `Prénom et Nom : ${infos['visitor_fullname'] ? infos['visitor_fullname'] + ',' : ''} Téléphone : ${infos['visitor_phone'] ? infos['visitor_phone'] + ',' : ''} Email : ${infos['visitor_email'] ? infos['visitor_email'] + ',' : ''} Dernier Diplôme : ${infos['last_degree'] ? infos['last_degree'] + ',' : ''} Domaine : ${infos['domaine'] ? infos['domaine'] + ',' : ''} Besoins : ${infos['besoins'] ? infos['besoins'] + ',' : ''} Compte Étude : ${infos['compte_etude_fr_post_bac'] ? infos['compte_etude_fr_post_bac'] + ',' : ''} Numéro Compte Étude : ${infos['numero_compte_etude_fr'] ? infos['numero_compte_etude_fr'] + ',' : ''} Lien Rapport auto-évaluation : ${(infos['has_doing_auto_evaluation'] === '1' || infos['has_doing_auto_evaluation'] === 1 || infos['has_doing_auto_evaluation'] === true) && (FrontendBaseUrl + 'rapport-auto-evaluation-vu-par-campus-france-sn/' + infos['numero_dossier_auto_evaluation'])}`
        )
    }

    const onCloseModals = () => {
        props?.closeSubModal()
        props?.closeModal()
    }

    const onGetStandTalker = (appID, data) => {
    
        axios.get(ApiBaseUrl + 'campus-talkers', requestConfig)
            .then(response => {
                if (response.data.success) {

                    const mine = { 
                        id: 'oe-campus-france-senegal-' + data.visitor_email,
                        name: data.visitor_fullname,
                        email: data.email,
                        role: "messager",
                        photoUrl: 'https://ui-avatars.com/api/?name=Visitor+User&background=random',
                        // signature: currentUser.signature
                    }
                
        
                    Talk.ready.then(() => {
                        const me = new Talk.User(mine);
            
                        if (!window.talkSession) {
                            window.talkSession = new Talk.Session({
                                appId: appID,
                                me: me,
                                locale: "fr-FR"
                            });
                        }
        
                    const conversation = window.talkSession.getOrCreateConversation(data.visitor_email + 'monespacecampusfrancesn');
                    conversation.setParticipant(me);
                    
                
                    /* Set participants of the conversations */
                    if(response.data.count !== 0){
                        response.data.dataTalk.forEach(item => {
                            conversation.setParticipant(new Talk.User(item));
                        })
                    }

                    conversation.setAttributes({
                        photoUrl: 'https://ui-avatars.com/api/?name=Campus+France&background=random',
                        subject: data.visitor_fullname + ' ( ' + data.visitor_email + ' ) ',
                        welcomeMessages: [onReturnVisitorInfos(data)]
                    })

                    let inbox = window.talkSession.createPopup(conversation,  {
                        // Don't use the default header provided by TalkJS
                        showChatHeader: true,
                        showCloseInHeader: true,
                        chatTitleMode: "Subject"
                    });
                
                    if(inbox.mount()){
                        setOpenChatInProgress(false);
                        onCloseModals();
                    }else{
                        setOpenChatInProgress(false);
                        setCannotChat(true)
                    }

                    inbox.setHighlightedWords()

                    var unreadCount = 0;
                    window.talkSession.unreads.on("change", function (conversationIds) {
                        unreadCount = conversationIds.length;
                        if (unreadCount > 0) {
                            document.title = "(" + unreadCount + " message(s) non lus) | Mon Espace Campus France SN";
                        } else {
                            document.title = "Mon Espace Campus France SN";
                        }
                        document.getElementById("__talkjs_launcher").innerHTML = '<div class="unread-counting">' + unreadCount + '</div>';
                        setUnreadCount(unreadCount)
                        console.log("Conversation ID's", conversationIds);
                    });
                    })
                    .catch(e => console.error(e));

                } else {
                console.log(response)
                }
            })
            .catch(error => {
                setOpenChatInProgress(false);
                console.log(error);
            })
    }

    const onSubmitOpenChatModal = (e) => {
        e.preventDefault()
        if(onValidateOpenChatForm()){
            setOpenChatInProgress(true);

            axios.post(`${ApiBaseUrl}need_help_visitors`, helpFields)
                .then(response => {
                    if(response.data.success && response.data.is_active_chat){
                        setisDisabledChat(false);

                        onGetStandTalker(talkjsID, helpFields);

                    }else if(response.data.success && !response.data.is_active_chat){
                        console.log(response)
                        toast.error('Nous sommes désolé mais le service CHAT est actuellement désactivé. Veuillez réessayer plus tard.', {
                            duration: 5000,
                            position: 'top-center'
                        });
                        
                        setOpenChatInProgress(false);
                        setisDisabledChat(true);

                        setTimeout(() => {
                            setisDisabledChat(true);
                        }, 5000);
                    }
                    else{
                        console.log(response)
                        toast.error('Oupsss! Une erreur est survenue lors de votre requête. Veuillez réessayer plus tard.', {
                            duration: 5000,
                            position: 'top-center'
                        });
                        setState({
                            openChatInProgress: false,
                            isErrorTalkTo: true
                        })
                        setOpenChatInProgress(false);
                        setIsErrorTalkTo(true);
                        setTimeout(() => {
                            setIsErrorTalkTo(false);
                        }, 5000);
                    }
                })
                .catch(error => {
                    console.log(error)
                    toast.error('Oupsss! Une erreur est survenue lors de votre requête. Veuillez réessayer plus tard.', {
                        duration: 5000,
                        position: 'top-center'
                    });
                    setOpenChatInProgress(false);
                    setIsErrorTalkTo(true);
                    setTimeout(() => {
                        setIsErrorTalkTo(false);
                    }, 5000);
                })
        }
    }

    // Gestion des RDV
    const onGetOrientations = () => {
        axios.get(`${ApiBaseUrl}coming-orientations`)
            .then(response => {
                if(response.data.success && !response.data.empty){
                    setComingOrientations(response.data.data);
                    setCountComingOrientations(response.data.count);
                }
                if(response.data.success && response.data.empty){
                    setIsEmptyComingOrientation(true);
                }
            })
    }

    useEffect(() => {
        if(comingOrientations){
            let availables = [];
            comingOrientations?.map((event) => {
                event?.dates?.map((date) => {
                    const dt = new Date(date?.date);
                    availables.push(dt)
                })
            })
            setAvailableDates(availables)
        }
        if(comingSeanceDeRemplissages){
            let r_availables = [];
            comingSeanceDeRemplissages?.map((event) => {
                event?.dates?.map((date) => {
                    const dt = new Date(date?.date);
                    r_availables.push(dt)
                })
            })
            setRemplissageAvailableDates(r_availables)
        }
        if(comingInformations){
            let i_availables = [];
            comingInformations?.map((event) => {
                event?.dates?.map((date) => {
                    const dt = new Date(date?.date);
                    i_availables.push(dt)
                })
            })
            setInformationAvailableDates(i_availables)
        }
        if(comingSoonComplementDossier){
            let c_availables = [];
            comingSoonComplementDossier?.map((event) => {
                event?.dates?.map((date) => {
                    const dt = new Date(date?.date);
                    c_availables.push(dt)
                })
            })
            setComplementDossierAvailableDates(c_availables)
        }
        if(comingPreparationDepart){
            let p_availables = [];
            comingPreparationDepart?.map((event) => {
                event?.dates?.map((date) => {
                    const dt = new Date(date?.date);
                    p_availables.push(dt)
                })
            })
            setPreparationDepartAvailableDates(p_availables)
        }

    }, [comingOrientations, comingSeanceDeRemplissages, comingInformations, comingSoonComplementDossier, comingPreparationDepart]);

    function isSameDay(a, b) {
        return differenceInCalendarDays(a, b) === 0;
    }

    function tileClassName({ date, view }) {
        // Add class to tiles in month view only
        if (view === 'month') {
          // Check if a date React-Calendar wants to check is on the list of dates to add class to
          if (availableDates.find(dDate => isSameDay(dDate, date))) {
            return 'available-date';
          }
        }
    }

    function RemplissageTileClassName({ date, view }) {
        if (view === 'month') {
          if (remplissageAvailableDates.find(dDate => isSameDay(dDate, date))) {
            return 'available-date';
          }
        }
    }

    function InformationTileClassName({ date, view }) {
        if (view === 'month') {
          if (informationAvailableDates.find(dDate => isSameDay(dDate, date))) {
            return 'available-date';
          }
        }
    }

    function ComplementDossierTileClassName({ date, view }) {
        if (view === 'month') {
          if (complementDossierAvailableDates.find(dDate => isSameDay(dDate, date))) {
            return 'available-date';
          }
        }
    }

    function PreparationDepartTileClassName({ date, view }) {
        if (view === 'month') {
          if (preparationDepartAvailableDates.find(dDate => isSameDay(dDate, date))) {
            return 'available-date';
          }
        }
    }

    const onGetSeanceDeRemplissage = () => {
        axios.get(`${ApiBaseUrl}coming-seances-remplissages`)
            .then(response => {
                if(response.data.success && !response.data.empty){
                    setComingSeanceDeRemplissages(response.data.data);
                    setCountComingSeanceDeRemplissages(response.data.count);
                }
                if(response.data.success && response.data.empty){
                    setIsEmptyComingSeanceDeRemplissages(true);
                }
            })
    }

    const onGetInformations = () => {
        axios.get(`${ApiBaseUrl}coming-sessions-information`)
            .then(response => {
                if(response.data.success && !response.data.empty){
                    setComingInformations(response.data.data);
                    setCountComingInformations(response.data.count);
                    setIsEmptyComingInformations(false);
                    console.log(response.data.count)
                }
                if(response.data.success && response.data.empty){
                    setIsEmptyComingInformations(true);
                }
            })
    }

    const onGetSeanceComplementDossier = () => {
        axios.get(`${ApiBaseUrl}coming-complement-dossier`)
            .then(response => {
                if(response.data.success && !response.data.empty){
                    console.log(response.data.data)
                    setComingSoonComplementDossier(response.data.data);
                    setCountComingSoonComplementDossier(response.data.count);
                }
                if(response.data.success && response.data.empty){
                    setIsEmptyComingSoonComplementDossier(true);
                }
            })
    }
    
    const onGetSeancePreparationDepart = () => {
        axios.get(`${ApiBaseUrl}coming-preparation-depart-france`)
            .then(response => {
                console.log(response)
                if(response.data.success && !response.data.empty){
                    setComingPreparationDepart(response.data.data);
                    setCountComingPreparationDepart(response.data.count);
                }
                if(response.data.success && response.data.empty){
                    setIsEmptyComingPreparationDepart(true);
                }
            })
    }

    const dateCompare = (date, time) => {
        var dt = date + " " + time
        const datetime = new Date(dt)
        const date2 = new Date();
    
        if(datetime > date2){
            // console.log(`${datetime} is greater than today`)
            return 'is greater than today'
        } else if(datetime < date2){
            // console.log(`today is greater than ${datetime}`)
            return 'today is greater than'
        } else{
            // console.log(`Both dates are equal`)
            return 'are equal'
        }
    }

    const onGetEventInfos = (event) => {
        setEventInfos(event);
    }

    const onGetSubscribeFormModal = (eventType, item) => {
        onGetEventInfos(item);
        console.log(item)
        props?.onOpenSubscribeEventModal(eventType);
    }

    const checkoutEventHandleChange = (e) => {
        let fields = checkoutFields
        fields['event_id'] = eventInfos?.eventInfos?.id
        // fields["event_checkout_region"] = eventInfos?.eventInfos?.ville?.id
        fields[e.target.name] = e.target.value

        if(e.target.name === 'event_checkout_email'){
            fields[e.target.name] = e.target.value.toLowerCase()
        }
        if(e.target.name === 'event_checkout_compte_etudes_fr' && e.target.value === '1'){
            setCheckoutHasCompteEtudesFR(true);
        }
        if(e.target.name === 'event_checkout_compte_etudes_fr' && e.target.value === '0'){
            setCheckoutHasCompteEtudesFR(false);
        }
        if(e.target.name === 'has_doing_auto_evaluation' && e.target.value === '1'){
            setShowNumeroDossierInput(true)
        }
        if(e.target.name === 'has_doing_auto_evaluation' && e.target.value === '0'){
            setShowNumeroDossierInput(false)
        }

        setCheckoutFields(fields);
        console.log(fields)
    }
    
    const onValidateCheckoutEventForm = () => {
        let errors = checkoutErrors
        var isValidForm = true

        // if(!checkoutFields['checkout_date_id']){
        //     isValidForm = false
        //     set_is_empty_checkout_date_id(true);

        //     setTimeout(() => {
        //         set_is_empty_checkout_date_id(false);
        //     }, 5000);

        //     errors['checkout_date_id'] = 'Vous devez choisir une date'
            
        // }
        if(!checkoutFields['event_checkout_fullname']){
            isValidForm = false
            set_is_empty_event_checkout_fullname(true);

            setTimeout(() => {
                set_is_empty_event_checkout_fullname(false);
            }, 5000);

            errors['event_checkout_fullname'] = 'Ce champ est obligatoire'
        }
        if(!checkoutFields['event_checkout_email']){
            isValidForm = false
            set_is_empty_event_checkout_email(true);

            setTimeout(() => {
                set_is_empty_event_checkout_email(false);
            }, 5000);

            errors['event_checkout_email'] = 'Ce champ est obligatoire'
        }
        if (typeof checkoutFields["event_checkout_email"] !== "undefined") {
            var pattern = new RegExp(
              /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(checkoutFields["event_checkout_email"])) {
              isValidForm = false;
              set_is_invalid_event_checkout_email(true);
              setTimeout(() => {
                set_is_invalid_event_checkout_email(false);
              }, 5000);
      
              errors["invalid_event_checkout_email"] = "Votre adresse email n'est pas valide";
            }
        }
        if(!checkoutFields['event_checkout_phone']){
            isValidForm = false
            set_is_empty_event_checkout_phone(true);

            setTimeout(() => {
                set_is_empty_event_checkout_phone(false);
            }, 5000);

            errors['event_checkout_phone'] = 'Ce champ est obligatoire'
        }
        if(!checkoutFields['event_checkout_address']){
            isValidForm = false
            set_is_empty_event_checkout_address(true);

            setTimeout(() => {
                set_is_empty_event_checkout_address(false);
            }, 5000);

            errors['event_checkout_address'] = 'Ce champ est obligatoire'
        }
        if(!checkoutFields['event_checkout_region'] || checkoutFields['event_checkout_region'] === '--Choisir une ville--'){
            isValidForm = false
            set_is_empty_event_checkout_region(true);

            setTimeout(() => {
                set_is_empty_event_checkout_region(false);
            }, 5000);

            errors['event_checkout_region'] = 'Ce champ est obligatoire'
        }
        if(checkoutFields["event_checkout_region"]) {
            if(parseInt(checkoutFields["event_checkout_region"]) !== eventInfos?.eventInfos?.ville?.id){
                isValidForm = false
                set_is_different_event_checkout_region(true);

                setTimeout(() => {
                    set_is_different_event_checkout_region(false);
                }, 5000);

                errors['different_event_checkout_region'] = 'Votre ville de résidence ne correspond à la ville associée à l\'événement.'
            }
        }
        if(!checkoutFields['event_checkout_compte_etudes_fr'] || checkoutFields['event_checkout_compte_etudes_fr'] === '--Choisir une option--'){
            isValidForm = false
            set_is_empty_event_checkout_compte_etudes_fr(true);

            setTimeout(() => {
                set_is_empty_event_checkout_compte_etudes_fr(false);
            }, 5000);

            errors['event_checkout_compte_etudes_fr'] = 'Ce champ est obligatoire'
        }
        if(typeof checkoutFields['event_checkout_compte_etudes_fr'] !== undefined && checkoutFields['event_checkout_compte_etudes_fr'] === 1){
            if(!checkoutFields['event_checkout_compte_etudes_fr_number']){
                isValidForm = false
                set_is_empty_event_checkout_compte_etudes_fr_number(true);

                setTimeout(() => {
                    set_is_empty_event_checkout_compte_etudes_fr_number(false);
                }, 5000);

                errors['event_checkout_compte_etudes_fr_number'] = 'Ce champ est obligatoire'
            }
        }

        if(!checkoutFields['has_doing_auto_evaluation']){
            isValidForm = false
            set_is_empty_has_doing_auto_evaluation(true)
            setTimeout(() => {
                set_is_empty_has_doing_auto_evaluation(false);
            }, 5000);
            errors['has_doing_auto_evaluation'] = 'Ce champ est obligatoire'
        }
        if(showNumeroDossierInput){
            if(!checkoutFields['numero_dossier_auto_evaluation']){
                isValidForm = false
                set_is_empty_numero_dossier_auto_evaluation(true)
                setTimeout(() => {
                    set_is_empty_numero_dossier_auto_evaluation(false);
                }, 5000);
                errors['numero_dossier_auto_evaluation'] = 'Ce champ est obligatoire'
            }
        }

        setCheckoutErrors(errors)

        return isValidForm
    }

    const onSubmitEventCheckout = (e) => {
        e.preventDefault()

        if(onValidateCheckoutEventForm()){
            setEventCheckoutInProgress(true);
            checkoutFields['checkout_date_id'] = eventInfos?.id;

            axios.post(`${ApiBaseUrl}event_checkouts`, checkoutFields)
                .then(response => {
                    setEventCheckoutInProgress(false);

                    if(response.data.success && !response.data.already_subscribe && !response.data.limit_reached && !response.data.cancelled_event){
                        onGetOrientations();
                        onGetSeanceDeRemplissage();
                        onGetInformations();
                        onGetSeanceComplementDossier();
                        onGetSeancePreparationDepart();
                        toast.success('Votre inscription a été bien prise en compte.', {
                            duration: 5000,
                            position: 'top-center'
                        });
                        $('#event_checkout_fullname').val('')
                        $('#event_checkout_email').val('')
                        $('#event_checkout_phone').val('')
                        $('#event_checkout_address').val('')
                        $('#event_checkout_region').val('')
                        $('#event_checkout_compte_etudes_fr').val('')
                        $('#event_checkout_compte_etudes_fr_number').val('')
                        setValue('');
                        setEventInfos([])
                        // hasNoRequest && !checkEventInProgress
                        setHasNoRequest(true);
                        setCheckEventInProgress(false);
                        
                        props?.setIsOpenSubscribeEventModal(false);

                        setCheckoutFields({});
                        setCheckoutErrors({});

                    }else if(response.data.success && response.data.already_subscribe && !response.data.limit_reached && !response.data.cancelled_event){
                        toast.error('Désolé! vous ne pouvez pas vous réinscrire puis que vous êtes déjà inscrit à cette séance.', {
                            duration: 5000,
                            position: 'top-center'
                        });
                        $('#event_checkout_fullname').val('')
                        $('#event_checkout_email').val('')
                        $('#event_checkout_phone').val('')
                        $('#event_checkout_address').val('')
                        $('#event_checkout_region').val('')
                        $('#event_checkout_compte_etudes_fr').val('')
                        $('#event_checkout_compte_etudes_fr_number').val('')
                    }else if(response.data.success && !response.data.already_subscribe && response.data.limit_reached && !response.data.cancelled_event){

                        toast.error('Oupsss! Désolé, votre inscription ne peut pas être prise en compte. Nous avons atteint le nombre de participants pour cette séance.', {
                            duration: 5000,
                            position: 'top-center'
                        });
                        $('#event_checkout_fullname').val('')
                        $('#event_checkout_email').val('')
                        $('#event_checkout_phone').val('')
                        $('#event_checkout_address').val('')
                        $('#event_checkout_region').val('')
                        $('#event_checkout_compte_etudes_fr').val('')
                        $('#event_checkout_compte_etudes_fr_number').val('')
                    }else if(response.data.success && !response.data.already_subscribe && !response.data.limit_reached && response.data.cancelled_event){

                        toast.error('Oupsss! Désolé, vous ne pouvez pas vous inscrire car cette séance a été annulé.', {
                            duration: 5000,
                            position: 'top-center'
                        });
                        $('#event_checkout_fullname').val('')
                        $('#event_checkout_email').val('')
                        $('#event_checkout_phone').val('')
                        $('#event_checkout_address').val('')
                        $('#event_checkout_region').val('')
                        $('#event_checkout_compte_etudes_fr').val('')
                        $('#event_checkout_compte_etudes_fr_number').val('')
                    }else{

                        toast.error('Oupsss! Une erreur est survenue lors de votre inscription. Veuillez réessayer plus tard.', {
                            duration: 5000,
                            position: 'top-center'
                        });
                        console.log(response)
                    }
                })
                .catch(error => {
                    toast.error('Oupsss! Une erreur est survenue lors de votre inscription. Veuillez réessayer plus tard.', {
                        duration: 5000,
                        position: 'top-center'
                    });
                    console.log(error)
                    setEventCheckoutInProgress(false);
                })
        }
    }


    useEffect(() => {
        Modal.setAppElement('body');
        onGetChatStatus();
        onGetOrientations();
        onGetSeanceDeRemplissage();
        onGetInformations();
        onGetSeanceComplementDossier();
        onGetSeancePreparationDepart();
        onGetVilles();
    }, []);

    const onClickDay = (value, event) => {
        setHasNoRequest(false);
        setCheckEventInProgress(true);
        console.log('value', moment(value).format("YYYY-MM-DD"));
        console.log('event', event);
        // check-date-events
        var choosedDate = moment(value).format("YYYY-MM-DD");
        var eventType = window.sessionStorage.getItem('eventType');

        axios.post(ApiBaseUrl + 'check-date-events/' + eventType, {
            date: choosedDate
        }).then(response => {
            console.log(response)
            if(response.data.success && !response.data.empty){
                setChoosedDateEvent(response.data.dates);
            }
            if(!response.data.success && response.data.empty){
                console.log('empty', response)
            }
            setCheckEventInProgress(false);
        })
        
    }

    const onMeetWithConseiller = (fromEventType) => {
        if(fromEventType === 'orientation_collective'){
            props?.onCloseOrientationCollectiveModal();
        }
        if(fromEventType === 'remplissage'){
            props?.onCloseRemplissageModal()
        }
        if(fromEventType === 'information'){
            props?.onCloseInformationModal()
        }
        if(fromEventType === 'complement_dossier'){
            props?.onCloseComplementDossierModal()
        }
        if(fromEventType === 'preparation_sejour'){
            props?.onClosePreparationSejourModal()
        }
        props?.openSubModal();
    }

    return (
        <>
            <Modal
                isOpen={props?.modalIsOpen}
                className="Modal"
                overlayClassName="Overlay"
                style={customStyles}
            >
                <div className="v2-modal-content-container">
                    <div className="v2-modal-content">
                        <div className="v2-modal-header">
                            <div className="v2-modal-header-content">
                                <div className="v2-modal-return-back-or-close-btn-container">
                                    <button className="btn btn-danger v2-return-back-btn" onClick={() => props?.closeModal()}>
                                        <span className="return-back-group">
                                            <span className="return-back-icon-container">
                                                <img src={ReturnBack} alt="Return back" className="return-back-icon" />
                                            </span>
                                            {
                                                window.innerWidth > 480 && <span className="return-back-label">Retour à la liste</span>
                                            }
                                            
                                        </span>
                                    </button>
                                </div>
                                <div className="v2-modal-header-title-container">
                                    <div className="modal-header-title-group">
                                        <div className="modal-header-title-icon-container">
                                            <img src={OrientationIcon} alt="Return back" className="modal-header-title-icon" />
                                        </div>
                                        <div className="modal-header-title-label">Je m'oriente</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="v2-modal-body">
                            <div className="v2-modal-body-content-container">
                                <div className="v2-modal-body-row row">
                                    <div className="v2-modal-body-col col-md-4 reding">
                                        <div className="v2-modal-body-item-container">
                                            <div className="v2-modal-body-item">
                                                <button onClick={props?.openSubModal} className="btn btn-danger v2-modal-body-item-btn">
                                                    <span className="sub-service-item-label">Echanger avec un conseiller / Rendez-vous d’orientation</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="v2-modal-body-col col-md-4 blueing">
                                        <div className="v2-modal-body-item-container">
                                            <div className="v2-modal-body-item">
                                                <button onClick={props?.onOpenOrientationCollectiveModal} 
                                                    className="btn btn-danger v2-modal-body-item-btn"
                                                >
                                                    <span className="sub-service-item-label">Participer à une session collective d’orientation</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="v2-modal-body-col col-md-4 reding">
                                        <div className="v2-modal-body-item-container">
                                            <div className="v2-modal-body-item">
                                                <button onClick={props?.onOpenRemplissageModal} 
                                                    className="btn btn-danger v2-modal-body-item-btn"
                                                >
                                                    <span className="sub-service-item-label">Participer à un atelier d’aide au remplissage du dossier électronique</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="v2-modal-body-col col-md-4 blueing">
                                        <div className="v2-modal-body-item-container">
                                            <div className="v2-modal-body-item">
                                                <button onClick={props?.onOpenInformationModal} 
                                                    className="btn btn-danger v2-modal-body-item-btn"
                                                >
                                                    <span className="sub-service-item-label">Rendez-vous individuel d’information</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="v2-modal-body-col col-md-4 reding">
                                        <div className="v2-modal-body-item-container">
                                            <div className="v2-modal-body-item">
                                                <button onClick={props?.onOpenComplementDossierModal} 
                                                    className="btn btn-danger v2-modal-body-item-btn"
                                                >
                                                    <span className="sub-service-item-label">Rendez-vous de complément de dossier</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="v2-modal-body-col col-md-4 blueing">
                                        <div className="v2-modal-body-item-container">
                                            <div className="v2-modal-body-item">
                                                <button onClick={props?.onOpenPreparationSejourModal} className="btn btn-danger v2-modal-body-item-btn">
                                                    <span className="sub-service-item-label">Séance de préparation au séjour en France</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </Modal>
            
            
            <Modal
                    isOpen={props?.subModalIsOpen}
                    className="Modal"
                    overlayClassName="Overlay"
                    style={customStyles}
                >
                    <div className="v2-modal-content-container">
                        <div className="v2-modal-content">
                            <div className="v2-modal-header">
                                <div className="v2-modal-header-content">
                                    <div className="v2-modal-return-back-or-close-btn-container">
                                        <button className="btn btn-danger v2-return-back-btn" onClick={() => props?.closeSubModal()}>
                                            <span className="return-back-group">
                                                <span className="return-back-icon-container">
                                                    <img src={ReturnBack} alt="Return back" className="return-back-icon" />
                                                </span>
                                                {
                                                    window.innerWidth > 480 && <span className="return-back-label">Retour à la liste</span>
                                                }
                                            </span>
                                        </button>
                                    </div>
                                    <div className="v2-modal-header-title-container">
                                        <div className="modal-header-title-group">
                                            <div className="modal-header-title-icon-container">
                                                <img src={OrientationIcon} alt="Return back" className="modal-header-title-icon" />
                                            </div>
                                            <div className="modal-header-title-label">Je m'oriente</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="v2-modal-body">
                                <div className="v2-modal-body-content-container">
                                    <div className="v2-modal-body-row row">
                                        {
                                            !isActiveChat ?
                                            <div className="v2-modal-body-col col-md-12 v2-modal-empty-badge-col">
                                                <div className="v2-modal-empty-badge-body">
                                                    <div className="v2-modal-empty-badge-icon-container">
                                                        <img src={WarningIcon} alt="Empty" className="v2-modal-empty-badge-icon" />
                                                    </div>
                                                    <div className="v2-modal-empty-badge-message-container">
                                                        <span>Désolé! Le service AIDE EN LIGNE est actuellement fermé.</span>
                                                    </div>
                                                </div>
                                            </div> :

                                            onRenderHelpSectionForm()
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </Modal>

            {/* Orientation Modal */}
            <Modal
                    isOpen={props?.isOpenOrientationCollectiveModal}
                    className="Modal"
                    overlayClassName="Overlay"
                    style={customStyles}
                >
                    <div className="v2-modal-content-container">
                        <div className="v2-modal-content">
                            <div className="v2-modal-header">
                                <div className="v2-modal-header-content">
                                    <div className="v2-modal-return-back-or-close-btn-container">
                                        <button className="btn btn-danger v2-return-back-btn" onClick={() => {
                                            setHasNoRequest(true);
                                            setValue('')
                                            props?.onCloseOrientationCollectiveModal()}
                                        }>
                                            <span className="return-back-group">
                                                <span className="return-back-icon-container">
                                                    <img src={ReturnBack} alt="Return back" className="return-back-icon" />
                                                </span>
                                                {
                                                    window.innerWidth > 480 && <span className="return-back-label">Retour à la liste</span>
                                                }
                                            </span>
                                        </button>
                                    </div>
                                    <div className="v2-modal-header-title-container">
                                        <div className="modal-header-title-group">
                                            <div className="modal-header-title-icon-container">
                                                <img src={OrientationIcon} alt="Return back" className="modal-header-title-icon" />
                                            </div>
                                            <div className="modal-header-title-label">Session Collective d'orientation</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="v2-modal-body">
                                <div className="v2-modal-body-content-container">
                                    <div className="v2-modal-body-row row checking-meeting-row">
                                        <div className="col-md-5 calendar-md-col">
                                            <div className="calendar-body-content">
                                                <Calendar 
                                                    value={value}
                                                    onClickDay={onClickDay}
                                                    onChange={onChangeDate}
                                                    tileClassName={tileClassName}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-7 calendar-select-result-md-col">
                                            <div className="calendar-body-select-result-content">
                                                {(hasNoRequest && !checkEventInProgress) ? (
                                                        <div className="row empty-alert-row">
                                                            <div className="col-md-2 empty-icon-col">
                                                                <FeatherIcon
                                                                    icon="alert-circle"
                                                                    className="m-b-3 empty-alert-icon rotateIn"
                                                                />
                                                            </div>
                                                            <div className="col-md-10 empty-textual-col">
                                                                <p><strong>Vous devez choisir une date.</strong></p>
                                                            </div>
                                                        </div>
                                                    ) : (!hasNoRequest && checkEventInProgress) ? (
                                                        <div className="loadering">
                                                            <span class="checkRDVloader"></span>
                                                        </div>
                                                    ) : (!hasNoRequest && !checkEventInProgress) && (
                                                        choosedDateEvent?.length === 0 ? (
                                                            <div className="empty-rdv-textual-container empty-rdv-alert-container">
                                                                <span>Aucun rendez vous disponible à cette date.</span>
                                                                <button onClick={() => onMeetWithConseiller('orientation_collective')} className="btn meet-conseiller-btn">Échanger avec un conseiller</button>
                                                            </div>
                                                        ) : (
                                                            <div className="no-empty-rdv-infos-container">
                                                                <h6 className="no-empty-rdv-label">Des rendez-vous sont disponibles pour le { moment(value).format("DD/MM/YYYY") } :</h6>
                                                                <ul className="no-empty-rdv-ul">
                                                                    {choosedDateEvent?.map((item, index) => 
                                                                        (((item?.eventInfos?.event_participant_count - item?.participants) <= 0) ) ? (
                                                                                <li className="disable-item" 
                                                                                    key={'no-empty-event' + index}
                                                                                > 
                                                                                    De {item?.startTime} à {item?.endTime} à <span className="event-location-label">{ item?.eventInfos?.ville?.name }</span>&nbsp;
                                                                                    <span className="event-particant-count"> 
                                                                                        {'0 place restante'} 
                                                                                    </span> 
                                                                                </li>
                                                                            ) : (((item?.eventInfos?.event_participant_count - item?.participants) > 0) && dateCompare(moment(item?.date).format('MM/DD/YYYY'), item?.eventInfos?.event_start_time) === 'is greater than today') ? (
                                                                                <li className="linkable" 
                                                                                    onClick={() => onGetSubscribeFormModal('orientation', item)} 
                                                                                    key={'no-empty-event' + index}
                                                                                > 
                                                                                    De {item?.startTime} à {item?.endTime} à <span className="event-location-label">{ item?.eventInfos?.ville?.name }</span>&nbsp;
                                                                                    <span className="event-particant-count"> 
                                                                                        { (item?.eventInfos?.event_participant_count - item?.participants) + ' place(s) restante(s)'} 
                                                                                    </span> 
                                                                                </li>
                                                                            ) : (
                                                                                <li className="disable-item" 
                                                                                    key={'no-empty-event' + index}
                                                                                > 
                                                                                    De {item?.startTime} à {item?.endTime} à <span className="event-location-label">{ item?.eventInfos?.ville?.name }</span>&nbsp;
                                                                                    <span className="event-particant-count"> 
                                                                                        {'0 place restante'} 
                                                                                    </span> 
                                                                                </li>
                                                                            ) 
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </Modal>

            {/* Remplissage */}
            <Modal
                    isOpen={props?.isOpenRemplissageModal}
                    className="Modal"
                    overlayClassName="Overlay"
                    style={customStyles}
                >
                    <div className="v2-modal-content-container">
                        <div className="v2-modal-content">
                            <div className="v2-modal-header">
                                <div className="v2-modal-header-content">
                                    <div className="v2-modal-return-back-or-close-btn-container">
                                        <button className="btn btn-danger v2-return-back-btn" onClick={() => {
                                            setValue('')
                                            setHasNoRequest(true); 
                                            props?.onCloseRemplissageModal()
                                        }}>
                                            <span className="return-back-group">
                                                <span className="return-back-icon-container">
                                                    <img src={ReturnBack} alt="Return back" className="return-back-icon" />
                                                </span>
                                                {
                                                    window.innerWidth > 480 && <span className="return-back-label">Retour à la liste</span>
                                                }
                                            </span>
                                        </button>
                                    </div>
                                    <div className="v2-modal-header-title-container">
                                        <div className="modal-header-title-group">
                                            <div className="modal-header-title-icon-container">
                                                <img src={OrientationIcon} alt="Return back" className="modal-header-title-icon" />
                                            </div>
                                            <div className="modal-header-title-label">Aide au remplissage du dossier électronique</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="v2-modal-body">
                                <div className="v2-modal-body-content-container">
                                    <div className="v2-modal-body-row row checking-meeting-row">
                                        <div className="col-md-5 calendar-md-col">
                                            <div className="calendar-body-content">
                                                <Calendar 
                                                    value={value}
                                                    onClickDay={onClickDay}
                                                    onChange={onChangeDate}
                                                    tileClassName={RemplissageTileClassName}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-7 calendar-select-result-md-col">
                                            <div className="calendar-body-select-result-content">
                                                {(hasNoRequest && !checkEventInProgress) ? (
                                                        <div className="row empty-alert-row">
                                                            <div className="col-md-2 empty-icon-col">
                                                                <FeatherIcon
                                                                    icon="alert-circle"
                                                                    className="m-b-3 empty-alert-icon rotateIn"
                                                                />
                                                            </div>
                                                            <div className="col-md-10 empty-textual-col">
                                                                <p><strong>Vous devez choisir une date.</strong></p>
                                                            </div>
                                                        </div>
                                                    ) : (!hasNoRequest && checkEventInProgress) ? (
                                                        <div className="loadering">
                                                            <span class="checkRDVloader"></span>
                                                        </div>
                                                    ) : (!hasNoRequest && !checkEventInProgress) && (
                                                        choosedDateEvent?.length === 0 ? (
                                                            <div className="empty-rdv-textual-container">
                                                                <span>Aucun rendez vous disponible à cette date.</span>
                                                                <button onClick={() => onMeetWithConseiller('remplissage')} className="btn meet-conseiller-btn">Échanger avec un conseiller</button>
                                                            </div>
                                                        ) : (
                                                            <div className="no-empty-rdv-infos-container">
                                                                <h6 className="no-empty-rdv-label">Des rendez-vous sont disponibles pour le { moment(value).format("DD/MM/YYYY") } :</h6>
                                                                <ul className="no-empty-rdv-ul">
                                                                    {choosedDateEvent?.map((item, index) => 
                                                                        (((item?.eventInfos?.event_participant_count - item?.participants) <= 0) ) ? (
                                                                                <li className="disable-item" 
                                                                                    key={'no-empty-event' + index}
                                                                                > 
                                                                                    De {item?.startTime} à {item?.endTime} à <span className="event-location-label">{ item?.eventInfos?.ville?.name }</span>&nbsp;
                                                                                    <span className="event-particant-count"> 
                                                                                        {'0 place restante'} 
                                                                                    </span> 
                                                                                </li>
                                                                            ) : (((item?.eventInfos?.event_participant_count - item?.participants) > 0) && dateCompare(moment(item?.date).format('MM/DD/YYYY'), item?.eventInfos?.event_start_time) === 'is greater than today') ? (
                                                                                <li className="linkable" 
                                                                                    onClick={() => onGetSubscribeFormModal('remplissage', item)} 
                                                                                    key={'no-empty-event' + index}
                                                                                > 
                                                                                    De {item?.startTime} à {item?.endTime} à <span className="event-location-label">{ item?.eventInfos?.ville?.name }</span>&nbsp;
                                                                                    <span className="event-particant-count"> 
                                                                                        { (item?.eventInfos?.event_participant_count - item?.participants) + ' place(s) restante(s)'} 
                                                                                    </span> 
                                                                                </li>
                                                                            ) : (
                                                                                <li className="disable-item" 
                                                                                    key={'no-empty-event' + index}
                                                                                > 
                                                                                    De {item?.startTime} à {item?.endTime} à <span className="event-location-label">{ item?.eventInfos?.ville?.name }</span>&nbsp;
                                                                                    <span className="event-particant-count"> 
                                                                                        {'0 place restante'} 
                                                                                    </span> 
                                                                                </li>
                                                                            ) 
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </Modal>

            {/* RDV individuel d'informations */}
            <Modal
                    isOpen={props?.isOpenInformationModal}
                    className="Modal"
                    overlayClassName="Overlay"
                    style={customStyles}
                >
                    <div className="v2-modal-content-container">
                        <div className="v2-modal-content">
                            <div className="v2-modal-header">
                                <div className="v2-modal-header-content">
                                    <div className="v2-modal-return-back-or-close-btn-container">
                                        <button className="btn btn-danger v2-return-back-btn" onClick={() => {
                                            setHasNoRequest(true);
                                            setValue('');
                                            props?.onCloseInformationModal()}
                                        }>
                                            <span className="return-back-group">
                                                <span className="return-back-icon-container">
                                                    <img src={ReturnBack} alt="Return back" className="return-back-icon" />
                                                </span>
                                                {
                                                    window.innerWidth > 480 && <span className="return-back-label">Retour à la liste</span>
                                                }
                                            </span>
                                        </button>
                                    </div>
                                    <div className="v2-modal-header-title-container">
                                        <div className="modal-header-title-group">
                                            <div className="modal-header-title-icon-container">
                                                <img src={OrientationIcon} alt="Return back" className="modal-header-title-icon" />
                                            </div>
                                            <div className="modal-header-title-label">RDV individuel d'informations</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="v2-modal-body">
                                <div className="v2-modal-body-content-container">
                                    <div className="v2-modal-body-row row checking-meeting-row">
                                        <div className="col-md-5 calendar-md-col">
                                            <div className="calendar-body-content">
                                                <Calendar 
                                                    value={value}
                                                    onClickDay={onClickDay}
                                                    onChange={onChangeDate}
                                                    tileClassName={InformationTileClassName}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-7 calendar-select-result-md-col">
                                            <div className="calendar-body-select-result-content">
                                                {(hasNoRequest && !checkEventInProgress) ? (
                                                        <div className="row empty-alert-row">
                                                            <div className="col-md-2 empty-icon-col">
                                                                <FeatherIcon
                                                                    icon="alert-circle"
                                                                    className="m-b-3 empty-alert-icon rotateIn"
                                                                />
                                                            </div>
                                                            <div className="col-md-10 empty-textual-col">
                                                                <p><strong>Vous devez choisir une date.</strong></p>
                                                            </div>
                                                        </div>
                                                    ) : (!hasNoRequest && checkEventInProgress) ? (
                                                        <div className="loadering">
                                                            <span class="checkRDVloader"></span>
                                                        </div>
                                                    ) : (!hasNoRequest && !checkEventInProgress) && (
                                                        choosedDateEvent?.length === 0 ? (
                                                            <div className="empty-rdv-textual-container">
                                                                <span>Aucun rendez vous disponible à cette date.</span>
                                                                <button onClick={() => onMeetWithConseiller('information')} className="btn meet-conseiller-btn">Échanger avec un conseiller</button>
                                                            </div>
                                                        ) : (
                                                            <div className="no-empty-rdv-infos-container">
                                                                <h6 className="no-empty-rdv-label">Des rendez-vous sont disponibles pour le { moment(value).format("DD/MM/YYYY") } :</h6>
                                                                <ul className="no-empty-rdv-ul">
                                                                    {choosedDateEvent?.map((item, index) => 
                                                                        (((item?.eventInfos?.event_participant_count - item?.participants) <= 0) ) ? (
                                                                                <li className="disable-item" 
                                                                                    key={'no-empty-event' + index}
                                                                                > 
                                                                                    De {item?.startTime} à {item?.endTime} à <span className="event-location-label">{ item?.eventInfos?.ville?.name }</span>&nbsp;
                                                                                    <span className="event-particant-count"> 
                                                                                        {'0 place restante'} 
                                                                                    </span> 
                                                                                </li>
                                                                            ) : (((item?.eventInfos?.event_participant_count - item?.participants) > 0) && dateCompare(moment(item?.date).format('MM/DD/YYYY'), item?.eventInfos?.event_start_time) === 'is greater than today') ? (
                                                                                <li className="linkable" 
                                                                                    onClick={() => onGetSubscribeFormModal('information', item)} 
                                                                                    key={'no-empty-event' + index}
                                                                                > 
                                                                                    De {item?.startTime} à {item?.endTime} à <span className="event-location-label">{ item?.eventInfos?.ville?.name }</span>&nbsp;
                                                                                    <span className="event-particant-count"> 
                                                                                        { (item?.eventInfos?.event_participant_count - item?.participants) + ' place(s) restante(s)'} 
                                                                                    </span> 
                                                                                </li>
                                                                            ) : (
                                                                                <li className="disable-item" 
                                                                                    key={'no-empty-event' + index}
                                                                                > 
                                                                                    De {item?.startTime} à {item?.endTime} à <span className="event-location-label">{ item?.eventInfos?.ville?.name }</span>&nbsp;
                                                                                    <span className="event-particant-count"> 
                                                                                        {'0 place restante'} 
                                                                                    </span> 
                                                                                </li>
                                                                            ) 
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </Modal>

            {/* Complément dossier */}
            <Modal
                    isOpen={props?.isOpenComplementDossierModal}
                    className="Modal"
                    overlayClassName="Overlay"
                    style={customStyles}
                >
                    <div className="v2-modal-content-container">
                        <div className="v2-modal-content">
                            <div className="v2-modal-header">
                                <div className="v2-modal-header-content">
                                    <div className="v2-modal-return-back-or-close-btn-container">
                                        <button className="btn btn-danger v2-return-back-btn" onClick={() => {
                                            setHasNoRequest(true);
                                            setValue('');
                                            props?.onCloseComplementDossierModal()
                                        }}>
                                            <span className="return-back-group">
                                                <span className="return-back-icon-container">
                                                    <img src={ReturnBack} alt="Return back" className="return-back-icon" />
                                                </span>
                                                {
                                                    window.innerWidth > 480 && <span className="return-back-label">Retour à la liste</span>
                                                }
                                            </span>
                                        </button>
                                    </div>
                                    <div className="v2-modal-header-title-container">
                                        <div className="modal-header-title-group">
                                            <div className="modal-header-title-icon-container">
                                                <img src={OrientationIcon} alt="Return back" className="modal-header-title-icon" />
                                            </div>
                                            <div className="modal-header-title-label">RDV de complément de dossier</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="v2-modal-body">
                                <div className="v2-modal-body-content-container">
                                    <div className="v2-modal-body-row row checking-meeting-row">
                                        <div className="col-md-5 calendar-md-col">
                                            <div className="calendar-body-content">
                                                <Calendar 
                                                    value={value}
                                                    onClickDay={onClickDay}
                                                    onChange={onChangeDate}
                                                    tileClassName={ComplementDossierTileClassName}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-7 calendar-select-result-md-col">
                                            <div className="calendar-body-select-result-content">
                                                {(hasNoRequest && !checkEventInProgress) ? (
                                                        <div className="row empty-alert-row">
                                                            <div className="col-md-2 empty-icon-col">
                                                                <FeatherIcon
                                                                    icon="alert-circle"
                                                                    className="m-b-3 empty-alert-icon rotateIn"
                                                                />
                                                            </div>
                                                            <div className="col-md-10 empty-textual-col">
                                                                <p><strong>Vous devez choisir une date.</strong></p>
                                                            </div>
                                                        </div>
                                                    ) : (!hasNoRequest && checkEventInProgress) ? (
                                                        <div className="loadering">
                                                            <span class="checkRDVloader"></span>
                                                        </div>
                                                    ) : (!hasNoRequest && !checkEventInProgress) && (
                                                        choosedDateEvent?.length === 0 ? (
                                                            <div className="empty-rdv-textual-container">
                                                                <span>Aucun rendez vous disponible à cette date.</span>
                                                                <button onClick={() => onMeetWithConseiller('complement_dossier')} className="btn meet-conseiller-btn">Échanger avec un conseiller</button>
                                                            </div>
                                                        ) : (
                                                            <div className="no-empty-rdv-infos-container">
                                                                <h6 className="no-empty-rdv-label">Des rendez-vous sont disponibles pour le { moment(value).format("DD/MM/YYYY") } :</h6>
                                                                <ul className="no-empty-rdv-ul">
                                                                    {choosedDateEvent?.map((item, index) => 
                                                                        (((item?.eventInfos?.event_participant_count - item?.participants) <= 0) ) ? (
                                                                                <li className="disable-item" 
                                                                                    key={'no-empty-event' + index}
                                                                                > 
                                                                                    De {item?.startTime} à {item?.endTime} à <span className="event-location-label">{ item?.eventInfos?.ville?.name }</span>&nbsp;
                                                                                    <span className="event-particant-count"> 
                                                                                        {'0 place restante'} 
                                                                                    </span> 
                                                                                </li>
                                                                            ) : (((item?.eventInfos?.event_participant_count - item?.participants) > 0) && dateCompare(moment(item?.date).format('MM/DD/YYYY'), item?.eventInfos?.event_start_time) === 'is greater than today') ? (
                                                                                <li className="linkable" 
                                                                                    onClick={() => onGetSubscribeFormModal('complement', item)} 
                                                                                    key={'no-empty-event' + index}
                                                                                > 
                                                                                    De {item?.startTime} à {item?.endTime} à <span className="event-location-label">{ item?.eventInfos?.ville?.name }</span>&nbsp;
                                                                                    <span className="event-particant-count"> 
                                                                                        { (item?.eventInfos?.event_participant_count - item?.participants) + ' place(s) restante(s)'} 
                                                                                    </span> 
                                                                                </li>
                                                                            ) : (
                                                                                <li className="disable-item" 
                                                                                    key={'no-empty-event' + index}
                                                                                > 
                                                                                    De {item?.startTime} à {item?.endTime} à <span className="event-location-label">{ item?.eventInfos?.ville?.name }</span>&nbsp;
                                                                                    <span className="event-particant-count"> 
                                                                                        {'0 place restante'} 
                                                                                    </span> 
                                                                                </li>
                                                                            ) 
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </Modal>

            {/* Préparation de séjour */}
            <Modal
                    isOpen={props?.isOpenPreparationSejourModal}
                    className="Modal"
                    overlayClassName="Overlay"
                    style={customStyles}
                >
                    <div className="v2-modal-content-container">
                        <div className="v2-modal-content">
                            <div className="v2-modal-header">
                                <div className="v2-modal-header-content">
                                    <div className="v2-modal-return-back-or-close-btn-container">
                                        <button className="btn btn-danger v2-return-back-btn" onClick={() => {
                                            setHasNoRequest(true);
                                            setValue('');
                                            props?.onClosePreparationSejourModal()
                                        }}>
                                            <span className="return-back-group">
                                                <span className="return-back-icon-container">
                                                    <img src={ReturnBack} alt="Return back" className="return-back-icon" />
                                                </span>
                                                {
                                                    window.innerWidth > 480 && <span className="return-back-label">Retour à la liste</span>
                                                }
                                            </span>
                                        </button>
                                    </div>
                                    <div className="v2-modal-header-title-container">
                                        <div className="modal-header-title-group">
                                            <div className="modal-header-title-icon-container">
                                                <img src={OrientationIcon} alt="Return back" className="modal-header-title-icon" />
                                            </div>
                                            <div className="modal-header-title-label">Séance de préparation au séjour en France</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="v2-modal-body">
                                <div className="v2-modal-body-content-container">
                                    <div className="v2-modal-body-row row checking-meeting-row">
                                        <div className="col-md-5 calendar-md-col">
                                            <div className="calendar-body-content">
                                                <Calendar 
                                                    value={value}
                                                    onClickDay={onClickDay}
                                                    onChange={onChangeDate}
                                                    tileClassName={PreparationDepartTileClassName}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-7 calendar-select-result-md-col">
                                            <div className="calendar-body-select-result-content">
                                                {(hasNoRequest && !checkEventInProgress) ? (
                                                        <div className="row empty-alert-row">
                                                            <div className="col-md-2 empty-icon-col">
                                                                <FeatherIcon
                                                                    icon="alert-circle"
                                                                    className="m-b-3 empty-alert-icon rotateIn"
                                                                />
                                                            </div>
                                                            <div className="col-md-10 empty-textual-col">
                                                                <p><strong>Vous devez choisir une date.</strong></p>
                                                            </div>
                                                        </div>
                                                    ) : (!hasNoRequest && checkEventInProgress) ? (
                                                        <div className="loadering">
                                                            <span class="checkRDVloader"></span>
                                                        </div>
                                                    ) : (!hasNoRequest && !checkEventInProgress) && (
                                                        choosedDateEvent?.length === 0 ? (
                                                            <div className="empty-rdv-textual-container">
                                                                <span>Aucun rendez vous disponible à cette date.</span>
                                                                <button onClick={() => onMeetWithConseiller('preparation_sejour')} className="btn meet-conseiller-btn">Échanger avec un conseiller</button>
                                                            </div>
                                                        ) : (
                                                            <div className="no-empty-rdv-infos-container">
                                                                <h6 className="no-empty-rdv-label">Des rendez-vous sont disponibles pour le { moment(value).format("DD/MM/YYYY") } :</h6>
                                                                <ul className="no-empty-rdv-ul">
                                                                    {choosedDateEvent?.map((item, index) => 
                                                                        (((item?.eventInfos?.event_participant_count - item?.participants) <= 0) ) ? (
                                                                                <li className="disable-item" 
                                                                                    key={'no-empty-event' + index}
                                                                                > 
                                                                                    De {item?.startTime} à {item?.endTime} à <span className="event-location-label">{ item?.eventInfos?.ville?.name }</span>&nbsp;
                                                                                    <span className="event-particant-count"> 
                                                                                        {'0 place restante'} 
                                                                                    </span> 
                                                                                </li>
                                                                            ) : (((item?.eventInfos?.event_participant_count - item?.participants) > 0) && dateCompare(moment(item?.date).format('MM/DD/YYYY'), item?.eventInfos?.event_start_time) === 'is greater than today') ? (
                                                                                <li className="linkable" 
                                                                                    onClick={() => onGetSubscribeFormModal('preparation', item)} 
                                                                                    key={'no-empty-event' + index}
                                                                                > 
                                                                                    De {item?.startTime} à {item?.endTime} à <span className="event-location-label">{ item?.eventInfos?.ville?.name }</span>&nbsp;
                                                                                    <span className="event-particant-count"> 
                                                                                        { (item?.eventInfos?.event_participant_count - item?.participants) + ' place(s) restante(s)'} 
                                                                                    </span> 
                                                                                </li>
                                                                            ) : (
                                                                                <li className="disable-item" 
                                                                                    key={'no-empty-event' + index}
                                                                                > 
                                                                                    De {item?.startTime} à {item?.endTime} à <span className="event-location-label">{ item?.eventInfos?.ville?.name }</span>&nbsp;
                                                                                    <span className="event-particant-count"> 
                                                                                        {'0 place restante'} 
                                                                                    </span> 
                                                                                </li>
                                                                            ) 
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </Modal>


            {/* Event subscribe modal */}
            <Modal
                    isOpen={props?.isOpenSubscribeEventModal}
                    className="Modal"
                    overlayClassName="Overlay"
                    style={customStyles}
                >
                    <div className="v2-modal-content-container">
                        <div className="v2-modal-content">
                            <div className="v2-modal-header">
                                <div className="v2-modal-header-content">
                                    <div className="v2-modal-return-back-or-close-btn-container">
                                        <button className="btn btn-danger v2-return-back-btn" onClick={() => props?.onCloseSubscribeEventModal('orientation')}>
                                            <span className="return-back-group">
                                                <span className="return-back-icon-container">
                                                    <img src={ReturnBack} alt="Return back" className="return-back-icon" />
                                                </span>
                                                {
                                                    window.innerWidth > 480 && <span className="return-back-label">Fermer</span>
                                                }
                                            </span>
                                        </button>
                                    </div>
                                    <div className="v2-modal-header-title-container">
                                        <div className="modal-header-title-group">
                                            <div className="modal-header-title-icon-container">
                                                <img src={OrientationIcon} alt="Return back" className="modal-header-title-icon" />
                                            </div>
                                            <div className="modal-header-title-label">Inscription</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="v2-modal-body">
                                <div className="v2-modal-body-content-container">
                                    <div className="v2-modal-body-row row">
                                        <form id="event-subscribe-form">
                                            <div className="row add-event-row">
                                                <div className="col-md-6 offset-md-3 add-event-col event-checkout-form-infos">
                                                    <h4 className="date-choice-label"> Date de RDV </h4>
                                                    <div className="choosed-datetime-infos">
                                                        { moment(eventInfos?.date).format('DD/MM/YYYY') + ' de ' + eventInfos?.startTime + ' à ' + eventInfos?.endTime + ' à ' + eventInfos?.eventInfos?.ville?.name }
                                                        <span className={ parseInt(eventInfos?.eventInfos?.event_participant_count) - parseInt(eventInfos?.participants) !== 0 ? "count-remaining" : "ready-remaining" }> 
                                                            { parseInt(eventInfos?.eventInfos?.event_participant_count) - parseInt(eventInfos?.participants) } place(s) 
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 o-form-col">
                                                    <div className="form-group o-form-group">
                                                        <label forHtml="event_checkout_fullname">Prénom et Nom</label>
                                                        <input onChange={checkoutEventHandleChange} type="text" placeholder="Votre Prénom et Nom" className="form-control oe-form-control" name="event_checkout_fullname" id="event_checkout_fullname" />
                                                    </div>
                                                    {is_empty_event_checkout_fullname &&
                                                        <div className="alert alert-danger oe-alert-danger"> { checkoutErrors.event_checkout_fullname } </div>
                                                    }
                                                </div>
                                                <div className="col-md-6 o-form-col">
                                                    <div className="form-group o-form-group">
                                                        <label forHtml="event_checkout_email">Votre Email</label>
                                                        <input onChange={checkoutEventHandleChange} type="text" placeholder="Votre Email" className="form-control oe-form-control" name="event_checkout_email" id="event_checkout_email" />
                                                    </div>
                                                    {is_empty_event_checkout_email &&
                                                        <div className="alert alert-danger oe-alert-danger"> { checkoutErrors.event_checkout_email } </div>
                                                    } 
                                                    {is_invalid_event_checkout_email &&
                                                        <div className="alert alert-danger oe-alert-danger"> { checkoutErrors.invalid_event_checkout_email } </div>
                                                    }
                                                </div>
                                                <div className="col-md-6 o-form-col">
                                                    <div className="form-group o-form-group">
                                                        <label forHtml="event_checkout_phone">Votre Téléphone</label>
                                                        <input onChange={checkoutEventHandleChange} type="text" placeholder="Votre Téléphone" className="form-control oe-form-control" name="event_checkout_phone" id="event_checkout_phone" />
                                                    </div>
                                                    {is_empty_event_checkout_phone &&
                                                        <div className="alert alert-danger oe-alert-danger"> { checkoutErrors.event_checkout_phone } </div>
                                                    }
                                                </div>
                                                <div className="col-md-6 o-form-col">
                                                    <div className="form-group o-form-group">
                                                        <label forHtml="event_checkout_address">Votre Adresse</label>
                                                        <input onChange={checkoutEventHandleChange} type="text" placeholder="Votre adresse de résidence" className="form-control oe-form-control" name="event_checkout_address" id="event_checkout_address" />
                                                    </div>
                                                    {is_empty_event_checkout_address &&
                                                        <div className="alert alert-danger oe-alert-danger"> { checkoutErrors.event_checkout_address } </div>
                                                    }
                                                </div>
                                                <div className="col-md-6 o-form-col">
                                                    <div className="form-group o-form-group">
                                                        <label htmlFor="has_doing_auto_evaluation">Avez-vous été auto-évalué ? <span className="required-icon">*</span></label>
                                                        <select onChange={checkoutEventHandleChange} name="has_doing_auto_evaluation" className="form-control oe-form-control v2-select-form-control" id="has_doing_auto_evaluation">
                                                            <option selected disabled>--Choisir--</option>
                                                            <option value={1}>Oui</option>
                                                            <option value={0}>Non</option>
                                                        </select>
                                                    </div>
                                                    {is_empty_has_doing_auto_evaluation &&
                                                        <div className="alert alert-danger oe-alert-danger">
                                                            {checkoutErrors.has_doing_auto_evaluation}
                                                        </div>
                                                    }
                                                </div>
                                                {showNumeroDossierInput &&
                                                    <div className="col-md-6 o-form-col">
                                                        <div className="form-group o-form-group">
                                                            <label htmlFor="numero_dossier_auto_evaluation">Votre Numéro de dossier <span className="required-icon">*</span></label>
                                                            <input placeholder="Votre Numéro de dossier" onChange={checkoutEventHandleChange} name="numero_dossier_auto_evaluation" className="form-control oe-form-control" id="numero_dossier_auto_evaluation" />
                                                        </div>
                                                        {is_empty_numero_dossier_auto_evaluation &&
                                                            <div className="alert alert-danger oe-alert-danger">
                                                                {checkoutErrors.numero_dossier_auto_evaluation}
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                <div className="col-md-6 o-form-col">
                                                    <div className="form-group o-form-group">
                                                        <label forHtml="event_checkout_region">Votre Région de résidence</label>
                                                        <select onChange={checkoutEventHandleChange} name="event_checkout_region" id="event_checkout_region" className="form-control oe-form-control v2-select-form-control">
                                                            <option>--Choisir une ville--</option>
                                                            {
                                                                villes?.map((ville, index) => 
                                                                    <option key={`select-ville-${index}`} value={ville?.id}> { ville?.name } </option>
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                    {is_empty_event_checkout_region &&
                                                        <div className="alert alert-danger oe-alert-danger"> { checkoutErrors.event_checkout_region } </div>
                                                    }
                                                    {is_different_event_checkout_region &&
                                                        <div className="alert alert-danger oe-alert-danger"> { checkoutErrors.different_event_checkout_region } </div>
                                                    }
                                                </div>
                                                <div className="col-md-6 o-form-col">
                                                    <div className="form-group o-form-group">
                                                        <label forHtml="event_checkout_compte_etudes_fr">Avez-vous un compte etudes SN ?</label>
                                                        <select onChange={checkoutEventHandleChange} name="event_checkout_compte_etudes_fr" id="event_checkout_compte_etudes_fr" className="form-control oe-form-control v2-select-form-control">
                                                            <option>--Choisir une option--</option>
                                                            <option value={1}> OUI </option>
                                                            <option value={0}> NON </option>
                                                        </select>
                                                    </div>
                                                    {is_empty_event_checkout_compte_etudes_fr &&
                                                        <div className="alert alert-danger oe-alert-danger"> { checkoutErrors.event_checkout_compte_etudes_fr } </div>
                                                    }
                                                </div>

                                                {checkoutHasCompteEtudesFR &&
                                                    <div className="col-md-6 o-form-col">
                                                        <div className="form-group o-form-group">
                                                            <label forHtml="event_checkout_compte_etudes_fr_number">Votre Numero de Compte Etudes SN</label>
                                                            <input onChange={checkoutEventHandleChange} type="text" placeholder="Votre Numero de Compte Etudes SN" className="form-control oe-form-control" name="event_checkout_compte_etudes_fr_number" id="event_checkout_compte_etudes_fr_number" />
                                                        </div>
                                                        {is_empty_event_checkout_compte_etudes_fr_number &&
                                                            <div className="alert alert-danger oe-alert-danger"> { checkoutErrors.event_checkout_compte_etudes_fr_number } </div>
                                                        }
                                                    </div>
                                                }

                                                <div className="col-md-6 offset-md-3 add-event-submit-col">
                                                    {!eventCheckoutInProgress ?
                                                        <button className="btn btn-primary add-event-submit-btn" onClick={ (e) => onSubmitEventCheckout(e) }>
                                                            <FeatherIcon icon="plus" className="submit-add-icon" />&nbsp;
                                                            <span className="submit-add-label">S'inscrire</span>
                                                        </button> :
                                                        <button className="btn btn-primary add-event-submit-btn">
                                                            <i className="fas fa-spin fa-spinner"></i>&nbsp;
                                                            <span className="submit-add-label">Inscription en cours</span>
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </Modal>

            {/* Play tutorials videos */}
            <Modal
                    isOpen={props?.isOpenPlayingTutorialVideoModal}
                    className="Modal"
                    overlayClassName="Overlay"
                    style={customStyles}
                >
                    <div className="v2-modal-content-container">
                        <div className="v2-modal-content">
                            <div className="v2-modal-header">
                                <div className="v2-modal-header-content">
                                    <div className="v2-modal-return-back-or-close-btn-container">
                                        <button className="btn btn-danger v2-return-back-btn" onClick={() => props?.onClosePlayingTutorialVideoModal(tutorielEventType)}>
                                            <span className="return-back-group">
                                                <span className="return-back-icon-container">
                                                    <img src={ReturnBack} alt="Return back" className="return-back-icon" />
                                                </span>
                                                {
                                                    window.innerWidth > 480 && <span className="return-back-label">Retour à la liste</span>
                                                }
                                            </span>
                                        </button>
                                    </div>
                                    <div className="v2-modal-header-title-container">
                                        <div className="modal-header-title-group">
                                            {/* <div className="modal-header-title-icon-container">
                                                <img src={OrientationIcon} alt="Return back" className="modal-header-title-icon" />
                                            </div> */}
                                            <div className="modal-header-title-label">{ videoInfos?.video_title }</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="v2-modal-body">
                                <div className="v2-modal-body-content-container">
                                    <div className="v2-modal-body-row">
                                        <div className="row modal-play-video-row">
                                            <div className="col-md-12 modal-play-video-col">
                                                <div className="modal-play-video-container">
                                                    <ReactPlayer
                                                        className="visiteur_youtube_video_playing"
                                                        url={videoInfos?.youtube_url}
                                                    /> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </Modal>
        </>
    )
}

const customStyles = {
    content: {
        // position: "absolute",
        // top: "20%",
        // width: "100%",
        // heigth: "auto",
        // left: "0",
        // right: "0",
        // margin: "auto",
    },
};

export default JeMorienteIndexModal