import React, { useState } from 'react';
import './AutoEvaluationHome.css';
import FooterV2 from '../../footerV2/FooterV2';
import LandingPageHeaderV2 from '../../landingPageV2/LandingPageHeaderV2';
import AutoEvalHomeDecoVector from '../../../img/v2/AutoEvalHomeDecoVector.svg';
import AutoEvalHomeIconGroup from '../../../img/v2/AutoEvalHomeIconGroup.svg';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import toast, { Toaster } from 'react-hot-toast';

const AutoEvaluationHome = () => {
    const [isFirstStep, setIsFirstStep] = useState(true);
    const [isSecondStep, setIsSecondStep] = useState(false);
    // const [consent, setConsent] = useState({});
    // const [consentIsOK, setConsentIsOK] = useState(true);
    const [consentSubmitInProgress, setConsentSubmitInProgress] = useState(false);
    const [nokSubmitInProgress, setNokSubmitInProgress] = useState(false);
    const [numeroDossier, setNumeroDossier] = useState('');
    const [is_empty_numero_dossier, set_is_empty_numero_dossier] = useState(false);
    const [checkNumeroDossierInProgress, setCheckNumeroDossierInProgress] = useState(false);
    const history = useHistory()

    // const handleChangeConsent = (e) => {
    //     // consent[e.target.name] = e.target.value;
    //     setConsent({...consent, [e.target.name]: e.target.value})
    //     console.log(consent)
    // }

    // const onValidateConsentForm = () => {
    //     var isValidForm = true;

    //     if(!consent['warning11'] ||
    //         !consent['warning21'] ||
    //         !consent['warning31'] ||
    //         !consent['warning41'] ||
    //         !consent['warning51'] ||
    //         !consent['warning61']) {
    //             isValidForm = false;
    //             setConsentIsOK(false);
    //             setTimeout(() => {
    //                 setConsentIsOK(true);
    //             }, 5000);
    //     }

    //     return isValidForm;
    // }

    const onSubmitConsent = (e, cst) => {
        e.preventDefault();
        if(cst === 'ok'){
            setConsentSubmitInProgress(true);
            setTimeout(() => {
                setConsentSubmitInProgress(false);
                document.getElementById('ae-warning-confirm-form').reset();
                $('.close').click();
                
                history.push({ 
                    pathname: "/auto-evaluation-quiz",
                    state: { consent: "ok" }
                  });
            }, 4000);
        }
        if(cst === 'nok'){
            setNokSubmitInProgress(true);
            setTimeout(() => {
                setNokSubmitInProgress(false);
                document.getElementById('ae-warning-confirm-form').reset();
                setIsFirstStep(true);
                setIsSecondStep(false);
                $('.close').click();
                toast.error('Vous ne pouvez pas accéder à l\'outil d\'auto-évaluation parce que vous ne consentez pas à toutes tes conditions préalables requises.', {
                    duration: 7000,
                    position: 'top-center'
                });
            }, 4000);
        }
        // if(onValidateConsentForm()){
        //     setConsentSubmitInProgress(true);
        //     if(consent['warning11'] === 'Oui' &&
        //         consent['warning21'] === 'Oui' &&
        //         consent['warning31'] === 'Oui' &&
        //         consent['warning41'] === 'Oui' &&
        //         consent['warning51'] === 'Oui' &&
        //         consent['warning61']=== 'Oui'){
        //             setConsentSubmitInProgress(false);
        //             document.getElementById('ae-warning-confirm-form').reset();
        //             $('.close').click();
        //             history.push("/auto-evaluation-quiz");
        //     }
        //     if(consent['warning11'] === 'Non' ||
        //         consent['warning21'] === 'Non' ||
        //         consent['warning31'] === 'Non' ||
        //         consent['warning41'] === 'Non' ||
        //         consent['warning51'] === 'Non' ||
        //         consent['warning61']=== 'Non'){
        //             setConsentSubmitInProgress(false);
        //             document.getElementById('ae-warning-confirm-form').reset();
        //             setIsFirstStep(true);
        //             setIsSecondStep(false);
        //             $('.close').click();
        //             toast.error('Vous ne pouvez pas accéder à l\'outil d\'auto-évaluation parce que vous ne consentez pas à toutes tes conditions préalables requises.', {
        //                 duration: 7000,
        //                 position: 'top-center'
        //             });
        //     }
        // }
        
    }

    const onCheckProfile = (e) => {
        e.preventDefault();
        var isValidForm = true;
        if(numeroDossier.length === 0){
            isValidForm = false;
            set_is_empty_numero_dossier(true);
            setTimeout(() => {
                set_is_empty_numero_dossier(false);
            }, 5000);
        }

        if(isValidForm){
            setCheckNumeroDossierInProgress(true);
            setTimeout(() => {
                setCheckNumeroDossierInProgress(false);
                document.getElementById("checkNumeroDossierForm").reset();
                $(".close").click();
                history.push('/mon-profil-etudiant/' + numeroDossier);
            }, 3000);
        }
    }

    return (
        <div className="auto-evaluation-home-component">
            <LandingPageHeaderV2 />

            <section id="auto-eval-home-page-section">
                <div className="auto-eval-home-page-container container">
                    <div className="auto-eval-home-page-body">
                        <div className="auto-eval-home-page-content">
                            {(isFirstStep && !isSecondStep) && (
                                <>
                                    <img src={AutoEvalHomeIconGroup} className="home-icon-group" alt="Auto Evaluation" />
                                    <img src={AutoEvalHomeDecoVector} className="home-deco-vector" alt="Auto Evaluation" />

                                    <div className="auto-eval-home-textual-container">
                                        <div className="auto-eval-home-textual-content">
                                            J’évalue <br /> mon projet
                                        </div>
                                        <div className="auto-eval-next-item-btn-container">
                                            <button 
                                                className="btn btn-default auto-eval-next-item-btn"
                                                onClick={() => {
                                                    setIsFirstStep(false);
                                                    setIsSecondStep(true);
                                                }}
                                            >
                                                Commencer
                                            </button>
                                            {/* <button
                                                className="btn btn-default check-your-profile"
                                                data-toggle="modal"
                                                data-target="#checkNumeroDossierModal"
                                            >
                                                Voir votre profil étudiant
                                            </button> */}
                                        </div>
                                    </div>
                                </>)
                            }

                            {(!isFirstStep && isSecondStep) && (
                                <div className="second-step">
                                    <div className="auto-eval-home-deco-vector-container">
                                        <img src={AutoEvalHomeDecoVector} className="home-deco-vector" alt="Auto Evaluation" />
                                    </div>
                                    <div className="auto-eval-home-textual-container">
                                        <div className="auto-eval-home-textual-content">
                                            <h2 className="auto-eval-warning-title">ATTENTION</h2>
                                            <p>
                                                Le test est personnel (ne doit pas être remplis par un tiers). Il est purement indicatif et ne garantit pas l’obtention d’une acceptation au sein d’un établissement ou du visa.
                                            </p>
                                        </div>
                                        <div className="auto-eval-next-item-btn-container">
                                            <button 
                                                className="btn btn-default auto-eval-next-item-btn"
                                                data-toggle="modal"
                                                data-target="#autoEvalConfirmationWarningModal"
                                            >
                                                J'ai compris
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                            }
                        </div>
                    </div>
                </div> 
            </section>

            <FooterV2 />

            <div className="modal fade auto-eval-modal" id="autoEvalConfirmationWarningModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal-body">
                            <div className="ae-warning-confirm-container">
                                <div className="ae-warning-confirm-form-container">
                                    <form id="ae-warning-confirm-form">
                                        <div className="row ae-warning-confirm-row-item">
                                            <div className="col-md-12 ae-warning-confirm-col-item left">
                                                <div className="warning-confirm-textual-container">
                                                    <p>
                                                        Ce test devant se dérouler sans interruption ni retour en arrière 
                                                        possible nous vous conseillons donc de prévoir 20 minutes pour y 
                                                        répondre et dans les meilleures conditions possibles : endroit aéré, 
                                                        lumineux, sans bruit, vous devez être détendu et surtout pour que 
                                                        le test soit concluant vous devez répondre le plus sincèrement 
                                                        et objectivement possible.  
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="ae-separator"></div>

                                        <div className="row ae-warning-confirm-row-item">
                                            <div className="col-md-12 ae-warning-confirm-col-item left">
                                                <div className="warning-confirm-textual-container">
                                                    <p>
                                                        Seuls les candidats étant en terminale, BT 3 ou à un 
                                                        niveau supérieur peuvent faire le test d’auto évaluation.  
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="ae-separator"></div>

                                        <div className="row ae-warning-confirm-row-item">
                                            <div className="col-md-12 ae-warning-confirm-col-item left">
                                                <div className="warning-confirm-textual-container">
                                                    <p>
                                                        Vos bulletins et /ou relevés de notes doivent être à proximité 
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="ae-separator"></div>

                                        <div className="row ae-warning-confirm-row-item">
                                            <div className="col-md-12 ae-warning-confirm-col-item left">
                                                <div className="warning-confirm-textual-container">
                                                    <p>
                                                        Il y a un délai de réponse pour chaque question (30 secondes max à déterminer)  
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="ae-separator"></div>

                                        <div className="row ae-warning-confirm-row-item">
                                            <div className="col-md-12 ae-warning-confirm-col-item left">
                                                <div className="warning-confirm-textual-container">
                                                    <p>
                                                        Une fois que la question est validée il est impossible de revenir en arrière
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="ae-separator"></div>

                                        <div className="row ae-warning-confirm-row-item">
                                            <div className="col-md-12 ae-warning-confirm-col-item left">
                                                <div className="warning-confirm-textual-container">
                                                    <p>
                                                        Une personne ne peut remplir le formulaire plus d’une fois (par campagne)
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="ae-separator"></div>

                                        {/* {!consentIsOK &&
                                            <div className="consent-error-container">
                                                <div className="alert alert-danger consent-alert-danger">
                                                    Vous devez confirmer toutes les propositions
                                                </div>
                                            </div>
                                        } */}

                                        <div className="row ea-warning-submit-btn-container">
                                            <div className="col-md-6 ea-submit-btn-col nok-btn-col">
                                                {!nokSubmitInProgress ? (
                                                    <button onClick={(e) => onSubmitConsent(e, "nok")} className="btn btn-default ea-warning-submit-btn nok-btn">
                                                        Je n'accepte pas
                                                    </button>
                                                    ) : (
                                                        <button className="btn btn-default ea-warning-submit-btn nok-inprogress">
                                                            En Cours
                                                        </button>
                                                    )
                                                }
                                            </div>
                                            <div className="col-md-6 ea-submit-btn-col">
                                                {!consentSubmitInProgress ? (
                                                    <button onClick={(e) => onSubmitConsent(e, "ok")} className="btn btn-default ea-warning-submit-btn ok-btn">
                                                        J'accepte
                                                    </button>
                                                    ) : (
                                                        <button className="btn btn-default ea-warning-submit-btn ok-inprogress">
                                                            En Cours
                                                        </button>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade auto-eval-modal" id="checkNumeroDossierModal" tabIndex="-1" role="dialog" aria-labelledby="checkNumeroDossierModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal-body">
                            <div className="ae-warning-confirm-container">
                                <div className="ae-warning-confirm-form-container">
                                    <form id="checkNumeroDossierForm" onSubmit={(e) => onCheckProfile(e)}>
                                        <div className="row ae-warning-confirm-row-item">
                                            <div className="col-md-12 ae-quizz-form-col">
                                                <div className="form-group ae-quizz-form-group">
                                                    <label htmlFor="numero_dossier">Votre numéro de dossier</label>
                                                    <input onChange={(e) => setNumeroDossier(e.target.value)} type="text" name="numero_dossier" placeholder="Votre numéro de dossier" id="numero_dossier" className="form-control ae-quizz-form-control" />
                                                </div>
                                                {is_empty_numero_dossier && (
                                                    <div className="alert alert-danger ae-quizz-alert-danger">
                                                        Ce champ est requis.
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="row ea-warning-submit-btn-container">
                                            <div className="col-md-12 ea-submit-btn-col">
                                                {!checkNumeroDossierInProgress ? (
                                                    <button className="btn btn-default ea-warning-submit-btn">
                                                        Envoyer
                                                    </button>
                                                    ) : (
                                                        <button className="btn btn-default ea-warning-submit-btn">
                                                            En Cours
                                                        </button>
                                                    )
                                                }
                                                
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Toaster />
        </div>
    )
}

export default AutoEvaluationHome