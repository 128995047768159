import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../img/logo-campus-france.png';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import toast, {Toaster} from 'react-hot-toast';
import ApiBaseUrl from '../../http/ApiBaseUrl';

const EditPassword = () => {
    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    const [password_empty, set_password_empty] = useState(false);
    const [cpassword_empty, set_cpassword_empty] = useState(false);
    const [noMatchedPassword, set_noMatchedPassword] = useState(false);
    const [resetPasswordInProgress, setResetPasswordInProgress] = useState(false);
    let { authToken } = useParams();
    let nav = useHistory();

    const handleChange = (e) => {
        let _fields = fields;
        _fields[e.target.name] = e.target.value;
        setFields(_fields);
        console.log(fields)
    }
    
    
    const onValidateResetPasswordForm = () => {
        let _errors = errors;
        var isValidForm = true;
    
        if(!fields["password"]){
            isValidForm= false
            set_password_empty(true);
            setTimeout(() => {
                set_password_empty(false);
            }, 5000);
            _errors["password"] = 'Ce champ est obligatoire'
        }
        if(!fields["password_confirmation"]){
            isValidForm= false
            set_cpassword_empty(true);
            setTimeout(() => {
                set_cpassword_empty(false);
            }, 5000);
            _errors["password_confirmation"] = 'Ce champ est obligatoire'
        }
        if(fields["password"] !== fields["password_confirmation"]){
            isValidForm= false
            set_noMatchedPassword(true);
            setTimeout(() => {
                set_noMatchedPassword(false);
            }, 5000);
            _errors["matching_password_error"] = 'Les deux mots de passes ne sont pas identiques'
        }
    
        setErrors(_errors);
    
        return isValidForm;
    }
    
    const onSubmitResetPassword = (e) => {
        e.preventDefault();
    
        if(onValidateResetPasswordForm()){
            setResetPasswordInProgress(true);
        
            axios.post(ApiBaseUrl + 'reset-user-password/' + authToken, fields)
                .then(response => {
                    document.getElementById('reset-password-form').reset();
                    setResetPasswordInProgress(false);
                    if(response.data.success){
                        toast.success('Votre mot de passe a été bien réinitialisé. Vous serez redirigé vers la page de connexion dans 5s...', {
                            duration: 5000,
                            position: 'top-center',
                        });
                        setTimeout(() => {
                            nav.push('/admin-connexion');
                        }, 5000);
                    }else{
                        console.log(response)
                        toast.error('Un problème est survenu lors de la modification. Veuillez réessayer plus tard.', {
                            duration: 5000,
                            position: 'top-center',
                        });
                    }
                })
                .catch(error => {
                    console.log(error)
                    setResetPasswordInProgress(false);
                    toast.error('Un problème est survenu lors de la modification. Veuillez réessayer plus tard.', {
                        duration: 5000,
                        position: 'top-center',
                    });
                })
        }
    
    }

    return (
        <div className="dashboard-auth-component">
            <header>
            <div className="col-md-12 d-flex justify-content-center p-block-20 header-navbar">
                <NavLink to="/">
                <img className="logo-inscription" src={Logo} alt="Mon Espace Campus France" />
                </NavLink>
            </div>
            </header>
            <section id="auth-form-section">
                <div className="container auth-form-container">
                    <div className="auth-form-body">
                        <div className="auth-form-content">
                            <h3 className="auth-form-title">Réninitialisation de votre mot de passe</h3>
                            <form id="reset-password-form">
                                <div className="row auth-form-row">
                                    <div className="col-md-12 auth-input-col">
                                        <div className="auth-input-container form-group">
                                            <input onChange={handleChange} placeholder="Votre nouveau mot de passe" className="form-control auth-form-control" name="password" type="password" id="password" />
                                        </div>
                                        {password_empty &&
                                            <div className="alert alert-danger error-alert">
                                                {errors.password}
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md-12 auth-input-col">
                                        <div className="auth-input-container form-group">
                                            <input onChange={handleChange} placeholder="Confirmer votre nouveau mot de passe" className="form-control auth-form-control" name="password_confirmation" type="password" id="password_confirmation" />
                                        </div>
                                        {cpassword_empty &&
                                            <div className="alert alert-danger error-alert">
                                                {errors.password_confirmation}
                                            </div>
                                        }
                                        {noMatchedPassword &&
                                            <div className="alert alert-danger error-alert">
                                                {errors.matching_password_error}
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md-12 auth-submit-btn-col">
                                        {resetPasswordInProgress ?
                                            <button className="btn btn-success auth-submit-btn">modification en cours</button> :
                                            <button type="submit" onClick={(e) => onSubmitResetPassword(e)} className="btn btn-success auth-submit-btn">modifier</button>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Toaster />
        </div>
    )
}

export default EditPassword