// LOCAL
// var api = 'http://127.0.0.1:8000/api/';
// var baseApiUrl = 'http://127.0.0.1:8000'
// var domaineName = 'www.salonsn.withvolkeno.com'
// var jpoBaseApiUrl = 'http://127.0.0.1:8080/api/'

// PREPROD
var api = 'https://api-monespacesn-v2.withvolkeno.com/api/';
var baseApiUrl = 'https://api-monespacesn-v2.withvolkeno.com'
var domaineName = 'www.api-monespacesn-v2.withvolkeno.com'
var jpoBaseApiUrl = 'https://api-monespacesn-v2.withvolkeno.com/api/'


// PRODUCTION
// var api = 'https://prod.api-monespacesn-v2.withvolkeno.com/api/'
// var domaineName = 'www.salonvirtuelsenegal.campusfrance.org'
// var baseApiUrl = 'https://prod.api-monespacesn-v2.withvolkeno.com'
// var jpoBaseApiUrl = 'https://campus-france-jpo-prod-backend.withvolkeno.com/api/'

// TalkJS AppID LOCAL
var talkjsID = 'tM9Ecn0s';

// TalkJS AppID PRODUCTION
// var talkjsID = 'ZbVYWEk8';

module.exports = {
    api: api,
    talkjsID: talkjsID,
    domaineName: domaineName,
    baseApiUrl: baseApiUrl,
    jpoBaseApiUrl: jpoBaseApiUrl
}
 