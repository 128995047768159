import React, { useEffect, useState } from 'react';
import './CandidatPrivatePlanning.css';
import { Calendar } from 'react-calendar';
import HeaderOutilsEtudiant from '../headerOutilsEtudiant';
import moment from 'moment';
import axios from 'axios';
import ApiBaseUrl from '../../http/ApiBaseUrl';
import FeatherIcon from 'feather-icons-react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import Skeleton from 'react-loading-skeleton';
import toast, { Toaster } from 'react-hot-toast';
import { differenceInCalendarDays } from 'date-fns'

const CandidatPrivatePlanning = () => {
    const requestConfig = {
        headers: { Authorization: `Bearer ${window.sessionStorage.getItem('token')}` }
    };
    const [hasNoRequest, setHasNoRequest] = useState(true);
    const [checkEventInProgress, setCheckEventInProgress] = useState(false);
    const [choosedDateEvent, setChoosedDateEvent] = useState();
    const [value, setValue] = useState();
    const [eventInfos, setEventInfos] = useState();

    const params = useParams();
    const [showAccessFormModal, setShowAccessFormModal] = useState(true);
    const [showCheckoutFormModal, setShowCheckoutFormModal] = useState(false);
    const [linkToken, setLinkToken] = useState(params?.link_token);
    const [linkTimestamp, setLinkTimestamp] = useState(params?.link_timestamp);
    const [giveCalendarAccess, setGiveCalendarAccess] = useState(false);
    const [accessFormData, setAccessFormData] = useState({});
    const [errorFormData, setErrorFormData] = useState({});
    const [is_empty_candidat_email, set_is_empty_candidat_email] = useState(false);
    const [is_invalid_email_syntax, set_is_invalid_email_syntax] = useState(false);
    const [is_empty_numero_dossier, set_is_empty_numero_dossier] = useState(false);
    const [is_empty_link_access_code, set_is_empty_link_access_code] = useState(false);
    const [accessRequestInProgress, setAccessRequestInProgress] = useState(false);
    const [candidatPlanning, setCandidatPlanning] = useState([]);
    const [privateCheckoutFormData, setPrivateCheckoutFormData] = useState({});
    const [villes, setVilles] = useState([]);
    // eslint-disable-next-line
    const [checkoutFields, setCheckoutFields] = useState({});
    const [checkoutFieldsErrors, setCheckoutFieldsErrors] = useState({});
    const [checkoutInProgress, setCheckoutInProgress] = useState(false);
    const [checkoutHasCompteEtudesFR, setCheckoutHasCompteEtudesFR] = useState(false);
    const [is_empty_event_checkout_phone, set_is_empty_event_checkout_phone] = useState(false);
    const [is_empty_event_checkout_address, set_is_empty_event_checkout_address] = useState(false);
    const [is_empty_event_checkout_region, set_is_empty_event_checkout_region] = useState(false);
    const [is_empty_event_checkout_compte_etudes_fr, set_is_empty_event_checkout_compte_etudes_fr] = useState(false);
    const [is_empty_event_checkout_compte_etudes_fr_number, set_is_empty_event_checkout_compte_etudes_fr_number] = useState(false);
    const [is_not_same_good_region, set_is_not_same_good_region] = useState(false);
    const [choosedDate, setChoosedDate] = useState('');
    const [events, setEvents] = useState([]);
    const [availableDates, setAvailableDates] = useState([]);


    const handleCloseAccessFormModal = () => setShowAccessFormModal(false);
    // eslint-disable-next-line
    const handleShowAccessFormModal = () => setShowAccessFormModal(true);

    const handleCloseCheckoutFormModal = () => setShowCheckoutFormModal(false);
    const handleShowCheckoutFormModal = () => setShowCheckoutFormModal(true);

    useEffect(() => {
        setLinkToken(params?.link_token);
        setLinkTimestamp(params?.link_timestamp);
        onGetVilles()
    }, [linkToken, linkTimestamp, params]);

    const onGetUpdatedEvents = (eType, date) => {
        console.log('event date', date)
        setCheckEventInProgress(true);
        var eventType = '';
        if(eType === 'Aide à la construction du projet'){
            eventType = 'construction_projet'
        }
        if(eType === 'Entretien Individuel d\'orientation'){
            eventType = 'orientation_individuel'
        }
        axios.post(ApiBaseUrl + 'check-private-planning-date-events/' + eventType, {
            date: date
        }).then(response => {
            setHasNoRequest(false);
            setCheckEventInProgress(false);
            if(response.data.success && !response.data.empty){
                console.log('response.data.dates', response.data.dates)
                setChoosedDateEvent(response.data.dates);
            }
            if(!response.data.success && response.data.empty){
                console.log('empty', response)
            }
            // setCheckEventInProgress(false);
        })
    }

    const onGetVilles = () => {
        axios.get(ApiBaseUrl + 'villes')
          .then(response => {
            if (response.data.success) {
              setVilles(response.data.data)
            } else {
              console.log(response)
            }
          })
          .catch(error => {
            console.log(error)
          })
    }

    const onClickDay = (value, event) => {
        setHasNoRequest(false);
        setCheckEventInProgress(true);
        console.log('value', value)
        var choosedDate = moment(value).format("YYYY-MM-DD");
        setChoosedDate(choosedDate);
        var eventType = '';
        if(candidatPlanning?.type_rdv === 'Aide à la construction du projet'){
            eventType = 'construction_projet'
        }
        if(candidatPlanning?.type_rdv === 'Entretien Individuel d\'orientation'){
            eventType = 'orientation_individuel'
        }

        axios.post(ApiBaseUrl + 'check-private-planning-date-events/' + eventType, {
            date: choosedDate
        }).then(response => {
            console.log(response)
            if(response.data.success && !response.data.empty){
                setChoosedDateEvent(response.data.dates);
            }
            if(!response.data.success && response.data.empty){
                console.log('empty', response)
            }
            setCheckEventInProgress(false);
        })
        
    }

    const onChangeDate = (value, event) => {
        console.log('event', event);
        setValue(value)
    }

    const dateCompare = (date, time) => {
        var dt = date + " " + time
        const datetime = new Date(dt)
        const date2 = new Date();
    
        if(datetime > date2){
            return 'is greater than today'
        } else if(datetime < date2){
            return 'today is greater than'
        } else{
            return 'are equal'
        }
    }

    const onGetEventInfos = (event) => {
        setEventInfos(event);
    }

    const onGetSubscribeFormModal = (eventType, item) => {
        onGetEventInfos(item);
        handleShowCheckoutFormModal()
        console.log('item', item);
    }

    const handleChangeAccessForm = (e) => {
        let fields = accessFormData;
        fields[e.target.name] = e.target.value;
        setAccessFormData(fields);
    }

    const onValidateAccessFormData = () => {
        var isValidForm = true;
        let errors = errorFormData;
        if(!accessFormData['candidat_email']){
            isValidForm = false;
            set_is_empty_candidat_email(true);
            setTimeout(() => {
                set_is_empty_candidat_email(false);
            }, 5000);
            errors['candidat_email'] = 'Ce champ est requis.'
        }
        if (typeof accessFormData["candidat_email"] !== "undefined") {
            var pattern = new RegExp(
              /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(accessFormData["candidat_email"])) {
                isValidForm = false;
                set_is_invalid_email_syntax(true);
                setTimeout(() => {
                    set_is_invalid_email_syntax(false);
                }, 5000);
                errors["invalid_email_syntax"] = "Votre adresse email n'est pas valide";
            }
        }
        if(!accessFormData['numero_dossier']){
            isValidForm = false;
            set_is_empty_numero_dossier(true);
            setTimeout(() => {
                set_is_empty_numero_dossier(false);
            }, 5000);
            errors['numero_dossier'] = 'Ce champ est requis.'
        }
        if(!accessFormData['link_access_code']){
            isValidForm = false;
            set_is_empty_link_access_code(true);
            setTimeout(() => {
                set_is_empty_link_access_code(false);
            }, 5000);
            errors['link_access_code'] = 'Ce champ est requis.'
        }
        setErrorFormData(errors);
        return isValidForm;
    }

    const onSubmitAccessRequest = () => {
        if(onValidateAccessFormData()){
            setAccessRequestInProgress(true);
            axios.post(`${ApiBaseUrl}check-private-planning-access`, accessFormData, requestConfig)
                .then(response => {
                    setAccessRequestInProgress(false)
                    if(response.data.success && response.data.has_access){
                        toast.success(response.data.message, {
                            duration: 8000,
                            position: 'top-center'
                        });
                        setCandidatPlanning(response.data.data);
                        setEvents(response.data.events)
                        setShowAccessFormModal(false);
                        setGiveCalendarAccess(true);
                        setAccessFormData({})
                        setErrorFormData({})
                        handleCloseAccessFormModal();
                    }
                })
                .catch(error => {
                    console.log('error', error)
                    setAccessRequestInProgress(false)
                })
        }
    }

    const onPopulateCalendar = async (evts) => {
        let availables = [];
        await evts?.map((event) => {
            // eslint-disable-next-line
            return event?.dates?.map((date) => {
                const dt = new Date(date?.date);
                availables.push(dt)
            })
        })
        return setAvailableDates(availables);
    }

    useEffect(() => {
        if(events){
            onPopulateCalendar(events)
        }

    }, [events]);

    function isSameDay(a, b) {
        return differenceInCalendarDays(a, b) === 0;
    }

    function tileClassName({ date, view }) {
        // Add class to tiles in month view only
        if (view === 'month') {
          // Check if a date React-Calendar wants to check is on the list of dates to add class to
          if (availableDates.find(dDate => isSameDay(dDate, date))) {
            return 'available-date';
          }
        }
    }

    const handleChangePrivateCheckout = (e) => {
        let fields = privateCheckoutFormData;
        fields[e.target.name] = e.target.value;
        if(e.target.name === 'event_checkout_compte_etudes_fr' && (e.target.value === '1' || e.target.value === 1)){
            setCheckoutHasCompteEtudesFR(true);
        }
        if(e.target.name === 'event_checkout_compte_etudes_fr' && (e.target.value === '0' || e.target.value === 0)){
            setCheckoutHasCompteEtudesFR(false);
        }
        setPrivateCheckoutFormData(fields);
    }

    const onValidatePrivateCheckout = () => {
        var isValidForm = true;
        let errors = checkoutFieldsErrors;
        if(!privateCheckoutFormData['event_checkout_phone']){
            isValidForm = false;
            set_is_empty_event_checkout_phone(true);
            setTimeout(() => {
                set_is_empty_event_checkout_phone(false);
            }, 5000);
            errors['event_checkout_phone'] = 'Ce champ est requis.'
        }
        if(!privateCheckoutFormData['event_checkout_address']){
            isValidForm = false;
            set_is_empty_event_checkout_address(true);
            setTimeout(() => {
                set_is_empty_event_checkout_address(false);
            }, 5000);
            errors['event_checkout_address'] = 'Ce champ est requis.'
        }
        if(!privateCheckoutFormData['event_checkout_region']){
            isValidForm = false;
            set_is_empty_event_checkout_region(true);
            setTimeout(() => {
                set_is_empty_event_checkout_region(false);
            }, 5000);
            errors['event_checkout_region'] = 'Ce champ est requis.'
        }
        if(!privateCheckoutFormData['event_checkout_compte_etudes_fr']){
            isValidForm = false;
            set_is_empty_event_checkout_compte_etudes_fr(true);
            setTimeout(() => {
                set_is_empty_event_checkout_compte_etudes_fr(false);
            }, 5000);
            errors['event_checkout_compte_etudes_fr'] = 'Ce champ est requis.'
        }
        if((privateCheckoutFormData['event_checkout_compte_etudes_fr'] === 1 || 
            privateCheckoutFormData['event_checkout_compte_etudes_fr'] === '1') &&
            !privateCheckoutFormData['event_checkout_compte_etudes_fr_number'])
        {
            isValidForm = false;
            set_is_empty_event_checkout_compte_etudes_fr_number(true);
            setTimeout(() => {
                set_is_empty_event_checkout_compte_etudes_fr_number(false);
            }, 5000);
            errors['event_checkout_compte_etudes_fr_number'] = 'Ce champ est requis.'
        }
        if(privateCheckoutFormData['event_checkout_region']){
            if(parseInt(privateCheckoutFormData['event_checkout_region']) !== eventInfos?.eventInfos?.ville?.id){
                isValidForm = false;
                set_is_not_same_good_region(true);
                setTimeout(() => {
                    set_is_not_same_good_region(false);
                }, 5000);
                errors['not_same_good_region'] = "Votre région de résidence est différente de celle où doit se tenir ce RDV."
            }
        }
        setCheckoutFieldsErrors(errors);
        return isValidForm;
    }

    const onSubmitPrivateCheckout = () => {
        if(onValidatePrivateCheckout()){
            setCheckoutInProgress(true);
            privateCheckoutFormData['event_id'] = eventInfos?.eventInfos?.id;
            privateCheckoutFormData['event_checkout_email'] = candidatPlanning?.candidat_email;
            privateCheckoutFormData['event_checkout_fullname'] = candidatPlanning?.candidat_fullname;
            privateCheckoutFormData['has_doing_auto_evaluation'] = 1;
            privateCheckoutFormData['numero_dossier_auto_evaluation'] = candidatPlanning?.numero_dossier;
            privateCheckoutFormData['checkout_date_id'] = eventInfos?.id;
            privateCheckoutFormData['link_token'] = linkToken;
            privateCheckoutFormData['link_timestamp'] = linkTimestamp;
            console.log('privateCheckoutFormData', privateCheckoutFormData);
            axios.post(`${ApiBaseUrl}event_checkouts`, privateCheckoutFormData)
                .then(response => {
                    setCheckoutInProgress(false);
                    if(response.data.success && !response.data.already_subscribe && !response.data.limit_reached && !response.data.cancelled_event && !response.data.bad_link){
                        onGetUpdatedEvents(candidatPlanning?.type_rdv, choosedDate);
                        document.getElementById('v2-front-modal-checkout-form').reset();
                        handleCloseCheckoutFormModal();
                        toast.success("Votre inscription a été bien prise en compte.", {
                            duration: 8000,
                            position: 'top-center'
                        });
                        setCheckoutFields({});
                        setCheckoutFieldsErrors({});
                    }else if(response.data.success && response.data.already_subscribe && !response.data.limit_reached && !response.data.cancelled_event && !response.data.bad_link){
                        handleCloseCheckoutFormModal();
                        toast.error("Désolé! vous ne pouvez pas vous réinscrire puis que vous êtes déjà inscrit à cette séance.", {
                            duration: 8000,
                            position: 'top-center'
                        });
                    }else if(response.data.success && !response.data.already_subscribe && response.data.limit_reached && !response.data.cancelled_event && !response.data.bad_link){
                        handleCloseCheckoutFormModal();
                        toast.error("Oupsss! Désolé, votre inscription ne peut pas être prise en compte. Nous avons atteint le nombre de participants pour cette séance.", {
                            duration: 8000,
                            position: 'top-center'
                        });
                    }else if(response.data.success && !response.data.already_subscribe && !response.data.limit_reached && response.data.cancelled_event && !response.data.bad_link){
                        handleCloseCheckoutFormModal();
                        toast.error("Oupsss! Désolé, vous ne pouvez pas vous inscrire car cette séance a été annulé.", {
                            duration: 8000,
                            position: 'top-center'
                        });
                    }else if(response.data.success && !response.data.already_subscribe && !response.data.limit_reached && !response.data.cancelled_event && response.data.bad_link){
                        handleCloseCheckoutFormModal();
                        toast.error("Oups! Les informations que vous avez fourni ne correspondent pas à celles avec lesquelles le planning privé a été créé.", {
                            duration: 8000,
                            position: 'top-center'
                        });
                    }else{
                        console.log(response)
                        toast.error("Oupsss! Une erreur est survenue lors de votre inscription. Veuillez réessayer plus tard.", {
                            duration: 8000,
                            position: 'top-center'
                        });
                    }
                })
                .catch(error => {
                    setCheckoutInProgress(false);
                    console.log("error", error)
                    toast.error("Oupsss! Une erreur est survenue lors de votre inscription. Veuillez réessayer plus tard.", {
                        duration: 8000,
                        position: 'top-center'
                    });
                })
        }
        
    }

    return (
        <div className="component-generated-event-link-form">
            <HeaderOutilsEtudiant isAdminDashboard={false} />
            <div className="half-top-accueil fixing-after-header gl-form-container">
                <div className="container">
                    <h1 className="ff-nunito fw-900 text-center m-t-100 m-b-40 wow fadeInLeft generated-link-formulaire-title">Planning privé </h1>
                    <div className="v2-modal-body-row row checking-meeting-row">
                        <div className="col-md-5 calendar-md-col">
                            <div className="calendar-body-content">
                                {giveCalendarAccess ? (
                                    <Calendar 
                                        value={value}
                                        onClickDay={onClickDay}
                                        onChange={onChangeDate}
                                        tileClassName={tileClassName}
                                    />
                                ) : (
                                    <Skeleton className="calendar-skeleton" />
                                )}
                                
                            </div>
                        </div>
                        <div className="col-md-7 calendar-select-result-md-col">
                            <div className="calendar-body-select-result-content">
                                {(hasNoRequest && !checkEventInProgress) ? (
                                        giveCalendarAccess ? (
                                            <div className="row empty-alert-row">
                                                <div className="col-md-2 empty-icon-col">
                                                    <FeatherIcon
                                                        icon="alert-circle"
                                                        className="m-b-3 empty-alert-icon rotateIn"
                                                    />
                                                </div>
                                                <div className="col-md-10 empty-textual-col">
                                                    <p><strong>Vous devez choisir une date.</strong></p>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="row empty-alert-row">
                                                <div className="col-md-2 empty-icon-col">
                                                    <Skeleton className="m-b-3 empty-alert-icon" />
                                                </div>
                                                <div className="col-md-10 empty-textual-col">
                                                    <p>
                                                        <Skeleton className="empty-textual-skeleton" />
                                                    </p>
                                                </div>
                                            </div>
                                        )

                                        
                                    ) : (!hasNoRequest && checkEventInProgress) ? (
                                        <div className="loadering">
                                            <span class="checkRDVloader"></span>
                                        </div>
                                    ) : (!hasNoRequest && !checkEventInProgress) && (
                                        choosedDateEvent?.length === 0 ? (
                                            <div className="empty-rdv-textual-container">
                                                <span>Aucun rendez vous disponible à cette date.</span>
                                            </div>
                                        ) : (
                                            <div className="no-empty-rdv-infos-container">
                                                <h5 className="no-empty-rdv-type"> { candidatPlanning?.type_rdv } </h5>
                                                <h6 className="no-empty-rdv-label">Des rendez-vous sont disponibles pour le { moment(value).format("DD/MM/YYYY") } :</h6>
                                                <ul className="no-empty-rdv-ul">
                                                    {choosedDateEvent?.map((item, index) => 
                                                        (((item?.eventInfos?.event_participant_count - item?.participants) <= 0) ) ? (
                                                                <li className="disable-item" 
                                                                    key={'no-empty-event' + index}
                                                                > 
                                                                    De {item?.startTime} à {item?.endTime} à <span className="event-location-label">{ item?.eventInfos?.ville?.name }</span>&nbsp;
                                                                    <span className="event-particant-count"> 
                                                                        {'0 place restante'} 
                                                                    </span> 
                                                                </li>
                                                            ) : (((item?.eventInfos?.event_participant_count - item?.participants) > 0) && dateCompare(moment(item?.date).format('MM/DD/YYYY'), item?.eventInfos?.event_start_time) === 'is greater than today') ? (
                                                                <li className="linkable" 
                                                                    onClick={() => onGetSubscribeFormModal('orientation', item)} 
                                                                    key={'no-empty-event' + index}
                                                                > 
                                                                    De {item?.startTime} à {item?.endTime} à <span className="event-location-label">{ item?.eventInfos?.ville?.name }</span>&nbsp;
                                                                    <span className="event-particant-count"> 
                                                                        { (item?.eventInfos?.event_participant_count - item?.participants) + ' place(s) restante(s)'} 
                                                                    </span> 
                                                                </li>
                                                            ) : (
                                                                <li className="disable-item" 
                                                                    key={'no-empty-event' + index}
                                                                > 
                                                                    De {item?.startTime} à {item?.endTime} à <span className="event-location-label">{ item?.eventInfos?.ville?.name }</span>&nbsp;
                                                                    <span className="event-particant-count"> 
                                                                        {'0 place restante'} 
                                                                    </span> 
                                                                </li>
                                                            ) 
                                                    )}
                                                </ul>
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={showAccessFormModal}
                onHide={handleCloseAccessFormModal}
                backdrop="static"
                keyboard={false}
                className="v2-front-modal"
            >
                <form id="v2-front-modal-form">
                    <Modal.Header className="v2-front-modal-header">
                        <Modal.Title className="v2-front-modal-title">Validation de vos accès</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="v2-front-modal-body">
                        <div className="v2-front-modal-form-container">
                            <div className="row v2-front-modal-form-row">
                                <div className="col-md-12 o-form-col">
                                    <div className="form-group o-form-group">
                                        <label htmlFor="candidat_email">Votre adresse email <span className="required-icon">*</span> </label>
                                        <input 
                                            placeholder="Votre adresse email" 
                                            name="candidat_email" 
                                            className="form-control oe-form-control" 
                                            id="candidat_email" 
                                            onChange={handleChangeAccessForm}
                                        />
                                    </div>
                                    {is_empty_candidat_email &&
                                        <div className="alert alert-danger oe-alert-danger"> 
                                            { errorFormData.candidat_email } 
                                        </div>
                                    }
                                    {is_invalid_email_syntax &&
                                        <div className="alert alert-danger oe-alert-danger"> 
                                            { errorFormData.invalid_email_syntax } 
                                        </div>
                                    }
                                </div>
                                <div className="col-md-12 o-form-col">
                                    <div className="form-group o-form-group">
                                        <label htmlFor="numero_dossier">Votre numéro de dossier <span className="required-icon">*</span> </label>
                                        <input 
                                            placeholder="Votre numéro de dossier" 
                                            name="numero_dossier" 
                                            className="form-control oe-form-control" 
                                            id="numero_dossier" 
                                            onChange={handleChangeAccessForm}
                                        />
                                    </div>
                                    {is_empty_numero_dossier &&
                                        <div className="alert alert-danger oe-alert-danger"> 
                                            { errorFormData.numero_dossier } 
                                        </div>
                                    }
                                </div>
                                <div className="col-md-12 o-form-col">
                                    <div className="form-group o-form-group">
                                        <label htmlFor="link_access_code">Votre code d'accès <span className="required-icon">*</span> </label>
                                        <input 
                                            placeholder="Votre code d'accès" 
                                            name="link_access_code" 
                                            className="form-control oe-form-control" 
                                            id="link_access_code" 
                                            onChange={handleChangeAccessForm}
                                        />
                                    </div>
                                    {is_empty_link_access_code &&
                                        <div className="alert alert-danger oe-alert-danger"> 
                                            { errorFormData.link_access_code } 
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="v2-front-modal-footer">
                        <Button 
                            type="submit" 
                            className="v2-front-modal-form-submit-btn" 
                            variant="primary"
                            onClick={(e) => {
                                e.preventDefault();
                                onSubmitAccessRequest();
                            }}
                        >
                            {accessRequestInProgress ? 'En cours' : 'Valider'}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal
                show={showCheckoutFormModal}
                onHide={handleCloseCheckoutFormModal}
                className="v2-front-modal"
            >
                <form id="v2-front-modal-checkout-form">
                    <Modal.Header className="v2-front-modal-header" closeButton>
                        <Modal.Title className="v2-front-modal-title">Inscription à un RDV </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="v2-front-modal-body">
                        <div className="v2-front-modal-form-container">
                            <div className="row v2-front-modal-form-row">
                                <div className="col-md-12 add-event-col event-checkout-form-infos">
                                    <h4 className="date-choice-label"> { candidatPlanning?.type_rdv } </h4>
                                    <div className="choosed-datetime-infos">
                                        { moment(eventInfos?.date).format('DD/MM/YYYY') + ' de ' + eventInfos?.startTime + ' à ' + eventInfos?.endTime + ' à ' + eventInfos?.eventInfos?.ville?.name }
                                        <span className={ parseInt(eventInfos?.eventInfos?.event_participant_count) - parseInt(eventInfos?.participants) !== 0 ? "count-remaining" : "ready-remaining" }> 
                                            { parseInt(eventInfos?.eventInfos?.event_participant_count) - parseInt(eventInfos?.participants) } place(s) 
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-12 o-form-col">
                                    <div className="form-group o-form-group">
                                        <label htmlFor="event_checkout_phone">Votre numéro de téléphone <span className="required-icon">*</span> </label>
                                        <input 
                                            placeholder="Votre numéro de téléphone" 
                                            name="event_checkout_phone" 
                                            className="form-control oe-form-control" 
                                            id="event_checkout_phone" 
                                            onChange={handleChangePrivateCheckout}
                                        />
                                    </div>
                                    {is_empty_event_checkout_phone &&
                                        <div className="alert alert-danger oe-alert-danger"> 
                                            { checkoutFieldsErrors.event_checkout_phone } 
                                        </div>
                                    }
                                </div>
                                <div className="col-md-12 o-form-col">
                                    <div className="form-group o-form-group">
                                        <label htmlFor="event_checkout_address">Votre adresse <span className="required-icon">*</span> </label>
                                        <input 
                                            placeholder="Votre adresse" 
                                            name="event_checkout_address" 
                                            className="form-control oe-form-control" 
                                            id="event_checkout_address" 
                                            onChange={handleChangePrivateCheckout}
                                        />
                                    </div>
                                    {is_empty_event_checkout_address &&
                                        <div className="alert alert-danger oe-alert-danger"> 
                                            { checkoutFieldsErrors.event_checkout_address } 
                                        </div>
                                    }
                                </div>
                                <div className="col-md-12 o-form-col">
                                    <div className="form-group o-form-group">
                                        <label forHtml="event_checkout_region">Votre Région de résidence</label>
                                        <select 
                                            name="event_checkout_region" 
                                            id="event_checkout_region" 
                                            className="form-control oe-form-control v2-select-form-control"
                                            onChange={handleChangePrivateCheckout}
                                        >
                                            <option selected disabled >--Choisir une ville--</option>
                                            {
                                                villes?.map((ville, index) => 
                                                    <option key={`select-ville-${index}`} value={ville?.id}> { ville?.name } </option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    {is_empty_event_checkout_region &&
                                        <div className="alert alert-danger oe-alert-danger"> 
                                            { checkoutFieldsErrors.event_checkout_region } 
                                        </div>
                                    }
                                    {is_not_same_good_region &&
                                        <div className="alert alert-danger oe-alert-danger"> 
                                            { checkoutFieldsErrors.not_same_good_region } 
                                        </div>
                                    }
                                </div>
                                <div className="col-md-12 o-form-col">
                                    <div className="form-group o-form-group">
                                        <label forHtml="event_checkout_compte_etudes_fr">Avez-vous un compte etudes SN ?</label>
                                        <select 
                                            name="event_checkout_compte_etudes_fr" 
                                            id="event_checkout_compte_etudes_fr" 
                                            className="form-control oe-form-control v2-select-form-control"
                                            onChange={handleChangePrivateCheckout}
                                        >
                                            <option>--Choisir une option--</option>
                                            <option value={1}> OUI </option>
                                            <option value={0}> NON </option>
                                        </select>
                                    </div>
                                    {is_empty_event_checkout_compte_etudes_fr &&
                                        <div className="alert alert-danger oe-alert-danger"> 
                                            { checkoutFieldsErrors.event_checkout_compte_etudes_fr } 
                                        </div>
                                    }
                                </div>

                                {checkoutHasCompteEtudesFR &&
                                    <div className="col-md-12 o-form-col">
                                        <div className="form-group o-form-group">
                                            <label forHtml="event_checkout_compte_etudes_fr_number">Votre Numero de Compte Etudes SN</label>
                                            <input 
                                                type="text" 
                                                placeholder="Votre Numero de Compte Etudes SN" 
                                                className="form-control oe-form-control" 
                                                name="event_checkout_compte_etudes_fr_number" 
                                                id="event_checkout_compte_etudes_fr_number" 
                                                onChange={handleChangePrivateCheckout}
                                            />
                                        </div>
                                        {is_empty_event_checkout_compte_etudes_fr_number &&
                                            <div className="alert alert-danger oe-alert-danger"> 
                                                { checkoutFieldsErrors.event_checkout_compte_etudes_fr_number } 
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="v2-front-modal-footer">
                        <Button 
                            type="submit" 
                            className="v2-front-modal-form-submit-btn" 
                            variant="primary"
                            disabled={checkoutInProgress}
                            onClick={(e) => {
                                e.preventDefault();
                                onSubmitPrivateCheckout();
                            }}
                        >
                            {checkoutInProgress ? 'En cours' : 'Envoyer'}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Toaster />
        </div>
    )
}

export default CandidatPrivatePlanning